import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import {TableHeader} from '../../../components/table/Headers';
import {
  GroupFilter,
  PeriodRangeFilter,
} from '../../../components/filter/Filters';
import {useFilter, getCurrentMonth} from '@unthinkable/react-filters';
import { PrimaryColumnCard } from '../../../components/card/PrimaryColumnCard';
import EmployeeWorkLoad from '../../employee/views/EmployeeWorkLoad';
import { TabView } from '../../../components/tab';

export const TeamEfficiencyReport = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  const {forDrill = false, employeeId: empId} = params;
  const employeeId = user?.employee?._id;

  return (
    <Table
      params={{...params, forDrill}}
      renderHeader={() => forDrill && <TableHeader title={'Team'} />}
      eventSourceId={'Task'}
      api={`/employees/${forDrill ? empId : employeeId}/teamEfficiencyReport`}
      columns={[
        {
          header: 'Member',
          field: 'member',
          render: UserAvatarChip,
        },
        {
          header: 'Reporting',
          field: 'reporting_team_size',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(`employee-wise-team-efficiency`, {
              ...params,
              employeeId: row._id,
              row,
              forDrill: true,
            });
          },
        },
        {
          header: 'Unallocated',
          field: 'bench',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('employee-workload', {
              employeeFilter: {
                _id: {$in: row.benchEmployeesIds},
                employee_status: {$in: ['Active', 'Inactive']},
              },
            });
          },
        },
        // {
        //   header: 'Managed Projects',
        //   field: 'managed_project_count',
        //   type: 'colorChip',
        //   align: 'center',
        //   onPress: ({row}) => {
        //     navigation.navigate('team-efficiency-report-overview', {
        //       ...params,
        //       addOnParamFilter: {
        //         owner_id: row._id,
        //         project_status: 'a',
        //       },
        //     });
        //   },
        // },
        {
          header: 'Project Team',
          field: 'managed_team_size',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
              formAllTeamView: true,
            });
          },
        },
        {
          header: 'Planned Tasks',
          field: 'managed_project_active_task_estimated_hours',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
        {
          header: 'Project Eff.',
          field: 'managed_project_efficiency',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Active Tasks',
          onPress: ({row}) => {
            navigation.navigate('team-active-tasks', {
              userId: row?.member?.employee_user,
              assigned_to: row?.member?.employee_user,
              all_tasks: false,
              activeTask: true,
              row,
            });
          },
        },
        {
          title: 'All Tasks',
          onPress: ({row}) => {
            navigation.navigate('team-active-tasks', {
              userId: row?.member?.employee_user,
              assigned_to: row?.member?.employee_user,
              all_tasks: true,
              activeTask: false,
              row,
            });
          },
        },
      ]}
    />
  );
};

export const AllEmployeeTeamEfficiencyReport = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  const {applyFilter, filterValues} = useFilter({});
  const {filter = {}, params: filterParams} = filterValues || {};
  return (
    <Table
      params={{...params, forDrill: false, ...filterParams}}
      eventSourceId={'Task'}
      limit={2000}
      addOnFilter={filter}
      api={`/allEmployeeTeamEfficiencyReport`}
      renderHeader={() => {
        return (
          <TableHeader
            title="All Team"
            actions={[
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    options: [
                      {label: 'Unallocated', value: 'bench'},
                      {
                        label: 'Project Eff.',
                        value: 'managed_project_efficiency',
                      },
                    ],
                    field: 'sort_on',
                    label: 'Sort On',
                    suggestionField: 'label',
                    keyField: 'value',
                    valueField: 'label',
                    asParam: true,
                  },
                  {
                    type: 'multiAutoComplete',
                    label: 'Department',
                    api: '/activeDepartmentSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'department',
                    searchFields: ['name'],
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Member',
          field: 'member',
          render: UserAvatarChip,
        },
        {
          header: 'Reporting',
          field: 'reporting_team_size',
          aggregate: true,
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(`employee-wise-team-efficiency`, {
              ...params,
              employeeId: row._id,
              row,
              forDrill: true,
            });
          },
        },
        {
          header: 'Unallocated',
          field: 'bench',
          type: 'colorChip',
          aggregate: true,
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('employee-workload', {
              employeeFilter: {
                _id: {$in: row.benchEmployeesIds},
                employee_status: {$in: ['Active', 'Inactive']},
              },
            });
          },
        },
        // {
        //   header: 'Managed Projects',
        //   field: 'managed_project_count',
        //   type: 'colorChip',
        //   align: 'center',
        //   onPress: ({row}) => {
        //     navigation.navigate('team-efficiency-report-overview', {
        //       ...params,
        //       addOnParamFilter: {
        //         owner_id: row._id,
        //         project_status: 'a',
        //       },
        //     });
        //   },
        // },
        {
          header: 'Project Team',
          field: 'managed_team_size',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
              formAllTeamView: true,
            });
          },
        },
        {
          header: 'On Locomo',
          field: 'locomo_team_size',
          aggregate: true,
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
                tool: 'Locomo',
              },
              formAllTeamView: true,
            });
          },
        },
        {
          header: 'Planned Tasks %',
          field: 'plan_task_percentage',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
              },
            });
          },
        },
        {
          header: 'Project Eff.',
          field: 'managed_project_efficiency',
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                project_status: 'a',
                tool: 'Locomo',
              },
            });
          },
        },
      ]}
      moreActions={[
        {
          title: 'Active Tasks',
          onPress: ({row}) => {
            navigation.navigate('team-active-tasks', {
              userId: row?.member?.employee_user,
              assigned_to: row?.member?.employee_user,
              all_tasks: false,
              activeTask: true,
              row,
            });
          },
        },
        {
          title: 'All Tasks',
          onPress: ({row}) => {
            navigation.navigate('team-active-tasks', {
              userId: row?.member?.employee_user,
              assigned_to: row?.member?.employee_user,
              all_tasks: true,
              activeTask: false,
              row,
            });
          },
        },
        {
          title: 'View Allocation',
          onPress: ({row}) => {
            const {reportingTeam} = row;
            const employeeIds = reportingTeam?.map(item => item._id) || [];
            navigation.navigate('allocated-resource-list', {
              ...params,
              employeeFilter: {
                employee: {$in: employeeIds},
              },
            });
          },
        },
      ]}
    />
  );
};

export const NewTeamEfficiencyReport = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  console.log("params>>>>>>>>>>>>>>>>>>>",params)
  const {forDrill = false, employeeId: empId,period} = params;
  const employeeId = user?.employee?._id;
  return (
    <Table
      params={{...params, forDrill}}
      renderHeader={() => forDrill && <TableHeader title={'Team'} />}
      eventSourceId={'Task'}
      api={`/employees/${forDrill ? empId : employeeId}/newteamEfficiencyReport`}
      columns={[
        {
          header: 'Member',
          field: 'member',
          render: UserAvatarChip,
        },
        {
          header: 'Reporting',
          field: 'reporting_team_size',
          aggregate: true,
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(`new-employee-wise-team-efficiency`, {
              ...params,
              employeeId: row._id,
              row,
              forDrill: true,
            });
          },
        },
        {
          header: 'Unallocated',
          field: 'unallocated_team_size',
          type: 'colorChip',
          aggregate: true,
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('employee-workload', {
              ...params,
              fromTeam : true,
              employeeFilter: {
                _id: {$in: row.unallocatedTeamIds},
                employee_status: {$in: ['Active', 'Inactive']},
              },
              from : "bench",
            });
          },
        },
        {
          header: 'Net Borrowed',
          field: 'borrowed_team_size',
          type: 'colorChip',
          align: 'center',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate('net-borrowed-team-view', {
              ...params,
              row,
              fromTeam : true,
              from : "borrowed",
            });
          },
        },
        {
          header: 'Deployed',
          field: 'deployed_team_size',
          type: 'colorChip',
          align: 'center',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                $or: [{completed_on: {$gte: period?.to}}, {project_status: 'a'}],
              },
              formAllNewTeamView: true,
            });
          },
        },
        {
          header: 'Efficiency Tracked',
          field: 'efficiency_tracked',
          aggregate: true,
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              addOnParamFilter: {
                owner_id: row._id,
                _id : {$in : row?.locomoProjects || []},
                // $or: [{completed_on: {$gte: period?.to}}, {project_status: 'a'}],
                // tool: 'Locomo',
              },
              formAllNewTeamView: true,
            });
          },
        },
        {
          header: 'Efficiency %',
          field: 'managed_project_efficiency_percent',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={`${row?.managed_project_efficiency_percent}MM`}
                items={[
                  {
                    value: `${row?.managed_project_delivered_hrs}/${row?.managed_project_available_hrs}`,
                  },
                ]}
              />
            );
          },
          // onPress: ({row}) => {
          //   navigation.navigate('team-efficiency-report-overview', {
          //     ...params,
          //     addOnParamFilter: {
          //       owner_id: row._id,
          //       project_status: 'a',
          //     },
          //   });
          // },
        },
      ]}
    />
  );
};

export const NewAllEmployeeTeamEfficiencyReport = props => {
  const {
    navigation,
    route: {params},
    user,
  } = props;
  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {filter = {}, params: filterParams} = filterValues || {};
  const {period = {}} = filterParams
  return (
    <Table
      params={{...params, forDrill: false, ...filterParams}}
      eventSourceId={'Task'}
      limit={10000}
      addOnFilter={filter}
      api={`/newallEmployeeTeamEfficiencyReport`}
      renderHeader={() => {
        return (
          <TableHeader
            title="All Team"
            actions={[
              <PeriodRangeFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
              />,
              <GroupFilter
                filterValues={filterValues}
                applyFilter={applyFilter}
                filters={[
                  {
                    type: 'autoComplete',
                    options: [
                      {label: 'Unallocated', value: 'bench'},
                      {
                        label: 'Project Eff.',
                        value: 'managed_project_efficiency',
                      },
                    ],
                    field: 'sort_on',
                    label: 'Sort On',
                    suggestionField: 'label',
                    keyField: 'value',
                    valueField: 'label',
                    asParam: true,
                  },
                  {
                    type: 'multiAutoComplete',
                    label: 'Department',
                    api: '/activeDepartmentSuggestions',
                    placeholder: 'Select',
                    suggestionField: 'name',
                    valueField: 'name',
                    field: 'department',
                    searchFields: ['name'],
                  },
                ]}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Member',
          field: 'member',
          render: UserAvatarChip,
        },
        {
          header: 'Reporting',
          field: 'reporting_team_size',
          aggregate: true,
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate(`new-employee-wise-team-efficiency`, {
              ...params,
              ...filterParams,
              employeeId: row._id,
              row,
              forDrill: true,
            });
          },
        },
        {
          header: 'Unallocated',
          field: 'unallocated_team_size',
          type: 'colorChip',
          aggregate: true,
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('employee-workload', {
              ...params,
              ...filterParams,
              fromTeam : true,
              employeeFilter: {
                _id: {$in: row.unallocatedTeamIds},
                employee_status: {$in: ['Active', 'Inactive']},
              },
              from : "bench",
            });
          },
        },
        {
          header: 'Net Borrowed',
          field: 'borrowed_team_size',
          type: 'colorChip',
          align: 'center',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate('net-borrowed-team-view', {
              ...params,
              ...filterParams,
              row,
              fromTeam : true,
              from : "borrowed",
            });
          },
        },
        {
          header: 'Deployed',
          field: 'deployed_team_size',
          type: 'colorChip',
          align: 'center',
          aggregate: true,
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              ...filterParams,
              addOnParamFilter: {
                owner_id: row._id,
                $or: [{completed_on: {$gte: period?.to}}, {project_status: 'a'}],
              },
              formAllNewTeamView: true,
            });
          },
        },
        {
          header: 'Efficiency Tracked',
          field: 'efficiency_tracked',
          aggregate: true,
          type: 'colorChip',
          align: 'center',
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              ...filterParams,
              addOnParamFilter: {
                owner_id: row._id,
                _id : {$in : row?.locomoProjects || []},
                // $or: [{completed_on: {$gte: period?.to}}, {project_status: 'a'}],
                // tool: 'Locomo',
              },
              formAllNewTeamView: true,
            });
          },
        },
        {
          header: 'Efficiency %',
          field: 'managed_project_efficiency_percent',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={`${row?.managed_project_efficiency_percent}MM`}
                items={[
                  {
                    value: `${row?.managed_project_delivered_hrs}/${row?.managed_project_available_hrs}`,
                  },
                ]}
              />
            );
          },
          onPress: ({row}) => {
            navigation.navigate('team-efficiency-report-overview', {
              ...params,
              ...filterParams,
              addOnParamFilter: {
                owner_id: row._id,
                $or: [{completed_on: {$gte: period?.to}}, {project_status: 'a'}],
                tool: 'Locomo',
              },
              formAllNewTeamView: true,
            });
          },
        },
      ]}
      // moreActions={[
      //   {
      //     title: 'Active Tasks',
      //     onPress: ({row}) => {
      //       navigation.navigate('team-active-tasks', {
      //         userId: row?.member?.employee_user,
      //         assigned_to: row?.member?.employee_user,
      //         all_tasks: false,
      //         activeTask: true,
      //         row,
      //       });
      //     },
      //   },
      //   {
      //     title: 'All Tasks',
      //     onPress: ({row}) => {
      //       navigation.navigate('team-active-tasks', {
      //         userId: row?.member?.employee_user,
      //         assigned_to: row?.member?.employee_user,
      //         all_tasks: true,
      //         activeTask: false,
      //         row,
      //       });
      //     },
      //   },
      // ]}
    />
  );
};


export const NetBorrowedTabs = props => {
  const {
    route: {params} = {},
  } = props;
  const {row = {} , period} = params
  const tabs = {
    borrowed: {
      label: 'Borrowed',
      view: (
        <EmployeeWorkLoad
          benchComparision={params?.benchComparision || false}
          tab="borrowed"
          projectFilter={{
            owner_id: row._id,
          }}
          employeeFilter={{
            _id: {$in: row.borrowedTeamIds},
            employee_status: {$in: ['Active', 'Inactive']},
          }}
          period = {{...period}}
        />
      ),
      api: '/employees-team/workload',
      // addOnFilter: filterValues?.filter || {},
      searchFields: ['name'],
      params: {
        ...params,
        employeeFilter: {
          _id: {$in: row.borrowedTeamIds},
          employee_status: {$in: ['Active', 'Inactive']},
        },
        projectFilter : {
          owner_id: row._id,
        },
        tab : "borrowed"
      },
    },
    lend: {
      label: 'lend',
      view: (
        <EmployeeWorkLoad
          benchComparision={params?.benchComparision || false}
          tab="lend"
          employeeFilter={{
            _id: {$in: row?.lendedEmployeeIds || []},
            employee_status: {$in: ['Active', 'Inactive']},
          }}
          projectFilter={{
            owner_id: row._id,
          }}
          period = {{...period}}
        />
      ),
      api: '/employees-team/workload',
      // addOnFilter: filterValues?.filter || {},
      searchFields: ['name'],
      params: {
        ...params,
        employeeFilter: {
          _id: {$in: row?.lendedEmployeeIds || []},
          employee_status: {$in: ['Active', 'Inactive']},
        },
        tab : "lend",
        projectFilter : {
          owner_id: row._id,
        },
      },
    },
  };

  return <TabView tabs={tabs} {...props} />;
};
