import {Col} from '@unthinkable/react-core-components';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React, {useState} from 'react';
import {Button} from '../../../components/button/Button';
import {Form, InlineForm} from '../../../components/form/Form';
import NestedTable from '../../../components/form/NestedTable';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

const RenderRequestFeedback = ({navigation}, {values}) => {
  const onPress = () => {
    navigation.navigate('request-feedback', {
      project: values,
    });
  };
  return <Button text="REQUEST FEEDBACK" onPress={onPress} />;
};

const ProjectComputations = fetch => ({
  'set value from customer': {
    compute: async value => {
      if (value?.customer?._id) {
        const {data: customerData} = await fetch({
          uri: '/customers',
          props: {
            filter: {
              _id: value?.customer?._id,
            },
            fields: {
              _id: 1,
              industry: {_id: 1, name: 1},
              owner: {_id: 1, name: 1},
            },
            only: true,
          },
        });
        return {
          industry: customerData.industry,
          account_owner: customerData.owner,
        };
      }
    },
    multi: true,
    dependencies: ['customer'],
  },
  'set other tool type': {
    compute: async value => {
      if (value?.is_other_tool) {
        return {
          tool: 'Other',
        };
      } else if (!value.is_other_tool || value.is_other_tool === false) {
        return {
          tool: 'Locomo',
        };
      }
    },
    multi: true,
    dependencies: ['is_other_tool'],
  },
});

export const ProjectForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'Project',
    ...props,
  });
  let {route = {}, fromDetail = false, mode, navigation} = props || {};
  let {params = {}} = route || {};
  let {
    department,
    product,
    project,
    new_project_type,
    formAdmin = false,
  } = params || {};
  const {
    user: {employee: employeeId, email: userEmail},
    fetch,
  } = useAppStateContext();
  const [requiredField, setRequiredField] = useState(false);
  return (
    <Form
      api={`/projects/${project?._id}`}
      computations={{...ProjectComputations(fetch)}}
      fields={{
        project: 1,
        code: 1,
        project_group: 1,
        desc: 1,
        department: {name: 1},
        product: {name: 1},
        projecttype: 1,
        project_sub_category: 1,
        owner_id: {name: 1},
        csm: {_id: 1, name: 1},
        account_owner: {_id: 1, name: 1},
        customer: {name: 1},
        primary_practice: {name: 1},
        project_category: 1,
        observer: {name: 1},
        project_group: 1,
        project_start_date: 1,
        project_end_date: 1,
        desc: 1,
        new_project_type: 1,
        tool: 1,
        msa: 1,
        proposal_doc: 1,
        estimation_sheet: 1,
        other_project: 1,
        observer: {name: 1},
        client: {
          name: 1,
        },
        clientAccess: 1,
        is_project_assign: 1,
        new_project_sub_type: {_id: 1, project_sub_type: 1},
        feedbackSchedule: 1,
        nextFeedbackSchedule: 1,
        is_support_project: 1,
        industry: {
          name: 1,
        },
        feedback_mode: 1,
        parent: {project: 1},
        disable_release_efficiency: 1,
        is_other_tool: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        status: 'backlog',
        owner_id: employeeId,
        department,
        // product: {
        //   _id: '53a42e38a22d3a020035f672',
        //   name: 'Software Services',
        // },
        // primary_practice: {
        //   _id: '5e06f3f73a48a146a17ecf71',
        //   name: 'JavaScript MEAN n MERN',
        // },
        is_project_assign: false,
        created_on: getZeroTimeDate(new Date()),
        new_project_type,
        tool: 'Locomo',
      }}
      layoutFields={[
        {
          label: 'About Project',
          fields: [
            {
              type: 'text',
              field: 'project',
              label: 'Project name',
              required: true,
              size: 6,
            },
            {
              label: 'Customer',
              field: 'customer',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
            // {
            //   label: 'Tool',
            //   field: 'tool',
            //   type: 'autoComplete',
            //   options: ['Locomo', 'Other'],
            //   required: true,
            //   size: 4,
            // },
            // {
            //   label: 'Type',
            //   field: 'new_project_type',
            //   type: 'autoComplete',
            //   size: 4,
            //   options: [
            //     {value: 'staffaugmentation', label: 'Staff Augmentation'},
            //     {value: 'managedteam', label: 'Managed Team'},
            //     {value: 'sdlc', label: 'SDLC'},
            //     {value: 'sdlcplus', label: 'SDLC+'},
            //   ],
            //   readOnly: formAdmin,
            //   suggestionField: 'label',
            //   keyField: 'value',
            //   valueField: 'label',
            //   required: true,
            //   onChangeValue: (value, e, {setFieldValue}) => {
            //     if (value === 'sdlcplus') {
            //       setRequiredField(true);
            //     } else {
            //       setRequiredField(false);
            //       setFieldValue('feedbackSchedule', 'Monthly');
            //       setFieldValue(
            //         'nextFeedbackSchedule',
            //         moment(new Date()).endOf('month'),
            //       );
            //     }
            //   },
            // },
            {
              label: 'Label',
              field: 'new_project_sub_type',
              type: 'autoComplete',
              api: `/projectsubtypes`,
              suggestionField: 'project_sub_type',
              valueField: 'project_sub_type',
              // filter: {
              //   parent: {
              //     _id: '66ed77d9aa6fbefe2b34ef5c',
              //   },
              // },
              size: 6,
              required: true,
            },
            // {
            //   label: 'Primary Practice',
            //   field: 'primary_practice',
            //   type: 'autoComplete',
            //   api: `/practices`,
            //   suggestionField: 'name',
            //   valueField: 'name',
            //   required: true,
            //   size: 4,
            // },

            // {
            //   label: 'Sub Category',
            //   field: 'project_sub_category',
            //   type: 'autoComplete',
            //   size: 3,
            //   options: [
            //     'Client Managed',
            //     'Daffodil Managed',
            //     'Hybrid Managed',
            //     'Daffodil Managed - Support',
            //   ],
            //   required: true,
            //   visible: ({values}) =>
            //     values &&
            //     (values.projecttype === 'f' || values.projecttype === 'tm'),
            // },

            {
              type: 'checkbox',
              field: 'disable_release_efficiency',
              label: 'Hide Efficiency',
              size: 6,
              visible: () => {
                return userEmail === 'amit.singh@daffodildb.com';
              },
            },
            {
              field: 'projecttype',
              type: 'autoComplete',
              label: 'Project Type',
              options: [
                {value: 'tm', label: 'T&M'},
                {value: 'f', label: 'Fixed'},
                {value: 'i', label: 'Internal'},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              size: 6,
              visible: () => fromDetail,
            },
            {
              collapsedFields: [
                // {
                //   label: 'Start Date',
                //   field: 'project_start_date',
                //   type: 'date',
                //   required: true,
                //   size: 6,
                // },

                // {
                //   field: 'feedbackSchedule',
                //   editable: false,
                //   type: 'text',
                //   label: 'Feedback schedule',
                //   size: 6,
                  // visible: ({values}) =>
                  //   values &&
                  //   (values.new_project_type === 'staffaugmentation' ||
                  //     values.new_project_type === 'managedteam' ||
                  //     values.new_project_type === 'sdlc'),
                // },
                {
                  field: 'is_support_project',
                  type: 'checkbox',
                  label: 'Support Project',
                  size: 6,
                },
                {
                  field: 'is_other_tool',
                  type: 'checkbox',
                  label: 'Other Tool',
                  size: 6,
                },
                // {
                //   field: 'approval_required',
                //   type: 'checkbox',
                //   label: 'Approval Required',
                //   size: 6,
                // },
                // {
                //   label: 'End Date',
                //   field: 'project_end_date',
                //   size: 6,
                //   required: true,
                //   type: 'date',
                // },
                // {
                //   label: 'Industry',
                //   field: 'industry',
                //   type: 'autoComplete',
                //   api: `/industries`,
                //   suggestionField: 'name',
                //   valueField: 'name',
                //   required: true,
                //   size: 6,
                // },
                {
                  type: 'aiTextArea',
                  field: 'desc',
                  required: true,
                  label: 'Description',
                  inputProps: {
                    minRows: 3,
                    maxRows: 6,
                  },
                },
              ],
            },
          ],
        },
        {
          groups : [
            {
              defaultHidden: true,
              label: 'Delivery Based Pricing',
              fields: [
                {
                  field: 'is_delivery_based_pricing',
                  type: 'checkbox',
                  label: 'Delivery Based Pricing',
                  size: 6,
                },
                {
                  field: 'delivery_pricing_date',
                  type: 'date',
                  label: 'Delivery Pricing Date',
                  size: 6,
                }
              ],
            },
          ]
        },
        {
          label: 'Parent Project',
          visible: () => {
            return (
              userEmail === 'amit.singh@daffodildb.com' ||
              userEmail === 'manoj.kaushik@daffodilsw.com'
            );
          },
          fields: [
            {
              label: 'Name',
              field: 'parent',
              type: 'autoComplete',
              api: `/projects`,
              suggestionField: 'project',
              valueField: 'project',
            },
          ],
        },
        {
          label: 'Ownership & additional access',
          fields: [
            {
              label: 'PM',
              field: 'owner_id',
              type: 'autoComplete',
              api: `/employeeSuggestions`,
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              size: 6,
              required: true,
            },
            // {
            //   label: 'AM',
            //   field: 'account_owner',
            //   type: 'autoComplete',
            //   api: `/employeeSuggestions`,
            //   suggestionField: 'name',
            //   secondarySuggestionField: 'official_email_id',
            //   valueField: 'name',
            //   size: 6,
            //   required: true,
            // },
            {
              collapsedFields: [
                {
                  label: 'Internal observers',
                  field: 'observer',
                  type: 'multiAutoComplete',
                  api: `/users`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  valueField: 'name',
                },
                {
                  label: 'Customer POC',
                  field: 'client',
                  type: 'multiAutoComplete',
                  api: `/users`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  valueField: 'name',
                  filter: {
                    userType: {$in: ['client', 'Client']},
                  },
                },
              ],
            },
          ],
        },

        {
          label: 'Documents',
          visible: ({values}) => values?.new_project_type === 'sdlcplus',
          fields: [
            {
              collapsedFields: [
                {
                  placeholder: 'Proposal',
                  label: 'Proposal',
                  field: 'proposal_doc',
                  multiple: true,
                  type: 'file',
                  // required: requiredField,
                  size: 6,
                  options: {bucketName: 'manaze'},
                },
                {
                  placeholder: 'MSA',
                  label: 'MSA',
                  field: 'msa',
                  multiple: true,
                  type: 'file',
                  // required: requiredField,
                  size: 6,
                  options: {bucketName: 'manaze'},
                },
                {
                  placeholder: 'Estimation Sheet  ',
                  label: 'Estimation Sheet  ',
                  field: 'estimation_sheet',
                  multiple: true,
                  type: 'file',
                  // required: requiredField,
                  size: 6,
                  options: {bucketName: 'manaze'},
                },
                {
                  placeholder: 'Others',
                  label: 'Others',
                  field: 'other_project',
                  multiple: true,
                  type: 'file',
                  // required: requiredField,
                  size: 6,
                  options: {bucketName: 'manaze'},
                },
              ],
            },
          ],
        },

        {
          label: 'Feedback setup',
          fields: [
            {
              field: 'feedback_mode',
              type: 'autoComplete',
              options: ['Manual', 'Automatic'],
              label: 'Feedback mode',
              size: 6,
            },
            {
              field: 'nextFeedbackSchedule',
              editable: false,
              type: 'date',
              size: 6,
              label: 'Next feedback schedule',
              size: 6,
              visible: ({values}) =>
                values &&
                (values.new_project_type === 'staffaugmentation' ||
                  values.new_project_type === 'managedteam' ||
                  values.new_project_type === 'sdlc'),
            },
            {
              render: RenderRequestFeedback,
              visible: mode === 'edit' && fromDetail,
              navigation,
            },
          ],
        },
        // {
        //   groups: [
        //     {
        //       label: 'Practice',
        //       defaultHidden: true,
        //       fields: [
        //         {
        //           label: 'Primary Practice',
        //           field: 'primary_practice',
        //           type: 'autoComplete',
        //           api: `/practices`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //           required: true,
        //           // defaultExpanded: true,
        //           size: 4,
        //         },
        //       ],
        //     },
        //     {
        //       label: 'Product',
        //       defaultHidden: true,
        //       fields: [
        //         {
        //           label: 'Product',
        //           field: 'product',
        //           type: 'autoComplete',
        //           api: `/products`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //           required: true,
        //           size: 6,
        //         },
        //       ],
        //     },
        //   ],
        // },
      ]}
      {...props}
    />
  );
};

const projectComputations = () => ({
  is_self_module: {
    compute: async value => {
      if (
        value?.new_project_sub_type?.project_sub_type === 'Module Developement'
      ) {
        return true;
      } else {
        return void 0;
      }
    },
    dependencies: ['new_project_sub_type'],
  },
  'set other tool type': {
    compute: async value => {
      if (value?.is_other_tool) {
        return {
          tool: 'Other',
        };
      }
    },
    multi: true,
    dependencies: ['is_other_tool'],
  },
});

export const UnbilledProjectForm = props => {
  let {
    route: {params},
  } = props || {};
  let {department, project} = params || {};
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'Project',
    ...props,
  });
  const {
    user: {employee: employeeId},
  } = useAppStateContext();

  return (
    <Form
      computations={{...projectComputations()}}
      api={`/projects/${project?._id}`}
      fields={{
        project: 1,
        code: 1,
        project_group: 1,
        desc: 1,
        department: {name: 1},
        product: {name: 1},
        projecttype: 1,
        project_sub_category: 1,
        owner_id: {name: 1},
        csm: {_id: 1, name: 1},
        account_owner: {_id: 1, name: 1},
        customer: {name: 1},
        primary_practice: {name: 1},
        project_category: 1,
        observer: {name: 1},
        project_group: 1,
        project_start_date: 1,
        project_end_date: 1,
        desc: 1,
        tool: 1,
        new_project_sub_type: {_id: 1, project_sub_type: 1},
        observer: {name: 1},
        is_support_project: 1,
        is_other_tool: 1,
        client: {
          name: 1,
        },
        clientAccess: 1,
        is_self_module: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={{
        status: 'backlog',
        owner_id: employeeId,
        department,
        // product: {
        //   _id: '53a42e38a22d3a020035f672',
        //   name: 'Software Services',
        // },
        // primary_practice: {
        //   _id: '5e06f3f73a48a146a17ecf71',
        //   name: 'JavaScript MEAN n MERN',
        // },
        projecttype: 'i',
        created_on: getZeroTimeDate(new Date()),
        tool: 'Locomo',
      }}
      layoutFields={[
        {
          label: 'About Project',
          fields: [
            {
              type: 'text',
              field: 'project',
              label: 'Project Name',
              required: true,
              size: 4,
            },
            // {
            //   label: 'Category',
            //   field: 'project_category',
            //   type: 'autoComplete',
            //   options: ['POC', 'Social', 'Internal'],
            //   size: 'small',
            //   required: true,
            //   size: 4,
            // },
            {
              label: 'For',
              field: 'customer',
              type: 'autoComplete',
              api: `/customers`,
              suggestionField: 'name',
              valueField: 'name',
              size: 4,
              required: true,
              // visible: ({values}) =>
              //   values?.project_category === 'POC' ||
              //   values.project_category === 'Social',
            },
            // {
            //   label: 'Tool',
            //   field: 'tool',
            //   type: 'autoComplete',
            //   options: ['Locomo', 'Other'],
            //   required: true,
            //   size: 'small',
            //   size: 6,
            // },
            {
              label: 'Man Month Estimate',
              field: 'man_month_estimate',
              type: 'number',
              size: 6,
            },
            {
              label: 'Label',
              field: 'new_project_sub_type',
              type: 'autoComplete',
              api: `/projectsubtypes`,
              filter: {
                parent: {
                  _id: '66ed77deaa6fbefe2b34ef83',
                },
              },
              suggestionField: 'project_sub_type',
              valueField: 'project_sub_type',
              size: 6,
              required: true,
            },

            // {
            //   label: 'Start Date',
            //   field: 'project_start_date',
            //   type: 'date',
            //   size: 'small',
            //   required: true,
            //   size: 6,
            // },
            // {
            //   field: 'is_support_project',
            //   type: 'checkbox',
            //   label: 'Support Project',
            //   size: 6,
            // },
            // {
            //   field: 'is_self_module',
            //   type: 'checkbox',
            //   label: 'Self Module',
            //   size: 6,
            // },
            {
              collapsedFields: [
                // {
                //   field: 'approval_required',
                //   type: 'checkbox',
                //   label: 'Approval Required',
                //   size: 6,
                // },
                // {
                //   label: 'End Date',
                //   field: 'project_end_date',
                //   size: 3,
                //   type: 'date',
                //   required: true,
                // },
                {
                  field: 'is_other_tool',
                  type: 'checkbox',
                  label: 'Other Tool',
                  size: 6,
                },
                {
                  size: 3,
                  type: 'textArea',
                  field: 'desc',
                  label: 'Description',
                  required: true,
                },
              ],
            },
          ],
        },
        {
          groups : [
            {
              defaultHidden: true,
              label: 'Delivery Based Pricing',
              fields: [
                {
                  field: 'is_delivery_based_pricing',
                  type: 'checkbox',
                  label: 'Delivery Based Pricing',
                  size: 6,
                },
                {
                  field: 'delivery_pricing_date',
                  type: 'date',
                  label: 'Delivery Pricing Date',
                  size: 6,
                }
              ],
            },
          ]
        },
        {
          label: 'Project ownership & access',
          fields: [
            {
              collapsedFields: [
                {
                  label: 'PM',
                  field: 'owner_id',
                  type: 'autoComplete',
                  api: `/employeeSuggestions`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'official_email_id',
                  valueField: 'name',
                  size: 6,
                  required: true,
                },
                {
                  label: 'Observer',
                  field: 'observer',
                  type: 'multiAutoComplete',
                  api: `/users`,
                  suggestionField: 'name',
                  secondarySuggestionField: 'email',
                  valueField: 'name',
                },
                // {
                //   label: 'AM',
                //   field: 'account_owner',
                //   type: 'autoComplete',
                //   api: `/employeeSuggestions`,
                //   suggestionField: 'name',
                //   secondarySuggestionField: 'official_email_id',
                //   valueField: 'name',
                //   size: 6,
                //   required: true,
                // },
              ],
            },
          ],
        },
        {
          label: 'Feedback POC',
          fields: [
            {
              label: 'Name',
              field: 'client',
              type: 'multiAutoComplete',
              api: `/users`,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              valueField: 'name',
              filter: {
                userType: {$in: ['client', 'Client']},
              },
            },
          ],
        },
        // {
        //   groups: [
        //     {
        //       label: 'Practice',
        //       defaultHidden: true,
        //       fields: [
        //         {
        //           label: 'Primary Practice',
        //           field: 'primary_practice',
        //           type: 'autoComplete',
        //           api: `/practices`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //           required: true,
        //           // defaultExpanded: true,
        //           size: 4,
        //         },
        //       ],
        //     },
        //     {
        //       label: 'Product',
        //       defaultHidden: true,
        //       fields: [
        //         {
        //           label: 'Product',
        //           field: 'product',
        //           type: 'autoComplete',
        //           api: `/products`,
        //           suggestionField: 'name',
        //           valueField: 'name',
        //           required: true,
        //           size: 6,
        //         },
        //       ],
        //     },
        //   ],
        // },
      ]}
      {...props}
    />
  );
};

export const AddProjectForm = props => {
  return (
    <ProjectForm
      header={{
        title: 'Add Project',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
          // return (
          //   <Text style={{color: ERROR_HIGH}}>
          //     You can only add 'testing' project through locomo!
          //   </Text>
          // );
        },
      }}
      successMessage="Project Created."
      {...props}
    />
  );
};

export const AddUnbilledProjectForm = props => {
  return (
    <UnbilledProjectForm
      header={{
        title: 'Add Project',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
          // return (
          //   <Text style={{color: ERROR_HIGH}}>
          //     You can only add 'testing' project through locomo!
          //   </Text>
          // );
        },
      }}
      {...props}
    />
  );
};

export const EditBilledProjectForm = props => {
  return (
    <ProjectForm
      mode="edit"
      header={{
        title: 'Edit Project',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
          // return (
          //   <Text style={{color: ERROR_HIGH}}>
          //     You can only add 'testing' project through locomo!
          //   </Text>
          // );
        },
      }}
      {...props}
    />
  );
};

export const EditUnbilledProjectForm = props => {
  return (
    <UnbilledProjectForm
      mode="edit"
      header={{
        title: 'Edit Project',
        secondaryTitle: () => {
          const {ERROR_HIGH} = useTheme('colors');
          // return (
          //   <Text style={{color: ERROR_HIGH}}>
          //     You can only add 'testing' project through locomo!
          //   </Text>
          // );
        },
      }}
      {...props}
    />
  );
};

export const ProjectDetailsForm = props => {
  return (
    <ProjectForm
      mode="edit"
      readOnly={true}
      header={{
        title: 'Detail Project',
      }}
      fromDetail={true}
      {...props}
    />
  );
};

export const ProjectDescriptionForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projects',
    eventSourceId: 'Project',
    ...props,
  });
  let {route = {}} = props || {};
  let {params = {}} = route || {};
  let {project} = params || {};
  return (
    <Form
      mode="edit"
      header={{
        title: 'Project Description',
        secondaryTitle: project?.project,
      }}
      api={`/projects/${project?._id}`}
      fields={{
        desc: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'aiTextArea',
          field: 'desc',
          required: true,
          label: 'Description',
          inputProps: {
            minRows: 6,
            maxRows: 8,
          },
        },
      ]}
      {...props}
    />
  );
};

export const RequestEstimateRevision = props => {
  const {
    route: {params},
  } = props;
  const {project, readOnly} = params;

  const {onSubmit} = useFormSubmit({
    eventSourceId: ['Project', 'performance-report'],
    uri: '/projects',
  });

  return (
    <Form
      header={{
        title: !readOnly ? 'Request Estimate Revision' : 'Revision History',
        secondaryTitle: project.project,
      }}
      mode="edit"
      readOnly={readOnly}
      api={'/projects/' + project?._id}
      fields={{
        estimate_revision_history: 1,
        man_month_estimate: 1,
      }}
      defaultValues={{
        project_status: 'n',
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          nested: true,
          field: 'estimate_revision_history',
          render: props => {
            const {
              push,
              form: {values},
            } = props;
            return (
              <Col key={values?._id} gap={8}>
                {!readOnly ? (
                  <InlineForm
                    key={values?.estimate_revision_history?.length}
                    onSubmit={push}
                    defaultValues={{
                      revison_no:
                        (values?.estimate_revision_history?.length || 0) + 1,
                      revised_date: new Date(),
                      old_estimate: values?.man_month_estimate,
                    }}
                    layoutFields={[
                      {
                        field: 'revised_date',
                        label: 'Date',
                        type: 'date',
                        required: true,
                        readOnly: true,
                      },
                      {
                        field: 'old_estimate',
                        label: 'Old Estimate',
                        type: 'number',
                        required: true,
                        readOnly: true,
                      },
                      {
                        field: 'new_estimate',
                        label: 'New Estimate',
                        type: 'number',
                        required: true,
                      },
                      {
                        field: 'reason',
                        label: 'Reason',
                        type: 'textArea',
                        required: true,
                      },
                    ]}
                  />
                ) : (
                  void 0
                )}
                <NestedTable
                  {...props}
                  readOnly
                  columns={[
                    {
                      header: 'Revision',
                      field: 'revison_no',
                      type: 'text',
                    },
                    {
                      field: 'revised_date',
                      header: 'Date',
                      type: 'date',
                    },
                    {
                      field: 'old_estimate',
                      header: 'Old Estimate',
                      type: 'number',
                    },
                    {
                      field: 'new_estimate',
                      header: 'New Estimate',
                      type: 'number',
                    },
                    {
                      field: 'reason',
                      header: 'Reason',
                      type: 'textArea',
                    },
                  ]}
                />
              </Col>
            );
          },
        },
      ]}
    />
  );
};
