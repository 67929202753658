import React, {useEffect} from 'react';
import {getPreviousWeek} from '@unthinkable/react-filters/src/DateUtility';
import ProjectAboutTable from '../../department/views/ProjectAboutTable';
import {useAppStateContext} from '../../../providers/AppState';
import {createMenuNavigator} from '../../../components/menu/MenuNavigator';
import {ScreensTab} from './assets/ScreensTable';
import {DataEntitiesTab} from './assets/DataEntityScreen';
import {ThirdPartyIntegrationsTab} from './assets/ThirdPartyIntegrationScreen';
import {ProjectMilestoneTabs} from './ProjectMilestoneTabs';
import {ProjectThemeColorTable} from './ProjectThemeColorTable';
import {RoadmapTabs} from './RoadmapTabs';
import {IssueTabs} from './IssueTab';
import {QaTestCasesTab} from './assets/QATestCasesScreen';
import {DataServicesTab} from './assets/DataServiceScreen';
import {ApiEndpointsTab} from './assets/ApiEndPointsScreen';
import {ComponentsTab} from './assets/ComponentScreen';
import {ProjectTeamTab} from '../../task/views/ProjectTeamTab';
import {ProjectRoleTable} from './ProjectRoleTable';
import {UserStoriesTable} from './UserstoryTable';
import getPmtTooltip from '../constants/PmtTooltip';
import {RepositoriesTable} from './RepositoriesTable';
import {ProjectEnvironmentTable} from './ProjectEnvironmentTable';
import {Labels} from '../constants/Label';
import {ModulesActiveTable} from './ModulesActiveTable';
import {ProjectReportTab} from './ProjectReportTab';

const Project = createMenuNavigator();
const ProjectHome = props => {
  const {navigation, route: {params} = {}} = props;

  let {user} = useAppStateContext();

  const {project} = params || {};

  const mergedParams = {
    ...params,
    period: params?.period || getPreviousWeek(),
    project_id: params.project._id,
  };

  const PmtTooltip = getPmtTooltip({params});

  // const {data: deploymentRepositories = []} = useFetchData({
  //   api: `/projects/${params.project._id}/deploymentRepositories`,
  //   eventSourceId: ['Repository'],
  // });

  useEffect(() => {
    //if it came from notification then navigate to that screen
    //14-02-2024 Rohit Bansal  - open route passed in url
    if (params && params.__navigate) {
      navigation.navigate(params.__navigate.screen, params.__navigate.params);
    }
  }, []);

  return (
    <Project.Navigator
      {...props}
      route={{
        ...props.route,
        params: {
          ...mergedParams,
        },
      }}>
      <Project.Group>
        <Project.Screen
          name="Modules"
          component={ModulesActiveTable}
          props={{
            ...props,
            params: {
              ...mergedParams,
            },
          }}
          options={{
            tooltip: PmtTooltip.MODULES,
            label: 'Modules',
            api: `/projectmodules`,
            eventSourceId: 'Module',
            filter: {
              project_id: params.project._id,
              status: {$ne: 'archived'},
            },
          }}
        />
        <Project.Screen
          name="Roles"
          component={ProjectRoleTable}
          options={{
            tooltip: PmtTooltip.ROLES,
            label: 'Roles',
            api: '/projectroles',
            filter: {
              project_id: params.project._id,
            },
            eventSourceId: 'Role',
          }}
        />
      </Project.Group>
      <Project.Group>
        <Project.Screen
          name="Team"
          component={ProjectTeamTab}
          props={{user}}
          options={{
            tooltip: PmtTooltip.TEAM_TASKS,
            label: 'Team Tasks',
          }}
        />
      </Project.Group>
      <Project.Group>
        <Project.Screen
          name="Milestones"
          component={ProjectMilestoneTabs}
          props={{user: user}}
          options={{
            tooltip: PmtTooltip.MILESTONES,
            label: 'Milestones',
            api: '/projectmilestones/all',
            eventSourceId: 'Milestone',
            params: {
              project_id: params?.project?._id,
              status: {$in: ['backlog', 'active']},
            },
          }}
        />
        <Project.Screen
          name="Feature"
          component={RoadmapTabs}
          props={{user, fromProject: true, type: 'feature'}}
          options={{
            tooltip: PmtTooltip.FEATURES,
            label: Labels.Features,
            defaultSelected: !project?.ai_enabled,
            api: `/projectfeatures`,
            filter: {
              project_id: params.project._id,
              status: {$in: ['backlog', 'active']},
            },
            eventSourceId: ['Feature', 'Issue'],
          }}
        />
      </Project.Group>
      <Project.Group>
        <Project.Screen
          name={`${Labels.Requirements}`}
          props={{fromProject: true}}
          component={UserStoriesTable}
          options={{
            tooltip: PmtTooltip.REQUIREMENTS,
            label: `${Labels.Requirements}`,
            api: `/projectUserStories`,
            filter: {
              project_id: params.project._id,
            },
            eventSourceId: ['Feature'],
          }}
        />
        <Project.Screen
          name="Issues"
          component={IssueTabs}
          options={{
            tooltip: PmtTooltip.ISSUES,
            label: 'Issues',
            api: `/projectissues`,
            filter: {
              project_id: params.project._id,
              status: {$ne: 'resolved'},
            },
            eventSourceId: ['Issue'],
          }}
        />
      </Project.Group>
      <Project.Group>
        <Project.Screen
          name="Data Entities"
          component={DataEntitiesTab}
          options={{
            tooltip: PmtTooltip.SCHEMA,
            label: 'Models',
            api: '/projectmodels/released',
            addOnFilter: {
              project_id: params?.project?._id,
            },
            eventSourceId: ['Model', 'DataImport'],
          }}
        />
        <Project.Screen
          name="APIs"
          component={ApiEndpointsTab}
          options={{
            tooltip: PmtTooltip.APIs,
            label: 'APIs',
            api: '/projectcontrollers/released',
            addOnFilter: {
              project_id: params?.project?._id,
            },
            eventSourceId: 'Controller',
          }}
        />
        <Project.Screen
          name="Screens"
          component={ScreensTab}
          options={{
            tooltip: PmtTooltip.SCREENS,
            label: 'Screens',
            api: '/projectviews/released',
            addOnFilter: {
              project_id: params?.project?._id,
            },
            eventSourceId: 'View',
          }}
        />
        <Project.Screen
          name="Services"
          component={DataServicesTab}
          options={{
            // tooltip: PmtTooltip.DATA_SERVICES,
            label: 'Services',
            api: '/projectdataservices/released',
            addOnFilter: {
              project_id: params?.project?._id,
            },
            eventSourceId: 'DataService',
          }}
        />
        <Project.Screen
          name="Third Party Integrations"
          component={ThirdPartyIntegrationsTab}
          props={{user: user}}
          options={{
            tooltip: PmtTooltip.INTEGRATIONS,
            label: 'Integrations',
            api: '/projectthirdpartyintegrations/released',
            addOnFilter: {
              project_id: params?.project?._id,
            },
            eventSourceId: 'ThirdPartyIntegration',
          }}
        />
        <Project.Screen
          name="TestCases"
          component={QaTestCasesTab}
          options={{
            tooltip: PmtTooltip.TEST_CASES,
            label: 'TestCases',
            api: '/qatestcases/released',
            addOnFilter: {
              project_id: params?.project?._id,
            },
            eventSourceId: 'TestCase',
          }}
        />
      </Project.Group>

      <Project.Group>
        {/* <Project.Screen
          name="Theme"
          component={ProjectThemeTable}
          options={{
            tooltip: PmtTooltip.THEME,
            label: 'Theme',
            api: '/ProjectThemes',
            filter: {
              project_id: params?.project?._id,
            },
            eventSourceId: 'Theme',
          }}
        /> */}
        <Project.Screen
          name="Components"
          component={ComponentsTab}
          options={{
            tooltip: PmtTooltip.UI_COMPONENTS,
            label: 'UI Components',
            api: '/projectcomponents/released',
            addOnFilter: {
              project_id: params?.project?._id,
            },
            eventSourceId: ['Component', 'DataImport'],
          }}
        />
      </Project.Group>
      <Project.Group>
        <Project.Screen
          name="Environments"
          component={ProjectEnvironmentTable}
          props={{user: user}}
          options={{
            label: 'Environments',
          }}
        />
        <Project.Screen
          name="Repositories"
          component={RepositoriesTable}
          props={{user: user}}
          options={{
            label: 'Repositories',
            api: '/projectRepositories',
            addOnFilter: {
              project_id: params?.project?._id,
            },
            eventSourceId: 'Repository',
          }}
        />
      </Project.Group>
      <Project.Group>
        <Project.Screen
          name="About Project"
          component={ProjectAboutTable}
          props={{user: user}}
          options={{
            tooltip: PmtTooltip.SETTINGS,
            label: 'Settings',
          }}
        />
        <Project.Screen
          name="Reports"
          component={ProjectReportTab}
          props={{user: user}}
          options={{
            label: 'Reports',
          }}
        />
      </Project.Group>
    </Project.Navigator>
  );
};

export default ProjectHome;
