import React from 'react';
import {TabView} from '../../../components/tab/TabView';
import {ProjectsTable, TYPE, projectFilters} from './ProjectsTable';
import {AddButton} from '../../../components/button/AddButton';
import {useFilter} from '../../../controllers/useFilter';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {useAppStateContext} from '../../../providers/AppState';
import { ProjectDetailedName } from '../../../app-components/renders/ProjectDetailedName';
import { Table } from '../../../components/table/Table';
import { useTheme } from '@unthinkable/react-theme';
import {Text} from '@unthinkable/react-core-components';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import { PerformanceBilledReportTable } from './PerformanceReportTable';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { GroupContentItem } from '../../../components/card/GroupContentItem';


export const ProjectTabs = props => {
  const {route: {params} = {}} = props;
  const filtersInfo = useFilter();
  const {onChangeFilter, searchValue, filterValues = {}} = filtersInfo;
  const {user} = useAppStateContext();

  return (
    <TabView
      params={params}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Active',
          view: (
            <ProjectsTable
              searchValue={searchValue}
              {...filterValues}
              status="active"
            />
          ),
          eventSourceId: 'Project',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter {...filtersInfo} filters={projectFilters} />,
            user?.userType === 'employee' ? (
              <AddButton title={'Project'} view={'add-project'} />
            ) : (
              void 0
            ),
          ],
        },
        completed: {
          label: 'Completed',
          view: (
            <ProjectsTable
              searchValue={searchValue}
              {...filterValues}
              status="released"
            />
          ),
          eventSourceId: 'Project',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter {...filtersInfo} filters={projectFilters} />,
          ],
        },
      }}
    />
  );
};

export const ProjectActiveTabs = props => {
  const {route: {params} = {}} = props;
  const filtersInfo = useFilter();
  const {onChangeFilter, searchValue, filterValues = {}} = filtersInfo;
  const {user} = useAppStateContext();

  return (
    <TabView
      params={params}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Active',
          view: (
            <ProjectsTable
              searchValue={searchValue}
              {...filterValues}
              status="active"
            />
          ),
          eventSourceId: 'Project',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter {...filtersInfo} filters={projectFilters} />,
            user?.userType === 'employee' ? (
              <AddButton title={'Project'} view={'add-project'} />
            ) : (
              void 0
            ),
          ],
        },
        completed: {
          label: 'Closed',
          view: (
            <ProjectsTable
              searchValue={searchValue}
              {...filterValues}
              status="released"
              tab="closed"
            />
          ),
          eventSourceId: 'Project',
          actions: [
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              placeholder="Search"
            />,
            <GroupFilter {...filtersInfo} filters={projectFilters} />,
          ],
        },
      }}
    />
  );
};


export const DeliveryPricingProjectTabs = props => {
  const {route: {params} = {}} = props;
  return (
    <TabView
      params={{...params, fromdetailView : true}}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Project',
          view: (
            <DeliveryPricingProjectTable />
          ),
          eventSourceId: 'Project',
          api : '/deliverybasedprojectdata'
        },
      }}
    />
  );
};

export const DeliveryPricingProjectTable = props => {
  const {
    route: {params},
    tab,
  } = props;
  const {fonts, colors} = useTheme();
  return (
    <Table
      eventSourceId={['Project', 'Employee', 'Feature']}
      api={`/deliverybasedprojectdata`}
      params={{
        ...params,
        fromdetailView : true
      }}
      limit={50}
      columns={[
        {
          type: 'text',
          field: 'project',
          header: 'Project',
          render: ProjectDetailedName,
          minWidth: 250,
        },
        {
          header: 'Customer',
          width: 300,
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {row?.customer?.name}
              </Text>
            );
          },
        },
        TYPE,
        {
          type: 'userAvatarChip',
          field: 'owner_id',
          header: 'Owner',
          width: 160,
        },
      ]}
    />
  );
};

export const ProjectEfficiencyLocomoTabs = props => {
  const {route: {params} = {}} = props;
  return (
    <TabView
      params={{...params, fromdetailView : true ,fromLocomo : true}}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Project',
          view: (
            <PerformanceBilledReportTable 
            fromLocomo = {true}
            />

          ),
          eventSourceId: 'Project',
          // api : '/project/performance-report'
        },
      }}
    />
  );
};

export const ProjectEfficincyLocomoTable = props => {
  const {
    route: {params},
    tab,
  } = props;
  const {fonts, colors} = useTheme();
  return (
    <Table
      eventSourceId={['Project', 'Employee', 'Feature']}
      api={'/taskEfficienyDataForNonTechProjects/locomo'}
      params={{
        ...params,
        fromdetailView : true
      }}
      limit={10000}
      columns={[
        {
          type: 'text',
          field: 'project',
          header: 'Project',
          render: ProjectDetailedName,
          width: 250,
        },
        {
          header: 'Customer',
          width: 250,
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {row?.customer?.name}
              </Text>
            );
          },
        },
        TYPE,
        {
          type: 'userAvatarChip',
          field: 'owner_id',
          header: 'Owner',
          width: 150,
        },
        {
          render: CountCellRender,
          aggrgate :  true,
          type : 'number',
          field: 'employee_count',
          header: 'Employee Count',
          count_field: 'employee_count',
          width: 160,
        },
        {
          type: 'text',
          field: 'efficiency',
          header: 'Efficiency',
          width: 160,
        },
      ]}
    />
  );
};

export const ProjectEfficiencyOtherTabs = props => {
  const {route: {params} = {}} = props;
  return (
    <TabView
      params={{...params, fromdetailView : true}}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Project',
          view: (
            <ProjectEfficincyOtherTable />
          ),
          eventSourceId: 'Project',
          // api : '/allocationAndDeliveredForNonTechProjects/other'
        },
      }}
    />
  );
};

export const ProjectEfficincyOtherTable = props => {
  const {
    route: {params},
    tab,
  } = props;
  const {fonts, colors} = useTheme();
  return (
    <Table
      eventSourceId={['Project', 'Employee', 'Feature']}
      api={'/allocationAndDeliveredForNonTechProjects/other'}
      params={{
        ...params,
        fromdetailView : true
      }}
      groupRow={{
        data: '_children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.project.project} />
              <GroupContentItem value={`${(Number((row?.billedHours)) || 0)} Hours`} />
            </RowWithSeparator>
          );
        },
      }}
      limit={10000}
      columns={[
        {
          type: 'text',
          field: 'project.project',
          header: 'Project',
          width: 250,
        },
        {
          header: 'Customer',
          width: 250,
          render: ({row, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {row?.customer?.name}
              </Text>
            );
          },
        },
        {
          type: 'text',
          field: 'project_resource_working_data.delivery_number',
          header: 'Delivery',
          width: 250,
        },
        {
          type: 'number',
          field: 'billed_hours',
          header: 'Billed Hours',
        }
      ]}
    />
  );
};

export const ProjectEfficiencyNonTechTabs = props => {
  const {route: {params} = {}} = props;
  return (
    <TabView
    params={{...params, fromdetailView : true ,fromNonTech : true}}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Project',
          view: (
            <PerformanceBilledReportTable
            fromNonTech = {true}
             />
          ),
          eventSourceId: 'Project',
          // api : '/project/performance-report'
        },
      }}
    />
  );
};

export const ProjectEfficincyNonTechTable = props => {
  const {
    route: {params},
    tab,
  } = props;
  const {fonts, colors} = useTheme();
  return (
    <Table
      eventSourceId={['Project', 'Employee', 'Feature']}
      api={'/taskEfficienyDataForNonTechProjects/nontech'}
      params={{
        ...params,
        fromdetailView : true
      }}
      limit={10000}
      columns={[
        {
          type: 'text',
          field: 'project',
          header: 'Project',
          render: ProjectDetailedName,
          minWidth: 250,
        },
        {
          type: 'userAvatarChip',
          field: 'owner_id',
          header: 'Owner',
          width: 160,
        },
        {
          render: CountCellRender,
          aggrgate :  true,
          type : 'number',
          field: 'employee_count',
          header: 'Employee Count',
          count_field: 'employee_count',
          width: 160,
        },
        {
          type: 'text',
          field: 'efficiency',
          header: 'Efficiency',
          width: 160,
        },
      ]}
    />
  );
};