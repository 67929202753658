import {Text} from '@unthinkable/react-core-components';
import {formats, TextRender} from '@unthinkable/react-table';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {TableHeader} from '../../../components/table/Headers';
import {GroupTable, Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {AmountRender} from './components/AmountRender';
import {renderLocale} from './OrderTable';
import { TabView } from '../../../components/tab';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { GroupContentItem } from '../../../components/card/GroupContentItem';

const RELATION = {
  field: 'account_owner_id',
  header: 'Relation',
  type: 'userAvatarChip',
  width: 240,
};

const DATE = {
  type: 'date',
  width: 150,
  field: 'invoice_date',
  header: 'Invoice Date',
  formatOptions: {
    format: 'DD MMM YY',
  },
};

const TYPES = {
  header: 'Type',
  width: 100,
  render: ({row, styles}) => {
    let {invoice_type} = row;
    let Type = '';
    if (invoice_type === 'i') {
      Type = 'ACT-S';
    } else if (invoice_type === 'c') {
      Type = 'CN';
    } else if (invoice_type === 'a') {
      Type = 'ADV-S';
    }
    return <Text style={{...styles.rowText}}>{Type}</Text>;
  },
};

const TYPE = {
  header: 'Type',
  width: 80,
  render: ({row, styles}) => {
    let {invoice_type} = row;
    let Type = '';
    let billing_model = '';
    if (invoice_type === 'i') {
      Type = 'ACT';
    } else if (invoice_type === 'c') {
      Type = 'CN';
    } else if (invoice_type === 'a') {
      Type = 'ADV-S';
    }
    if (row.pricing_type === 'ot') {
      billing_model = 'Fixed';
    } else {
      billing_model = 'T&M';
    }
    return (
      <PrimaryColumnCard primaryTitle={Type} items={[{value: billing_model}]} />
    );
  },
};

const BILLINGMODEL = {
  header: 'Billing Model',
  width: 150,
  render: ({row, styles}) => {
    let billing_model = '';
    if (row.pricing_type === 'ot') {
      billing_model = 'Fixed';
    } else {
      billing_model = 'T&M';
    }
    return <Text style={{...styles.rowText}}>{billing_model}</Text>;
  },
};

const INVOICE_NUMBER = {
  field: 'invoice_number',
  header: 'Invoice',
  width: 150,
  render: ({row, styles}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.invoice_number}
        items={[{value: moment(row?.invoice_date).format('DD MMM YY')}]}
      />
    );
  },
};
const INVOICENUMBER = {
  field: 'invoice_number',
  header: 'Invoice No',
  width: 150,
};

const CUSTOMER = {
  type: 'userAvatarChip',
  field: 'customer',
  header: 'Customer',
  minWidth: 220,
};

const AM = {
  header: 'AM',
  type: 'userAvatar',
  field: 'account_owner_id',
  width: 80,
};

const STATUS = {
  field: 'status',
  header: 'Status',
  width: 120,
  render: ({row, styles}) => {
    let {status} = row;
    if (status === 'New') {
      return <Text style={{...styles.rowText}}>Pending</Text>;
    } else if (status === 'Fully Invoiced') {
      return <Text style={{...styles.rowText}}>Paid</Text>;
    } else if (status === 'Partilly Invoiced') {
      return <Text style={{...styles.rowText}}>Partially paid</Text>;
    } else if (status === 'Cancelled') {
      return <Text style={{...styles.rowText}}>Cancelled</Text>;
    }
  },
};

const DUEDATE = {
  header: 'Due on',
  field: 'due_date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM YY',
  },
  width: 100,
};

const BASE_AMOUNT = {
  field: 'base_invoice_amount',
  header: 'Base Amount',
  type: 'currency',
  width: 200,
};

const AMOUNT = {
  aggregate: true,
  field: 'base_invoice_amount',
  header: 'Amount',
  type: 'currency',
  align: 'right',
  width: 140,
  render: ({row, styles}) => {
    return (
      <AmountRender
        row={row}
        styles={styles}
        amount={row?.invoice_amount || 0}
        base_amount={row?.base_invoice_amount || 0}
        currency={row.currency?.currency}
      />
    );
  },
};

const UNPAID_AMOUNT = {
  aggregate: true,
  field: 'base_pending_receipt_amount',
  header: 'Unpaid Amount',
  type: 'currency',
  width: 150,
  render: ({row, styles}) => {
    return (
      <AmountRender
        row={row}
        styles={styles}
        amount={row?.pending_receipt_amount || 0}
        base_amount={row?.base_pending_receipt_amount || 0}
        currency={row.currency?.currency}
      />
    );
  },
};
const UNPAIDFOREIGNAMOUNT = {
  aggregate: true,
  field: 'pending_receipt_amount',
  header: 'Foreign Unpaid Amount',
  type: 'currency',
  width: 200,
  render: ({row, styles}) => {
    const colors = useTheme('colors');
    return (
      <TextRender
        value={row?.pending_receipt_amount}
        styles={styles}
        format={formats.formatCurrency}
        formatOptions={{
          locale: renderLocale(row?.currency?.currency),
          currency: row?.currency?.currency,
          maximumFractionDigits: 0,
        }}
        color={colors.NEUTRAL_LOW}
      />
    );
  },
};

const UNPAID_ADVANCE_AMOUNT = {
  aggregate: true,
  field: 'base_pending_advance_amount',
  header: 'Unpaid Amount',
  type: 'currency',
  width: 200,
  render: ({row, styles}) => {
    return (
      <AmountRender
        row={row}
        styles={styles}
        amount={row?.pending_advance_amount || 0}
        base_amount={row?.base_pending_advance_amount || 0}
        currency={row.currency?.currency}
      />
    );
  },
};

const PENDING_RECEIPT_AMOUNT = {
  aggregate: true,
  field: 'base_pending_receipt_amount',
  header: 'Unpaid Amount',
  type: 'currency',
  width: 200,
  render: ({row, styles}) => {
    return (
      <AmountRender
        row={row}
        styles={styles}
        amount={row?.pending_receipt_amount || 0}
        base_amount={row?.base_pending_receipt_amount || 0}
        currency={row.currency?.currency}
      />
    );
  },
};

const INR_NUMBER = {
  field: 'irn',
  header: 'IRN Number',
  type: 'text',
  width: 250,
};

const activityLogDetailForInvoice = ({row, navigation}) => {
  navigation.navigate(`activity-logs`, {
    _id: row._id,
    populate: [{path: 'account_owner_id', select: {name: 1}}],
    api: `/revenueinvoices/${row?._id}/activityLogs`,
    displayFields: {
      desc: {
        label: 'Description',
      },
      status: {
        label: 'status',
      },
      owner: {
        label: 'Owner',
        field: 'account_owner_id.name',
      },
      invoice_number: {
        label: 'Invoice Number',
        field: 'invoice_number',
      },
      invoice_date: {
        label: 'Invoice Date',
        field: 'invoice_date',
      },
      base_invoice_amount: {
        label: 'Base Invoice Amount',
        field: 'base_invoice_amount',
      },
      invoice_amount: {
        label: 'Invoice Amount',
        field: 'invoice_amount',
      },
      pending_receipt_amount: {
        label: 'Pending Receipt Amount',
        field: 'pending_receipt_amount',
      },
      invoice_submission_date: {
        label: 'Invoice Submission Date',
        field: 'invoice_submission_date',
      },
    },
  });
};

export const RevenueInvoiceAllTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  if (params?.delivery_id) {
    addOnFilter['invoice_details.delivery_id'] = params.delivery_id;
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'revenue-invoice',
  });
  const {download} = useAppStateContext();
  const {invoiceFilter = {}} = params;
  const fetchProps = {
    params,
    searchValue,
    addOnFilter,
    api: '/revenueInvoices/all',
    addonGroupBy: groupBy?.addonGroupBy,
    groupRow: groupBy?.groupRow,
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      AM: 'account_owner_id.name',
                      'Pricing Type': 'pricing_type',
                      'Invoice no.': 'invoice_number',
                      Currency: 'currency.currency',
                      Date: 'invoice_date',
                      Type: 'invoiceType',
                      Model: 'invoice_billing_model',
                      'Base Amount': 'base_invoice_amount',
                      Amount: 'invoice_amount',
                      'Tax Amount': 'total_tax_amount',
                      'Base Tax Amount': 'base_total_tax_amount',
                      'Total Amount': 'total_invoice_amount',
                      'Base Total Amount': 'base_total_invoice_amount',
                      'Base Unpaid Amount': 'base_pending_receipt_amount',
                      'Unpaid Amount': 'pending_receipt_amount',
                      'Due date': 'due_date',
                      Status: 'invoice_status',
                      IRN: 'irn',
                      'Created On': 'createdAt',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/revenueInvoices/all`}
      search={searchValue}
      // limit={100}
      addOnFilter={{...addOnFilter, ...invoiceFilter}}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields="invoice_number"
      renderHeader={() => {
        return (
          Object.keys(invoiceFilter).length > 0 && (
            <TableHeader title="Invoices" />
          )
        );
      }}
      params={params}
      onRowPress={({row}) => {
        navigation.navigate(`non-editable-revenue-invoice`, {
          row,
        });
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        TYPE,
        AMOUNT,
        UNPAID_AMOUNT,
        UNPAIDFOREIGNAMOUNT,
        DUEDATE,
        STATUS,
        INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`editable-revenue-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {invoice: row._id},
            });
          },
        },
        {
          title: 'Receipts',
          onPress: ({row}) => {
            navigation.navigate('open-receipts', {invoice: row._id});
          },
        },
        {
          title: 'Mark Is Service Invoice',
          onPress: ({row}) => {
            navigation.navigate('mark-is-services', {
              row,
            });
          },
        },
        {
          title: 'Update invoice submission date',
          onPress: ({row}) => {
            navigation.navigate(`update-invoice-ubmission-date`, {
              row,
            });
          },
        },
        {
          title: 'Update Softex Details',
          onPress: ({row}) => {
            navigation.navigate(`update-softex-details`, {
              row,
            });
          },
        },
        {
          title: 'Update Invoice Date',
          onPress: ({row}) => {
            navigation.navigate(`update-revenue-invoice-date`, {
              row,
            });
          },
        },
        {
          title: 'Settle Invoice',
          onPress: ({row}) => {
            navigation.navigate(`settle-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Adjust Invoice',
          onPress: ({row}) => {
            navigation.navigate(`adjust-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Cancel',
          confirm: {
            title: 'Cancel Invoice',
            message: 'Do you want to cancel this invoice',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/revenueInvoices/cancel/${row?._id}`,
              props: row,
            });
          },
        },
        {
          title: 'Print Voucher',
          onPress: ({row}) => {
            download({
              uri: '/voucher/print-voucher',
              props: {
                invoiceId: row._id,
              },
            });
          },
        },
        {
          title: 'Download Invoice PDF',
          onPress: ({row}) => {
            navigation.navigate(`download-invoice`, {
              row,
            });
          },
        },
        {
          title: 'New Advance Invoice',
          onPress: ({row}) => {
            navigation.navigate(`download-invoice`, {
              row,
              newPDF :true
            });
          },
        },
        {
          title: 'Download Release ',
          onPress: ({row}) => {
            navigation.navigate(`download-project-release-features`, {
              row,
            });
          },
        },
        {
          title: 'Send Mail',
          confirm: {
            title: 'Send Mail',
            message: 'Do you want to Send Mail for this invoice',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/revenueInvoices/send-mail`,
              props: {_selectedid: row._id, callfrom: 'MailInvoice'},
            });
          },
        },
        {
          title: 'Sync With GST',
          confirm: {
            title: 'Sync With GST',
            message: 'Do you want to Sync this invoice',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/revenueInvoices/sync-gst/${row?._id}`,
              props: row,
            });
          },
        },
        {
          title: 'Activity Logs',
          onPress: ({row}) => activityLogDetailForInvoice({row, navigation}),
        },
      ]}
    />
  );
};

export const RevenueInvoiceAllTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'revenue-invoice',
  });
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const fetchProps = {
    api: '/revenueInvoices/all',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      AM: 'account_owner_id.name',
                      'Invoice no.': 'invoice_number',
                      Currency: 'currency.currency',
                      Date: 'invoice_date',
                      Type: 'invoiceType',
                      Model: 'invoice_billing_model',
                      Amount: 'base_invoice_amount',
                      'Unpaid Amount': 'base_pending_receipt_amount',
                      'Due date': 'due_date',
                      Status: 'invoice_status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/revenueInvoices/all`}
      search={searchValue}
      addOnFilter={{
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
        ...addOnFilter,
      }}
      searchFields="invoice_number"
      params={params}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      onRowPress={({row}) => {
        navigation.navigate(`non-editable-revenue-invoice`, {
          row,
        });
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_AMOUNT,
        UNPAIDFOREIGNAMOUNT,
        DUEDATE,
        STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`editable-revenue-invoice`, {
              row,
            });
          },
        },
        // {
        //   title: 'Voucher',
        //   onPress: ({row}) => {
        //     navigation.navigate(`open-voucher`, {
        //       invoice: row._id,
        //     });
        //   },
        // },
        // {
        //   title: 'Update invoice submission date',
        //   onPress: ({row}) => {
        //     navigation.navigate(`update-invoice-ubmission-date`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Update Softex Details',
        //   onPress: ({row}) => {
        //     navigation.navigate(`update-softex-details`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Settle Invoice',
        //   onPress: ({row}) => {
        //     navigation.navigate(`settle-invoice`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Adjust Invoice',
        //   onPress: ({row}) => {
        //     navigation.navigate(`adjust-invoice`, {
        //       row,
        //     });
        //   },
        // },
        // {
        //   title: 'Cancel',
        //   confirm: {
        //     title: 'Cancel Invoice',
        //     message: 'Do you want to cancel this invoice',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/revenueInvoices/cancel/${row?._id}`,
        //       props: row,
        //     });
        //   },
        // },
        // {
        //   title: 'Print Voucher',
        //   onPress: ({row}) => {
        //     download({
        //       uri: '/voucher/print-voucher',
        //       props: {
        //         invoiceId: row._id,
        //       },
        //     });
        //   },
        // },
        {
          title: 'Download Invoice PDF',
          onPress: ({row}) => {
            navigation.navigate(`download-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Send Mail',
          confirm: {
            title: 'Send Mail',
            message: 'Do you want to Send Mail for this invoice',
          },
          onPress: props => {
            const {row} = props;
            invoke({
              uri: `/revenueInvoices/send-mail`,
              props: {_selectedid: row._id, callfrom: 'MailInvoice'},
            });
          },
        },
        // {
        //   title: 'Sync With GST',
        //   confirm: {
        //     title: 'Sync With GST',
        //     message: 'Do you want to Sync this invoice',
        //   },
        //   onPress: props => {
        //     const {row} = props;
        //     invoke({
        //       uri: `/revenueInvoices/sync-gst`,
        //       props: row,
        //     });
        //   },
        // },
        // {
        //   title: 'Activity Logs',
        //   onPress: ({row}) => activityLogDetailForInvoice({row, navigation}),
        // },
      ]}
    />
  );
};

export const RevenueInvoiceActualTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    groupBy,
    addOnFilter = {},
  } = props;
  const fetchProps = {
    api: '/revenueInvoices/actual',
    addonGroupBy: groupBy?.addonGroupBy,
    groupRow: groupBy?.groupRow,
  };
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      AM: 'account_owner_id.name',
                      'Invoice no.': 'invoice_number',
                      Date: 'invoice_date',
                      Type: 'invoiceType',
                      Model: 'invoice_billing_model',
                      Amount: 'base_invoice_amount',
                      'Unpaid Amount': 'base_pending_receipt_amount',
                      'Due date': 'due_date',
                      Status: 'invoice_status',
                      IRN: 'irn',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/revenueInvoices/actual`}
      params={params}
      search={searchValue}
      addOnFilter={addOnFilter}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields="invoice_number"
      onRowPress={({row}) => {
        navigation.navigate(`non-editable-revenue-invoice`, {
          row,
        });
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        TYPE,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        STATUS,
        INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`editable-revenue-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {invoice: row._id},
            });
          },
        },
        {
          title: 'Receipts',
          onPress: ({row}) => {
            navigation.navigate('open-receipts', {invoice: row._id});
          },
        },
      ]}
    />
  );
};

export const RevenueInvoiceActualTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const fetchProps = {
    api: '/revenueInvoices/actual',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      AM: 'account_owner_id.name',
                      'Invoice no.': 'invoice_number',
                      Date: 'invoice_date',
                      Type: 'invoiceType',
                      Model: 'invoice_billing_model',
                      Amount: 'base_invoice_amount',
                      'Unpaid Amount': 'base_pending_receipt_amount',
                      'Due date': 'due_date',
                      Status: 'invoice_status',
                      IRN: 'irn',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/revenueInvoices/actual`}
      params={params}
      search={searchValue}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      addOnFilter={{
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
        ...addOnFilter,
      }}
      searchFields="invoice_number"
      onRowPress={({row}) => {
        navigation.navigate(`non-editable-revenue-invoice`, {
          row,
        });
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`editable-revenue-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              invoice: row._id,
            });
          },
        },
      ]}
    />
  );
};

export const RevenueInvoiceAdvancedTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  const fetchProps = {
    api: '/revenueInvoices/advance',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      AM: 'account_owner_id.name',
                      'Invoice no.': 'invoice_number',
                      Date: 'invoice_date',
                      Type: 'invoiceType',
                      Model: 'invoice_billing_model',
                      Amount: 'base_invoice_amount',
                      'Unpaid Amount': 'base_pending_receipt_amount',
                      'Due date': 'due_date',
                      Status: 'invoice_status',
                      IRN: 'irn',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/revenueInvoices/advance`}
      params={params}
      search={searchValue}
      addOnFilter={addOnFilter}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      searchFields="invoice_number"
      onRowPress={({row}) => {
        navigation.navigate(`non-editable-revenue-invoice`, {
          row,
        });
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        TYPE,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        STATUS,
        INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`editable-revenue-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {invoice: row._id},
            });
          },
        },
        {
          title: 'Adjust Invoice With Receipt',
          onPress: ({row}) => {
            navigation.navigate(`adjust-invoice`, {
              row: {...row, advanceInvoice: true},
            });
          },
        },
        {
          title: 'Receipts',
          onPress: ({row}) => {
            navigation.navigate('open-receipts', {invoice: row._id});
          },
        },
      ]}
    />
  );
};

export const RevenueInvoiceAdvancedTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const fetchProps = {
    api: '/revenueInvoices/advance',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      AM: 'account_owner_id.name',
                      'Invoice no.': 'invoice_number',
                      Date: 'invoice_date',
                      Type: 'invoiceType',
                      Model: 'invoice_billing_model',
                      Amount: 'base_invoice_amount',
                      'Unpaid Amount': 'base_pending_receipt_amount',
                      'Due date': 'due_date',
                      Status: 'invoice_status',
                      IRN: 'irn',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/revenueInvoices/advance`}
      params={params}
      search={searchValue}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      addOnFilter={{
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
        ...addOnFilter,
      }}
      searchFields="invoice_number"
      onRowPress={({row}) => {
        navigation.navigate(`non-editable-revenue-invoice`, {
          row,
        });
      }}
      eventSourceId="revenue-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`editable-revenue-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              invoice: row._id,
            });
          },
        },
      ]}
    />
  );
};

export const PendingInvoiceTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  return (
    <Table
      api={`/revenueInvoices/all`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      addOnFilter={{
        ...addOnFilter,
        base_pending_receipt_amount: {$gt: 0},
        invoice_type: {$in: ['i', 'a']},
      }}
      eventSourceId="pending-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICENUMBER,
        DATE,
        TYPES,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
      ]}
      moreActions={[
        {
          title: 'Settle Invoice',
          onPress: ({row}) => {
            navigation.navigate(`settle-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Adjust Invoice',
          onPress: ({row}) => {
            navigation.navigate(`adjust-invoice`, {
              row,
            });
          },
        },
      ]}
    />
  );
};

export const PendingInvoiceTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      api={`/revenueInvoices/all`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addOnFilter={{
        ...addOnFilter,
        base_pending_receipt_amount: {$gt: 0},
        invoice_type: {$in: ['i', 'a']},
        account_owner_id: employeeId,
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      eventSourceId="pending-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
    />
  );
};

export const CreditNotePendingTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const fetchProps = {
    api: '/revenueInvoices/all',
  };
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      AM: 'account_owner_id.name',
                      'Invoice no.': 'invoice_number',
                      Date: 'invoice_date',
                      Type: 'invoiceType',
                      Model: 'invoice_billing_model',
                      Amount: 'base_invoice_amount',
                      'Unpaid Amount': 'base_pending_receipt_amount',
                      'Due date': 'due_date',
                      Status: 'invoice_status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/revenueInvoices/all`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addOnFilter={{...addOnFilter, invoice_type: 'c'}}
      eventSourceId="pending-invoice"
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        TYPE,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        STATUS,
        INR_NUMBER,
      ]}
    />
  );
};

export const CreditNotePendingTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  const fetchProps = {
    api: '/revenueInvoices/all',
  };
  return (
    <Table
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      Customer: 'customer.name',
                      AM: 'account_owner_id.name',
                      'Invoice no.': 'invoice_number',
                      Date: 'invoice_date',
                      Type: 'invoiceType',
                      Model: 'invoice_billing_model',
                      Amount: 'base_invoice_amount',
                      'Unpaid Amount': 'base_pending_receipt_amount',
                      'Due date': 'due_date',
                      Status: 'invoice_status',
                    },
                  },
                },
              }),
          },
        ],
      }}
      api={`/revenueInvoices/all`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      addOnFilter={{
        ...addOnFilter,
        invoice_type: 'c',
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
      }}
      eventSourceId="pending-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
    />
  );
};

export const AdvancePendingTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
  } = props;
  return (
    <Table
      api={`/revenueInvoices/advance`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addOnFilter={{...addOnFilter}}
      eventSourceId="pending-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_ADVANCE_AMOUNT,
        DUEDATE,
        STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
    />
  );
};

export const AdvancePendingTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
  } = props;
  const {
    download,
    user: {employee: employeeId},
  } = useAppStateContext();
  return (
    <Table
      api={`/revenueInvoices/advance`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addOnFilter={{account_owner_id: employeeId, ...addOnFilter}}
      eventSourceId="pending-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        UNPAID_ADVANCE_AMOUNT,
        DUEDATE,
        STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
    />
  );
};

export const OverduePendingTableAm = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy = {},
  } = props;
  const {
    download,
    user: {
      employee: {_id: employeeId, team: employeeTeam},
    },
  } = useAppStateContext();
  return (
    <Table
      api={`/revenueInvoices/overdue`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addOnFilter={{
        $or: [
          {
            account_owner_id: employeeId,
          },
          {
            account_owner_id: {
              $in: employeeTeam,
            },
          },
        ],
        ...addOnFilter,
      }}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      eventSourceId="pending-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        DATE,
        TYPE,
        BILLINGMODEL,
        AMOUNT,
        PENDING_RECEIPT_AMOUNT,
        DUEDATE,
        STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {invoice: row._id},
            });
          },
        },
      ]}
    />
  );
};

export const OverduePendingTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  return (
    <Table
      api={`/revenueInvoices/overdue`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addOnFilter={{...addOnFilter}}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      eventSourceId="pending-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        // DATE,
        TYPE,
        // BILLINGMODEL,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
        // STATUS,
        // RELATION,
        // BASE_AMOUNT,
        // INR_NUMBER,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`editable-revenue-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {invoice: row._id},
            });
          },
        },
        {
          title: 'Settle Invoice',
          onPress: ({row}) => {
            navigation.navigate(`settle-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Adjust Invoice',
          onPress: ({row}) => {
            navigation.navigate(`adjust-invoice`, {
              row,
            });
          },
        },
      ]}
    />
  );
};

export const PendingTable = props => {
  const {
    route: {params} = {},
    navigation,
    searchValue,
    addOnFilter = {},
    groupBy,
  } = props;
  return (
    <Table
      api={`/revenueInvoices/pending`}
      params={params}
      search={searchValue}
      searchFields="invoice_number"
      addOnFilter={{...addOnFilter}}
      addonGroupBy={groupBy?.addonGroupBy}
      groupRow={groupBy?.groupRow}
      allowDynamicGrouping={true}
      eventSourceId="pending-invoice"
      columns={[
        CUSTOMER,
        AM,
        INVOICE_NUMBER,
        TYPE,
        AMOUNT,
        UNPAID_AMOUNT,
        DUEDATE,
      ]}
      moreActions={[
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate(`editable-revenue-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Voucher',
          onPress: ({row}) => {
            navigation.navigate(`open-voucher`, {
              extraFilter: {invoice: row._id},
            });
          },
        },
        {
          title: 'Settle Invoice',
          onPress: ({row}) => {
            navigation.navigate(`settle-invoice`, {
              row,
            });
          },
        },
        {
          title: 'Adjust Invoice',
          onPress: ({row}) => {
            navigation.navigate(`adjust-invoice`, {
              row,
            });
          },
        },
      ]}
    />
  );
};

export const AgeingReportInvoice = props => {
  const {route: {params} = {}, navigation} = props;
  const {filter = {}} = params;
  return (
    <Table
      api={'/ageingReportInvoice'}
      addOnFilter={filter}
      columns={[
        {
          header: 'Customer',
          field: 'customer.name',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Invoice Date',
          field: 'invoice_date',
          type: 'date',
          width: 200,
        },
        {
          header: 'Due Date',
          field: 'due_date',
          type: 'date',
          width: 200,
        },
        {
          header: 'Invoice no',
          type: 'text',
          field: 'invoice_number',
          width: 150,
        },
        {
          header: 'Status',
          type: 'text',
          field: 'status',
          width: 150,
        },
        {
          header: 'Amount',
          field: 'base_invoice_amount',
          aggregate: true,
          count_field: 'base_invoice_amount',
          type: 'number',
          width: 150,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
        },
        {
          header: 'Pending Receipt Amount',
          field: 'base_pending_receipt_amount',
          aggregate: true,
          count_field: 'base_pending_receipt_amount',
          type: 'number',
          width: 200,
          formatOptions: {
            maximumFractionDigits: 2,
          },
          toFixed: 2,
        },
      ]}
    />
  );
};

export const CustomerWiseRevenueInvoiceTable = props => {
  const {
    route: {params},
    tab,
  } = props;
  const {fonts, colors} = useTheme();
  return (
    <GroupTable
      {...props}
      params={{
        ...params,
        fromdetailView : true
      }}
      api={'/customerwiseRevenueData'} 
      limit = {50000}//query on backend
      groupRow={{
        data: '_children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.customer.name} />
              <GroupContentItem value={`${(Number((row?.base_invoice_amount).toFixed(0)) || 0).toLocaleString('en-IN')} Amount`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Customer',
          field : 'customer',
          type: 'text',
          minWidth: 200,
        },
        {
          header: 'Invoice No.',
          type: 'text',
          field: 'invoice_number',
          width: 150,
        },
        {
          header: 'Invoice Date',
          type: 'date',
          field: 'invoice_date',
          width: 120,
          formatOptions: { format: 'DD MMM YY' },
        },
        {
          aggregate: true,
          field: 'base_invoice_amount',
          header: 'Amount',
          type: 'currency',
          width: 150,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.invoice_amount || 0}
                base_amount={row?.base_invoice_amount || 0}
              />
            );
          },
        },
        {
          field: 'base_pending_receipt_amount',
          header: 'Unpaid Amount',
          type: 'currency',
          width: 150,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.pending_receipt_amount || 0}
                base_amount={row?.base_pending_receipt_amount || 0}
              />
            );
          },
        },
      ]}
    />
  );
};


export const CustomerWiseRevenueInvoiceTab = props => {
  const {route: {params} = {}} = props;
  return (
    <TabView
      params={{...params, fromdetailView : true}}
      {...props}
      tabs={{
        active: {
          apiKey: 'active',
          label: 'Invoice',
          view: (
            <CustomerWiseRevenueInvoiceTable />
          ),
          eventSourceId: 'Revenue',
          api : '/customerwiseRevenueData'
        },
      }}
    />
  );
};
