import React from 'react';
import {Table} from '../../../../components/table/Table';
import {TableHeader} from '../../../../components/table/Headers';
import {AddButton} from '../../../../components/button/AddButton';
import {UserAvatarWithNameAndEmail} from '../../../reimbursement/components/ReimbursementColumnRenders';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import {View, Text} from '@unthinkable/react-core-components';
import moment from 'moment';
import {
  Durations,
  TimeDuration,
} from '../../../order/views/components/Durations';
import {PrimaryColumnCard} from '../../../../components/card/PrimaryColumnCard';
import {useAppStateContext} from '../../../../providers/AppState';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const AllAttendanceTable = props => {
  let {
    route: {params},
    navigation,
    filter,
    searchValue,
    filterParams,
    periods,
    tab,
  } = props;
  const {
    period,
    filter: attendanceFilter = {},
    fromReport,
    fromDepartmentReport,
    daywisefilter,
    ...rest
  } = params;
  params = {
    ...rest,
  };
  if (fromDepartmentReport && period) {
    periods = period;
  }
  params = {/* ...params, */ ...filterParams, ...filter, periods};
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    api: `/attendance/all`,
    addOnFilter: {...filter, ...attendanceFilter, ...daywisefilter},
  };
  return (
    <Table
      api={'/attendance/all'}
      params={params}
      eventSourceId={'allattendance'}
      addOnFilter={{...filter, ...attendanceFilter, ...daywisefilter}}
      search={searchValue}
      fields={{
        employee: {
          _id: 1,
          name: 1,
          color: 1,
          official_email_id: 1,
          employee_code: 1,
        },
        first_attendance_type_id: {name: 1},
        second_attendance_type_id: {name: 1},
        attendance_date: 1,
        in_time: 1,
        out_time: 1,
        time_in_office: 1,
        break_time: 1,
        status: 1,
        remark: 1,
        purpose: 1,
        progress_hours: 1,
      }}
      selection={{
        actions: [
          {
            title: 'Update Attendance',
            visible: () => (fromReport ? false : true),
            onPress: ({selectedIds}) => {
              navigation.navigate('update-multiple-attendance', {
                ...params,
                selectedIds,
              });
            },
          },
          {
            title: 'Download',
            visible: () => {
              if (tab === 'daywiseAttendance') {
                return true;
              }
            },
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'Official Email Id': 'employee.official_email_id',
                      Date: 'attendance_date',
                      Day: 'dayOfWeek',
                      'First Half': 'first_attendance_type_id.name',
                      'Second Half': 'second_attendance_type_id.name',
                      'Time In Office': 'value',
                      'Break Time': 'break_time_value',
                      Time: 'time',
                      Remark: 'remark',
                      Purpose: 'purpose',
                      Status: 'status',
                    },
                    filename: 'Daily Attendance',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        // {header: 'Employee', field: 'employee.name', type: 'text'},
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 280,
        },
        {
          header: 'Date',
          render: ({row, styles}) => {
            const date = new Date(row?.attendance_date);
            const dayOfWeek = daysOfWeek[date.getUTCDay()];
            return (
              <PrimaryColumnCard
                primaryTitle={moment(row.attendance_date).format('DD MMM YY')}
                items={[{value: dayOfWeek}]}
              />
            );
          },
          width: 150,
        },
        {
          header: 'First Half',
          field: 'first_attendance_type_id.name',
          type: 'text',
          render: ({row, ...rest}) => {
            if (row?.first_attendance_type_id?.name) {
              const {first_attendance_type_id} = row;
              if (first_attendance_type_id?.name == 'Present') {
                const {SUCCESS_LOW, SUCCESS_HIGH} = useTheme('colors');
                return (
                  <Chip
                    {...rest}
                    color={SUCCESS_LOW}
                    textColor={SUCCESS_HIGH}
                    value={row?.first_attendance_type_id?.name || '-'}
                  />
                );
              } else if (first_attendance_type_id?.name == 'Absent') {
                const {ERROR_LOW, ERROR_HIGH} = useTheme('colors');
                return (
                  <Chip
                    {...rest}
                    color={ERROR_LOW}
                    textColor={ERROR_HIGH}
                    value={row?.first_attendance_type_id?.name || '-'}
                  />
                );
              } else if (first_attendance_type_id?.name == 'Leave') {
                const {WARNING_LOW, WARNING_HIGH} = useTheme('colors');
                return (
                  <Chip
                    {...rest}
                    color={WARNING_LOW}
                    textColor={WARNING_HIGH}
                    value={row?.first_attendance_type_id?.name || '-'}
                  />
                );
              } else {
                const {NEUTRAL_LOW, NEUTRAL_HIGH} = useTheme('colors');
                return (
                  <Chip
                    // {...rest}
                    color={'Outline'}
                    textColor={'NEUTRAL_LOW'}
                    value={row?.first_attendance_type_id?.name || '-'}
                  />
                );
              }
            }
          },
          width: 150,
        },
        {
          header: 'Second Half',
          field: 'second_attendance_type_id.name',
          type: 'text',
          render: ({row, ...rest}) => {
            if (row?.second_attendance_type_id?.name) {
              const {second_attendance_type_id} = row;
              if (second_attendance_type_id?.name == 'Present') {
                const {SUCCESS_LOW, SUCCESS_HIGH} = useTheme('colors');
                return (
                  <Chip
                    {...rest}
                    color={SUCCESS_LOW}
                    textColor={SUCCESS_HIGH}
                    value={row?.second_attendance_type_id?.name || '-'}
                    // borderRadius={6}
                  />
                );
              } else if (second_attendance_type_id?.name == 'Absent') {
                const {ERROR_LOW, ERROR_HIGH} = useTheme('colors');
                return (
                  <Chip
                    {...rest}
                    color={ERROR_LOW}
                    textColor={ERROR_HIGH}
                    value={row?.second_attendance_type_id?.name || '-'}
                    // borderRadius={6}
                  />
                );
              } else if (second_attendance_type_id?.name == 'Leave') {
                const {WARNING_LOW, WARNING_HIGH} = useTheme('colors');
                return (
                  <Chip
                    {...rest}
                    color={WARNING_LOW}
                    textColor={WARNING_HIGH}
                    value={row?.second_attendance_type_id?.name || '-'}
                    // borderRadius={6}
                  />
                );
              } else {
                const {NEUTRAL_LOW, NEUTRAL_HIGH} = useTheme('colors');
                return (
                  <Chip
                    // {...rest}
                    color={'Outline'}
                    textColor={'NEUTRAL_LOW'}
                    value={row?.second_attendance_type_id?.name || '-'}
                  />
                );
              }
            }
          },
          width: 150,
        },
        {
          header: ' Time In Office',
          // field: 'time_in_office',
          type: 'text',
          render: ({row, styles}) => {
            let {time_in_office} = row;
            let hours = time_in_office && time_in_office / 60;
            hours = hours && Number(hours);
            hours = hours && parseInt(hours);
            let minutees = time_in_office && time_in_office % 60;
            minutees = minutees && Number(minutees);
            minutees = minutees && parseInt(minutees);
            let value;
            if (hours) {
              value = `${hours}h ${minutees || 0}m`;
            } else if (minutees) {
              value = `${minutees || 0}m`;
            }
            return <Text style={{...styles.rowText}}>{value}</Text>;
          },
          align: 'right',
          width: 120,
        },
        {
          header: 'Break Time',
          field: 'break_time',
          type: 'text',
          render: ({row, styles}) => {
            let {break_time} = row || {};
            let hours = break_time && break_time / 60;
            hours = hours && Number(hours);
            hours = hours && parseInt(hours);
            let minutees = break_time && break_time % 60;
            minutees = minutees && Number(minutees);
            minutees = minutees && parseInt(minutees);
            let value;
            if (hours) {
              value = `${hours}h ${minutees || 0}m`;
            } else if (minutees) {
              value = `${minutees || 0}m`;
            }
            return <Text style={{...styles.rowText}}>{value}</Text>;
          },
          align: 'right',
          width: 150,
        },
        {
          header: 'Time',
          render: ({row}) => {
            const start = moment(row?.in_time);
            const end = moment(row?.out_time);
            return <TimeDuration start={start} end={end} />;
          },
          align: 'center',
          width: 250,
        },
        {
          header: 'Remark',
          width: 120,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.remark}
                items={[
                  {
                    value: row?.purpose,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Status',
          type: 'text',
          field: 'status',
          render: ({row, ...rest}) => {
            const {status, is_approved} = row;
            if (is_approved) {
              const {SUCCESS_LOW, SUCCESS_HIGH} = useTheme('colors');
              return (
                <Chip
                  {...rest}
                  color={SUCCESS_LOW}
                  textColor={SUCCESS_HIGH}
                  value={'Approved'}
                />
              );
            } else if (is_approved === false) {
              const {ERROR_LOW, ERROR_HIGH} = useTheme('colors');
              return (
                <Chip
                  {...rest}
                  color={ERROR_LOW}
                  textColor={ERROR_HIGH}
                  value={'Rejected'}
                />
              );
            } else {
              const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
              return (
                <Chip
                  {...rest}
                  color={INFORMATION_LOW}
                  textColor={INFORMATION_HIGH}
                  value={'Unapproved'}
                />
              );
            }
          },
          width: 150,
        },
        {
          header: 'Timesheet',
          field: 'progress_hours',
          type: 'text',
          render: ({row, styles}) => {
            let {progress_hours} = row || {};
            return (
              <Text style={{...styles.rowText}}>{`${progress_hours}h`}</Text>
            );
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Update Attendance',
          visible: () => (fromReport || fromDepartmentReport ? false : true),
          onPress: ({row}) => {
            navigation.navigate('update-attendance-HR', {
              ...params,
              row,
            });
          },
          // confirm: {
          //   title: 'Mark InActive',
          //   message: 'Are you sure you want to Mark InActive?',
          // },
          // onPress: markEmployeeSizeInactive,
        },
        {
          title: 'Punch Data',
          onPress: ({row}) => {
            navigation.navigate('punch-data-editable-screen', {row});
          },
          visible: () => user?.email === 'amit.singh@daffodildb.com',
        },
        {
          title: 'Punch Data',
          onPress: ({row}) => {
            navigation.navigate('punch-data-screen', {row});
          },
          visible: () => user?.email !== 'amit.singh@daffodildb.com',
        },
        {
          title: 'Activity History',
          visible: !fromReport,
          onPress: ({row}) => {
            navigation.navigate(`activity-logs`, {
              _id: row._id,
              populate: [
                {path: 'employee', select: {name: 1}},
                {
                  path: 'first_attendance_type_id',
                  select: {name: 1},
                },
                {
                  path: 'second_attendance_type_id',
                  select: {name: 1},
                },
                {
                  path: 'project_id',
                  select: {name: 1},
                },
              ],
              api: `/dailyattendances/${row?._id}/activityLogs`,
              displayFields: {
                Employee: {
                  label: 'Employee',
                  field: 'employee.name',
                },
                Date: {
                  label: 'Attendance Date',
                  field: 'attendance_date',
                },
                First_half: {
                  label: 'First Half',
                  field: 'first_attendance_type_id.name',
                },
                second_half: {
                  label: 'Second Half',
                  field: 'second_attendance_type_id.name',
                },
                time_in_office: {
                  label: 'Time in Office',
                  field: 'time_in_office',
                },
                break_time: {
                  label: 'Break Time',
                  field: 'break_time',
                },
                Status: {
                  label: 'Status',
                  field: 'status',
                },
                project_id: {
                  label: 'Project',
                  field: 'project_id.name',
                },
              },
            });
          },
        },
      ]}
    />
  );
};
export const AllLOPTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
    periods,
  } = props;

  const {period, ...rest} = params;

  params = {
    periods,
    ...rest,
  };
  params = {...params, ...filterParams};
  const fetchProps = {
    params,
    api: `/monthlyattendance/lop/all`,
    fromDownload: true,
  };
  const {user, download} = useAppStateContext();
  return (
    <Table
      api={'/monthlyattendance/lop/all'}
      params={params}
      eventSourceId={'alllop'}
      filter={filter}
      variant={'bordered'}
      search={searchValue}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`daily-attendance-tab`, {
          row: {
            month_id: row.month_id,
            year_id: row.year_id,
            employee: row.employee,
          },
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'Official Email Id': 'employee.official_email_id',
                      Month: 'month_id.name',
                      Year: 'year_id.name',
                      'Present Days': 'details.present_days',
                      Off: 'details.compensatory_off',
                      EWD: 'details.ewd_payable',
                      Leaves: 'details.leave_days',
                      Holidays: 'details.holidays',
                      'Total Salary Days': 'details.total_salary_days',
                      'Absent Days': 'details.absent_days',
                      LOP: 'lop_days',
                      Total: 'total',
                    },
                    filename: 'Monthly Attendance',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          minWidth: 200,
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Month',
          type: 'text',
          field: 'month_id.name',
          width: 120,
        },
        {
          header: 'Year',
          field: 'year_id.name',
          type: 'text',
          align: 'right',
          width: 80,
        },
        {
          header: 'Total',
          field: 'total_days_id_month',
          type: 'text',
          align: 'center',
          width: 80,
        },
        {
          header: 'Payable',
          children: [
            {
              header: 'Present',
              field: 'details.present_days',
              type: 'text',
              align: 'center',
              width: 80,
            },
            {
              header: 'Off',
              field: 'details.compensatory_off',
              type: 'text',
              align: 'center',
              width: 80,
            },
            {
              header: 'EWD',
              field: 'details.ewd_payable',
              type: 'text',
              align: 'center',
              width: 80,
            },
            {
              header: 'Paid Leaves',
              field: 'details.paid_leaves',
              type: 'text',
              align: 'center',
              width: 100,
            },
            {
              header: 'Holiday',
              field: 'details.holidays',
              type: 'text',
              align: 'center',
              width: 80,
            },
            {
              header: 'Total',
              field: 'details.total_salary_days',
              type: 'text',
              align: 'center',
              width: 80,
            },
          ],
        },
        {
          header: 'LOP',
          children: [
            {
              header: 'Absent',
              field: 'details.absent_days',
              type: 'text',
              align: 'center',
              onPress: ({row}) => {
                navigation.navigate(`daily-attendance-tab`, {
                  row: {
                    month_id: row.month_id,
                    year_id: row.year_id,
                    first_attendance_type_id: '5382e9c424b06a02005c09f1',
                    second_attendance_type_id: '5382e9c424b06a02005c09f1',
                    employee: row.employee,
                  },
                });
              },
              width: 80,
            },
            {
              header: 'LOP',
              type: 'text',
              align: 'center',
              width: 80,
              value: ({row}) => {
                if (row && row?.details) {
                  return (
                    row?.details?.absent_days + row?.details?.un_paid_leaves
                  );
                }
              },
            },
            {
              header: 'Total',
              type: 'text',
              align: 'center',
              value: ({row}) => {
                if (row && row?.details) {
                  return (
                    row?.details?.present_days +
                    row?.details?.compensatory_off +
                    row?.details?.ewd_payable +
                    row?.details?.paid_leaves +
                    row?.details?.holidays -
                    row?.details?.absent_days -
                    row?.details?.un_paid_leaves
                  );
                }
              },
              width: 80,
            },
          ],
        },
      ]}
    />
  );
};

export const AllLeaveRegisterTable = props => {
  let {
    navigation,
    route: {params},
    filter,
    searchValue,
    filterParams,
  } = props;

  const {period, ...rest} = params;
  params = {
    ...rest,
  };

  params = {...params, ...filterParams};
  const {user, download} = useAppStateContext();
  const fetchProps = {
    params,
    api: `/monthlyattendance/leaveregister/all`,
    addOnFilter: {...filter},
    fromDownload: true,
  };
  return (
    <Table
      api={'/monthlyattendance/leaveregister/all'}
      params={params}
      eventSourceId={'allleaveregister'}
      addOnFilter={filter}
      search={searchValue}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee.employee_code',
                      Name: 'employee.name',
                      'Official Email Id': 'employee.official_email_id',
                      Month: 'month_id.name',
                      Year: 'year_id.name',
                      Available: 'leave_details.opening_leave_balance',
                      Accured: 'leave_details.accrued_leaves',
                      Consumed: 'leave_details.consumed_leaves',
                      Balance: 'leave_details.leave_balance',
                    },
                    filename: 'Daily Attendance',
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Month',
          field: 'month_id.name',
          type: 'text',
          width: 150,
        },
        {
          header: 'Year',
          field: 'year_id.name',
          type: 'text',
          width: 120,
        },
        {
          header: 'Available',
          render: ({row, styles}) => {
            if (
              row &&
              row.leave_details &&
              row.leave_details.opening_leave_balance
            ) {
              return (
                <Text style={{...styles.rowText}}>
                  {row.leave_details.opening_leave_balance.toFixed(1)}
                </Text>
              );
            } else {
              return <Text style={{...styles.rowText}}>0</Text>;
            }
          },
          width: 120,
          type: 'number',
        },
        {
          header: 'Accured',
          render: ({row, styles}) => {
            if (row && row.leave_details && row.leave_details.accrued_leaves) {
              return (
                <Text style={{...styles.rowText}}>
                  {row.leave_details.accrued_leaves.toFixed(1)}
                </Text>
              );
            } else {
              return <Text style={{...styles.rowText}}>0</Text>;
            }
          },
          width: 120,
          type: 'number',
        },
        {
          header: 'Consumed',
          render: ({row, styles}) => {
            if (row && row.leave_details && row.leave_details.consumed_leaves) {
              return (
                <Text style={{...styles.rowText}}>
                  {row.leave_details.consumed_leaves.toFixed(1)}
                </Text>
              );
            } else {
              return <Text style={{...styles.rowText}}>0</Text>;
            }
          },
          width: 120,
          type: 'number',
        },
        {
          header: 'Balance',
          render: ({row, styles}) => {
            if (row && row.leave_details && row.leave_details.leave_balance) {
              return (
                <Text style={{...styles.rowText}}>
                  {row.leave_details.leave_balance.toFixed(1)}
                </Text>
              );
            } else {
              return <Text style={{...styles.rowText}}>0</Text>;
            }
          },
          width: 120,
          type: 'number',
        },
      ]}
    />
  );
};
