import React from 'react';
import {useTheme} from '@unthinkable/react-theme';

import {GroupTable} from '../../../components/table/Table';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {FeatureTableHeader} from './FeaturesTable';

const storyPointColumns = () => {
  const {colors} = useTheme();

  const userstory = {
    field: 'userstory',
    header: 'Userstory',
  };

  const complexity = {
    field: 'complexity_id.complexity_type',
    header: 'Complexity',
    width: 100,
  };

  const entity_count = {
    header: 'Entities',
    value: ({row}) => {
      return row?.entity_count.count + ' | ' + row?.entity_count.complexity;
    },
    type: 'text',
    color: colors.INFORMATION_HIGH,
    width: 70,
    align: 'right',
  };

  const view_count = {
    header: 'Views',
    value: ({row}) => {
      return row?.view_count.count + ' | ' + row?.view_count.complexity;
    },
    type: 'text',
    color: colors.INFORMATION_HIGH,
    width: 60,
    align: 'right',
  };

  const integration_count = {
    header: 'Integration',
    value: ({row}) => {
      return (
        row?.integration_count.count + ' | ' + row?.integration_count.complexity
      );
    },
    type: 'text',
    color: colors.INFORMATION_HIGH,
    width: 90,
    align: 'right',
  };

  const save_count = {
    header: 'Save',
    value: ({row}) => {
      return row?.save_api_count.count + ' | ' + row?.save_api_count.complexity;
    },
    type: 'text',
    color: colors.INFORMATION_HIGH,
    width: 60,
    align: 'right',
  };

  const read_count = {
    header: 'Read',
    value: ({row}) => {
      return row?.get_api_count.count + ' | ' + row?.get_api_count.complexity;
    },
    type: 'text',
    color: colors.INFORMATION_HIGH,
    width: 60,
    align: 'right',
  };

  const business_logic_count = {
    header: 'Business Logic',
    value: ({row}) => {
      return row?.service_count.count + ' | ' + row?.service_count.complexity;
    },
    type: 'text',
    color: colors.INFORMATION_HIGH,
    width: 120,
    align: 'right',
  };

  const list_count = {
    header: 'List',
    value: ({row}) => {
      return (
        row?.table_view_count.count + ' | ' + row?.table_view_count.complexity
      );
    },
    type: 'text',
    color: colors.INFORMATION_HIGH,
    width: 70,
    align: 'right',
  };

  const detail_count = {
    header: 'Detail',
    value: ({row}) => {
      return (
        row?.form_view_count.count + ' | ' + row?.form_view_count.complexity
      );
    },
    type: 'text',
    color: colors.INFORMATION_HIGH,
    width: 70,
    align: 'right',
  };

  const total_count = {
    header: 'Total',
    field: 'total_count',
    type: 'number',
    color: colors.INFORMATION_HIGH,
    width: 70,
    align: 'right',
  };

  return {
    userstory,
    complexity,
    entity_count,
    view_count,
    integration_count,
    save_count,
    read_count,
    business_logic_count,
    list_count,
    detail_count,
    total_count,
  };
};

export const ProjectStoryPointTable = props => {
  let {
    navigation,
    route: {params},
    extraParams = {},
    searchValue: searchFilter,
    addOnFilter,
  } = props;

  const {project} = params;

  const tableProps = {
    api: `/projects/${project?._id}/features/userstories`,
    search: searchFilter,
    searchFields: ['feature', 'code'],
    tableParams: {...extraParams},
    showReportColumns: true,
  };

  const column = storyPointColumns();

  return (
    <GroupTable
      {...tableProps}
      // renderHeader={_props => {
      //   return (
      //     <FeatureTableHeader {...props} {..._props} tableProps={tableProps} />
      //   );
      // }}
      params={{...extraParams}}
      columns={[
        column.userstory,
        column.complexity,
        column.entity_count,
        column.view_count,
        column.save_count,
        column.read_count,
        column.business_logic_count,
        column.list_count,
        column.detail_count,
        // navigation,
        column.integration_count,
        column.total_count,
      ]}
      limit={-1}
      skip={-1}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          const {feature = ''} = row || {};
          return <GroupContentItem value={feature} />;
        },
      }}
    />
  );
};
