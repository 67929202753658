import { getZeroTimeDate } from '@unthinkable/react-filters/src/DateUtility';
import React from 'react';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';
import { useAppStateContext } from '../../../providers/AppState';

const Mrf = props => {
  const {
    route: {params},
  } = props;

  const {mrf} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/manpowerrequisitions',
    eventSourceId: ['ManPowerRequisitionPending'],
  });

  const {user = {}} = useAppStateContext();
  const {employee} = user;

  const defaultValues = {
    requested_by: employee,
    status: 'Pending',
    requested_on: getZeroTimeDate(new Date()),
  };

  return (
    <Form
      api={`/manpowerrequisition/${mrf}`}
      fields={{
        position_title: {
          name: 1,
        },
        department: {
          name: 1,
        },
        must_have_skills: {
          name: 1,
          color: 1,
        },
        good_to_have_skills: {
          name: 1,
          color: 1,
        },
        experience_required: {
          experience: 1,
        },
        no_of_positions: 1,
        educational_requirement: 1,
        requested_by: {
          name: 1,
        },
        nature_of_request: 1,
        priority: 1,
        description: 1,
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      defaultValues={defaultValues}
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'position_title',
          label: 'Designation',
          placeholder: 'Designation',
          size: 6,
          api: '/designations',
          suggestionField: 'name',
          required: true,
          valueField:'name',

        },
        {
          type: 'autoComplete',
          field: 'department',
          label: 'Department',
          api: '/activeDepartmentSuggestions',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
        },
        {
          type: 'multiAutoComplete',
          field: 'must_have_skills',
          label: 'Must Have Skills',
          api: '/skillmetrics',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
          required: true,
          fields: {
            name: 1,
            color: 1,
          },
        },
        {
          type: 'multiAutoComplete',
          field: 'good_to_have_skills',
          label: 'Good to Have Skills',
          api: '/skillmetrics',
          valueField: 'name',
          suggestionField: 'name',
          size: 6,
          fields: {
            name: 1,
            color: 1,
          },
        },
        {
          placeholder: 'Experience Range',
          field: 'experience_required',
          label: 'Experience Range',
          type: 'autoComplete',
          api: '/employeeexperiences',
          suggestionField: 'experience',
          valueField: 'experience',
          size: 6,
          required: true,
        },
        {
          label: 'Minimum Eductional Requirement',
          type: 'text',
          field: 'educational_requirement',
          size: 6,
          required: true,
        },
        {
          field: 'no_of_positions',
          type: 'number',
          label: 'Position Count',
          placeholder: 'Position Count',
          size: 6,
        },
        {
          field: 'requested_by',
          type: 'autoComplete',
          label: 'Requested By',
          api: '/employeeSuggestions',
          suggestionField: 'name',
          secondaySuggestionField: 'official_email_id',
          size: 6,
          valueField:'name',
        },
        {
          type: 'autoComplete',
          field: 'nature_of_request',
          label: 'Nature Of Request',
          options: ['Addition', 'Replacement'],
          size: 6,
          required: true,
        },
        {
          type: 'autoComplete',
          field: 'priority',
          label: 'Priority',
          options: ['Urgent', 'High', 'Medium', 'Low'],
          size: 6,
          required: true,
        },
        {
          type: 'textArea',
          field: 'description',
          label: 'Description',
        },
      ]}
      {...props}
    />
  );
};

export const AddMrf = props => {
  return <Mrf header="Request talent requisition" {...props} />;
};

export const ApproveWithSalaryBudgetManPowerRequisition = props => {
  const {
    route: {params},
  } = props;
  const {mrf} = params;
  const {user = {}} = useAppStateContext();
  const {employee} = user;

  const {onSubmit} = useFormSubmit({
    uri: '/manpowerrequisitions',
    eventSourceId: [
      'MrfApproverPending',
      'MrfApproverApproved',
      'MrfApproverRejected',
    ],
  });

  return (
    <Form
      header="Approve Request"
      mode="edit"
      api={`/manpowerrequisition/${mrf}`}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Approved',
            approve_by: employee?._id,
            approved_on: getZeroTimeDate(new Date()),
          },
        };
      }}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          type: 'number',
          field: 'salary_offered',
          label: 'Salary Budget',
          required: true,
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const NonEditableMrf = props => {
  return (
    <Mrf
      readOnly={true}
      mode="edit"
      header="Request talent requisition"
      {...props}
    />
  );
};
export const EditableMrf = props => {
  return <Mrf mode="edit" header="Request talent requisition" {...props} />;
};
