import {
  Image,
  Text,
  TouchableOpacity,
  View,
  Linking,
  Row,
} from '@unthinkable/react-core-components';
import {CompositeFieldsRender} from '@unthinkable/react-table';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import React from 'react';
import {AddButton} from '../../../components/button/AddButton';
import {TableHeader} from '../../../components/table/Headers';
import {GroupTable, Table} from '../../../components/table/Table';
import {leadControllers} from '../controllers/leadControllers';
import {Tag} from '../../../components/chip/Chip';
import {useAppStateContext} from '../../../providers/AppState';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {Avatar} from '../../../components/avatar/Avatar';
import {useAccentColor} from '../../../controllers/useAccentColor';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {useInvoke} from '../../../controllers/useInvoke';

export const CommunicationRender = ({row, ...rest}) => {
  const {INFORMATION_LOW, INFORMATION_HIGH} = useTheme('colors');
  const {CommunicationBlue} = useTheme('icons');

  return (
    <>
      <View
        style={{
          borderRadius: 6,
          height: 10,
          width: 10,
          backgroundColor: INFORMATION_LOW,
        }}>
        <Image
          source={CommunicationBlue}
          style={{paddingLeft: 4, lineHeight: '18px', height: 15, width: 15}}
        />
      </View>
    </>

    // <Chip
    //   {...rest}
    //   color={INFORMATION_LOW}
    //   textColor={INFORMATION_HIGH}
    //   // value={
    //   //   (row['last_communication_date'] &&
    //   //     moment(row['last_communication_date']).format('DD MMM, YYYY')) ||
    //   //   '-'
    //   // }
    //   borderRadius={6}
    // />
  );
};

const LAST_COMMUNICATION = communications => ({
  // header: 'Communication',
  render: CommunicationRender,
  onPress: communications,
  // width: 20
});

const ModeRender = props => {
  return (
    <CompositeFieldsRender
      {...props}
      fields={[
        {field: 'mode', type: 'text'},
        {
          field: 'opportunity_id.work_location',
          type: 'text',
          variant: 'subtle',
        },
      ]}
    />
  );
};

const RenderEmailWithExp = props => {
  const {row} = props;
  const {resource} = row;
  const {HIGH, LOW} = useAccentColor(resource?.color);

  return (
    <Row gap={7} style={{alignItems: 'center', overflow: 'hidden'}}>
      <Avatar value={resource?.name} color={HIGH} brorderColor={LOW} />
      <PrimaryColumnCard
        primaryTitle={resource?.name}
        items={[
          {
            value: resource?.employee_code,
          },
          {
            value: `${resource?.experience} yrs`,
          },
        ]}
      />
    </Row>
  );
};

const CustomerRender = props => {
  const {row} = props;
  const {CustomerOpportunity, ProspectIcon, CustomerNew} = useTheme('icons');

  if (row?.opportunity_id?.potential_customer) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.opportunity_id?.potential_customer?.name}
        icon={ProspectIcon}
        variant="secondary"
        items={[
          {
            value:
              row?.opportunity_id?.potential_customer?.employee_count?.number,
            backgroundColor:
              row?.opportunity_id?.potential_customer?.employee_count?.color,
            numberOfLines: 1,
          },
          {
            value: row?.opportunity_id?.potential_customer?.country?.name,
            backgroundColor:
              row?.opportunity_id?.potential_customer?.country?.color,
            numberOfLines: 1,
          },
          {
            value: row?.opportunity_id?.industry?.name,
            backgroundColor: row?.opportunity_id?.industry?.name?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  } else if (row?.opportunity_id?.customer_id) {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.opportunity_id?.customer_id?.name}
        icon={CustomerNew}
        variant="secondary"
        items={[
          {
            value: row?.opportunity_id?.customer_id?.employee_count?.number,
            backgroundColor:
              row?.opportunity_id?.customer_id?.employee_count?.color,
            numberOfLines: 1,
            // onPress: () => {
            //   console.log(':::::::::::checking');
            // },
          },
          {
            value: row?.opportunity_id?.industry?.name,
            backgroundColor: row?.opportunity_id?.industry?.name?.color,
            numberOfLines: 1,
          },
          {
            value: row?.opportunity_id?.customer_id?.country?.name,
            backgroundColor: row?.opportunity_id?.customer_id?.country?.color,
            numberOfLines: 1,
          },
        ]}
      />
    );
  }
};

const RenderStatus = props => {
  const {
    row,
    styles: {rowText},
    navigation,
  } = props;
  console.log(rowText);
  const colors = useTheme('colors');
  if (
    row?.outcome &&
    (row?.outcome == 'Rejected' ||
      row?.outcome == 'Drop' ||
      row?.outcome == 'Denied by Resource' ||
      row?.outcome == 'Lost')
  ) {
    console.log('here');
    return (
      <>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('candidate-lost-description-pop-up', {
              row,
            });
          }}>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              color: 'Red',
              overflow: 'hidden',
            }}>
            {row?.outcome}
          </Text>
          <Text
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              overflow: 'hidden',
            }}>
            {moment(row?.outcome_date).format('DD MMM, YY')}
          </Text>
          <Text
            numberOfLines={1}
            style={{
              ...rowText,
              fontSize: 12,
              color: colors.NEUTRAL_MEDIUM,
              overflow: 'hidden',
            }}>
            {row?.outcome_description}
          </Text>
        </TouchableOpacity>
      </>
    );
  } else if (row?.outcome && row?.outcome == 'Selected') {
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          color: '#39e75f',
          overflow: 'hidden',
        }}>
        {row?.outcome}
      </Text>
    );
  } else if (row?.outcome && row?.outcome == 'Shift To Another Project') {
    return (
      <Text
        numberOfLines={1}
        style={{
          ...rowText,
          fontSize: 13,
          color: '#30839f',
          overflow: 'hidden',
        }}>
        {row?.outcome}
      </Text>
    );
  } else if (row?.interview_date && row?.interview_time && !row.outcome) {
    return (
      <>
        <Text
          numberOfLines={1}
          style={{
            ...rowText,
            color: colors.NEUTRAL_MEDIUM,
            overflow: 'hidden',
          }}>
          Interview Scheduled
        </Text>
        <Text style={{...rowText, fontSize: 13, color: colors.NEUTRAL_LOW}}>
          {moment(row?.interview_date).format('DD MMM')}
        </Text>
      </>
    );
  } else {
    return null;
  }
};

const RESOURCE_NAME = {
  field: 'resource',
  header: 'Candidate',
  type: 'userAvatarChip',

  width: 200,
};
const RESUME = resourceUrl => ({
  header: 'Resume',
  align: 'center',
  width: 100,
  // type: 'file',
  // field: 'profile',
  // fileText: 'view',
  render: ({row}) => {
    const icons = useTheme('icons');
    if (row?.profile && row?.profile != null) {
      return (
        <TouchableOpacity
          onPress={() => {
            const url = resourceUrl(row?.profile);
            Linking.openURL(url, '_blank');
          }}>
          <Image source={icons.Reimbursement} style={{height: 16, width: 14}} />
        </TouchableOpacity>
      );
    }
  },
  width: 100,
});

const EXPERIENCE = {
  field: 'resource.experience',
  header: 'Resource Experience',
};

const CUSTOMER = {
  header: 'Organisation',
  render: CustomerRender,
  width: 300,
};

const STATUS = {
  header: 'Status',
  render: RenderStatus,
  width: 200,
};

const ASSIGNED_DATE = {
  type: 'date',
  field: 'rfr_assigned_date',
  header: 'Assigned On',
  formatOptions: {
    format: 'DD MMM,YY',
  },
  width: 120,
};

const REQUIRMENT = {
  field: 'opportunity_id.requirement',
  header: 'Requirement',
  type: 'chip',
};

const MENTOR = {
  field: 'mentor.name',
  colorField: 'mentor.color',
  header: 'Trainer',
  type: 'colorTag',
};

const MODE = {
  field: 'mode',
  header: 'Mode',
};

const MODEResources = {
  header: 'Mode',
  width: 120,
  render: ModeRender,
};

const INTERVIEW_DATE = {
  type: 'date',
  field: 'interview_date',
  header: 'Interview Date',
  width: 180,
  formatOptions: {
    format: 'DD MMM, YY',
  },
};

const OUTCOME = {
  field: 'outcome',
  header: 'Outcome',
};

const OUTCOME_DESCRIPTION = {
  field: 'outcome_description',
  header: 'Outcome Description',
};

const REJECTION_REASON = {
  field: 'outcome_rejection_reason',
  header: 'Rejection Reason',
};

const OUTCOME_DATE = {
  field: 'outcome_date',
  header: 'Outcome Date',
  type: 'date',
  formatOptions: {
    format: 'DD MMM, YY',
  },
  width: 130,
};

// const RenderSkills = props => {
//   const {
//     row,
//     styles: {rowText},
//   } = props;
//   console.log("row>>>>",row)
//   console.log(rowText);
//   const colors = useTheme('colors');
//   const skillName = row?.opportunity_id?.skill
//     ?.map(element => {
//       return element?.name;
//     })
//     .join(', ');
//   if (!skillName) return null;
//   return (
//     <Text
//       // numberOfLines={1}
//       style={{
//         ...rowText,
//         backgroundColor: colors['ACCENT1_MEDIUM'],
//         paddingRight: 8,
//         paddingLeft: 8,
//         paddingTop: 4,
//         paddingBottom: 4,
//         borderRadius: 5,
//         overflow: 'hidden',
//       }}>
//       {skillName}
//     </Text>
//   );
// };

const RenderSkills = props => {
  const {row} = props;
  const skillName = row?.opportunity_id?.skill
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return <Tag value={skillName} />;
};

export const InterviewResourcesTable = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {product, row, addOnFilter: _addOnFilter, hideAddResource} = params;

  let addOnFilter = {
    opportunity_id: row?._id,
    invalid_interview: {$ne: true},
  };
  if (_addOnFilter) {
    addOnFilter = {...addOnFilter, ..._addOnFilter};
  }
  const {
    scheduleResourceInterview,
    outcomeDateCorrection,
    updateResume,
    markInterviewResourceOutcome,
    // interviewCommunication,
  } = leadControllers(props);
  const {resourceUrl, user} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['StaffAugmentation'],
  });
  return (
    <Table
      api={`/interviewresources/all`}
      eventSourceId={'StaffAugmentation'}
      addOnFilter={{
        ...addOnFilter,
      }}
      renderHeader={() => (
        <TableHeader
          title={'Resources'}
          actions={
            !hideAddResource && (
              <AddButton
                title="Add Resource"
                view="assign-interview-resource"
                opportunity={row}
                params={props?.route?.params}
              />
            )
          }
        />
      )}
      moreActions={
        !row?.formEmployeeMenu && [
          // {
          //   title: 'Mark Outcome',
          //   onPress: markInterviewResourceOutcome,
          // },
          // {title: 'Schedule', onPress: scheduleResourceInterview},
          {
            title: 'Correct Interview Schedule',
            onPress: scheduleResourceInterview,
          },
          {
            title: 'Update Resume',
            onPress: updateResume,
          },
          {
            title: 'Mark invalid',
            confirm: {
              title: 'Mark invalid',
              message:
                'Are you sure you want to mark this resource as invalid?',
            },
            onPress: props => {
              const {row} = props;
              invoke({
                uri: `/staffaugmentationopportunities/${row?._id}`,
                message: 'Marked invalid successfully',
                props: {
                  invalid_interview: true,
                },
              });
            },
          },
          {title: 'Correct Outcome Date', onPress: outcomeDateCorrection},
          {
            title: 'Update Mentor',
            visible: () => {
              if (
                user?.email === 'amit.singh@daffodildb.com' ||
                user?.email === 'sushil.nagvan@daffodilsw.com'
              ) {
                return true;
              } else {
                return false;
              }
            },
            onPress: ({row}) => {
              navigation.navigate('update-resource-mentor', {row: row});
            },
          },
        ]
      }
      columns={[
        RESOURCE_NAME,
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'text',
          width: 80,
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 150,
        },
        RESUME(resourceUrl),
        {
          header: 'Mentor',
          field: 'mentor.name',
          type: 'text',
          width: 150,
        },
        {
          type: 'date',
          header: 'Assigned',
          field: 'rfr_assigned_date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 180,
        },
        {
          header: 'Interview',
          field: 'interview_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
        },
        // !row?.formEmployeeMenu && MODEResources,
        // CUSTOMER,
        // LAST_COMMUNICATION(interviewCommunication),
        STATUS,
        {
          header: 'Reason',
          field: 'outcome_rejection_reason',
          type: 'textArea',
          width: 350,
        },
        // {
        //   type: 'date',
        //   header: 'Outcome date',
        //   field: 'outcome_date',
        //   formatOptions: {
        //     format: 'DD MMM, YY',
        //   },
        //   width: 180,
        // },
        // {
        //   type: 'userAvatarChip',
        //   header: 'Assigned against',
        //   field: 'assigned_against',
        //   // formatOptions: {
        //   //   format: 'DD MMM, YY',
        //   // },
        // },
      ]}
    />
  );
};

export const StaffAugmentationInterviewResourcesTable = props => {
  let {
    route: {params},
    navigation,
    filterParams,
    addOnFilter,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
    ...filterParams,
  };
  const {
    scheduleResourceInterview,
    outcomeDateCorrection,
    markInterviewResourceOutcome,
  } = leadControllers(props);

  return (
    <GroupTable
      {...props}
      params={params}
      limit={5000}
      api="/allstaffaugmentationopportunities"
      // addOnFilter={{
      //   interview_date: {
      //     $gte: period.from,
      //     $lte: period.to,
      //   },
      //   product: product?._id,
      // }}
      sort={{rfr_assigned_date: -1}}
      // renderHeader={() => <TableHeader title={'Resources'} />}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} assigned`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        ASSIGNED_DATE,
        {
          header: 'Candidate',
          render: RenderEmailWithExp,
          width: 200,
        },
        CUSTOMER,
        {
          header: 'Skill & Exp',
          render: ({row}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            const skillName = row?.skill
              ?.map(element => {
                return element?.name;
              })
              .join(', ');
            if (!skillName) return null;
            return <Tag value={skillName} />;
          },
        },
        {
          header: 'Mode',
          width: 130,
          render: ModeRender,
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 180,
        },
        // {
        //   header: 'Status',
        //   width: 180,
        //   render: ({row, styles: {rowText}}) => {
        //     const colors = useTheme('colors');
        //     if (
        //       row?.outcome &&
        //       (row?.outcome == 'Rejected' ||
        //         row?.outcome == 'Drop' ||
        //         row?.outcome == 'Denied by Resource' ||
        //         row?.outcome == 'Lost')
        //     ) {
        //       return (
        //         <>
        //           <TouchableOpacity
        //             onPress={() => {
        //               navigation.navigate('candidate-lost-description-pop-up', {
        //                 row,
        //               });
        //             }}>
        //             <Text
        //               numberOfLines={1}
        //               style={{
        //                 ...rowText,
        //                 color: 'Red',
        //                 overflow: 'hidden',
        //               }}>
        //               {row?.outcome}
        //             </Text>
        //             <Text
        //               numberOfLines={1}
        //               style={{
        //                 ...rowText,
        //                 fontSize: 12,
        //                 color: colors.NEUTRAL_MEDIUM,
        //                 overflow: 'hidden',
        //               }}>
        //               {row?.outcome_description}
        //             </Text>
        //           </TouchableOpacity>
        //         </>
        //       );
        //     } else if (row?.outcome && row?.outcome == 'Selected') {
        //       return (
        //         <Text
        //           numberOfLines={1}
        //           style={{
        //             ...rowText,
        //             color: '#39e75f',
        //             overflow: 'hidden',
        //           }}>
        //           {row?.outcome}
        //         </Text>
        //       );
        //     } else if (
        //       row?.outcome &&
        //       row?.outcome == 'Shift To Another Project'
        //     ) {
        //       return (
        //         <Text
        //           numberOfLines={1}
        //           style={{
        //             ...rowText,
        //             fontSize: 13,
        //             color: '#30839f',
        //             overflow: 'hidden',
        //           }}>
        //           {row?.outcome}
        //         </Text>
        //       );
        //     } else if (!row.outcome) {
        //       if (row?.status == 'Active' && !row?.interview_date) {
        //         return (
        //           <>
        //             <Text
        //               numberOfLines={1}
        //               style={{
        //                 ...rowText,
        //                 color: colors.NEUTRAL_MEDIUM,
        //                 overflow: 'hidden',
        //               }}>
        //               Resume shared
        //             </Text>
        //           </>
        //         );
        //       }
        //       if (
        //         row?.status == 'Active' &&
        //         getZeroTimeDate(row?.interview_date) >
        //           getZeroTimeDate(new Date())
        //       ) {
        //         return (
        //           <>
        //             <Text
        //               numberOfLines={1}
        //               style={{
        //                 ...rowText,
        //                 color: colors.NEUTRAL_MEDIUM,
        //                 overflow: 'hidden',
        //               }}>
        //               Interview scheduled
        //             </Text>
        //             <Text
        //               style={{
        //                 ...rowText,
        //                 fontSize: 13,
        //                 color: colors.NEUTRAL_LOW,
        //               }}>
        //               {moment(row?.interview_date).format('DD MMM')}
        //             </Text>
        //           </>
        //         );
        //       }
        //       if (
        //         row?.status == 'Active' &&
        //         getZeroTimeDate(row?.interview_date) <
        //           getZeroTimeDate(new Date())
        //       ) {
        //         return (
        //           <>
        //             <Text
        //               numberOfLines={1}
        //               style={{
        //                 ...rowText,
        //                 color: colors.NEUTRAL_MEDIUM,
        //                 overflow: 'hidden',
        //               }}>
        //               Results due
        //             </Text>
        //           </>
        //         );
        //       }
        //     } else {
        //       return null;
        //     }
        //   },
        // },
        INTERVIEW_DATE,
        // STATUS,

        // OUTCOME_DATE,
        // EXPERIENCE,
        // REQUIRMENT,
        // MENTOR,
        // MODE,
        // OUTCOME_DESCRIPTION,
        // REJECTION_REASON,
      ]}
      moreActions={() => [
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [{path: 'mentor', select: {name: 1}}],
              api: `/staffaugmentationopportunities/${row?._id}/activityLogs`,
              displayFields: {
                mentor: {
                  label: 'Mentor',
                  field: 'mentor.name',
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const StaffAugmentationInterviewResourcesClosedTable = props => {
  let {
    route: {params},
    navigation,
    filterParams,
    addOnFilter,
  } = props;
  console.log('addOnFilter::::', addOnFilter);
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
    ...filterParams,
  };
  const {
    scheduleResourceInterview,
    outcomeDateCorrection,
    markInterviewResourceOutcome,
  } = leadControllers(props);

  return (
    <GroupTable
      {...props}
      params={params}
      limit={5000}
      api="/allstaffaugmentationopportunities"
      // addOnFilter={{
      //   interview_date: {
      //     $gte: period.from,
      //     $lte: period.to,
      //   },
      //   product: product?._id,
      // }}
      sort={{close_date: -1}}
      // renderHeader={() => <TableHeader title={'Resources'} />}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} assigned`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        ASSIGNED_DATE,
        {
          header: 'Candidate',
          render: RenderEmailWithExp,
          width: 200,
        },
        CUSTOMER,
        {
          header: 'Skill & Exp',
          render: ({row}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            const skillName = row?.skill
              ?.map(element => {
                return element?.name;
              })
              .join(', ');
            if (!skillName) return null;
            return <Tag value={skillName} />;
          },
        },
        {
          header: 'Mode',
          width: 130,
          render: ModeRender,
        },
        {
          header: 'Mentor',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.mentor?.name}
                color={row?.mentor?.color}
                official_email_id={row?.mentor?.official_email_id}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Status',
          width: 180,
          render: ({row, styles: {rowText}}) => {
            const colors = useTheme('colors');
            if (
              row?.outcome &&
              (row?.outcome == 'Rejected' ||
                row?.outcome == 'Drop' ||
                row?.outcome == 'Denied by Resource' ||
                row?.outcome == 'Lost')
            ) {
              return (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('candidate-lost-description-pop-up', {
                        row,
                      });
                    }}>
                    <Text
                      numberOfLines={1}
                      style={{
                        ...rowText,
                        color: 'Red',
                        overflow: 'hidden',
                      }}>
                      {row?.outcome}
                    </Text>
                    <Text
                      numberOfLines={1}
                      style={{
                        ...rowText,
                        fontSize: 12,
                        color: colors.NEUTRAL_MEDIUM,
                        overflow: 'hidden',
                      }}>
                      {row?.outcome_description}
                    </Text>
                  </TouchableOpacity>
                </>
              );
            } else if (row?.outcome && row?.outcome == 'Selected') {
              return (
                <Text
                  numberOfLines={1}
                  style={{
                    ...rowText,
                    color: '#39e75f',
                    overflow: 'hidden',
                  }}>
                  {row?.outcome}
                </Text>
              );
            } else if (
              row?.outcome &&
              row?.outcome == 'Shift To Another Project'
            ) {
              return (
                <Text
                  numberOfLines={1}
                  style={{
                    ...rowText,
                    fontSize: 13,
                    color: '#30839f',
                    overflow: 'hidden',
                  }}>
                  {row?.outcome}
                </Text>
              );
            } else if (!row.outcome) {
              if (row?.status == 'Active' && !row?.interview_date) {
                return (
                  <>
                    <Text
                      numberOfLines={1}
                      style={{
                        ...rowText,
                        color: colors.NEUTRAL_MEDIUM,
                        overflow: 'hidden',
                      }}>
                      Resume shared
                    </Text>
                  </>
                );
              }
              if (
                row?.status == 'Active' &&
                getZeroTimeDate(row?.interview_date) >
                  getZeroTimeDate(new Date())
              ) {
                return (
                  <>
                    <Text
                      numberOfLines={1}
                      style={{
                        ...rowText,
                        color: colors.NEUTRAL_MEDIUM,
                        overflow: 'hidden',
                      }}>
                      Interview scheduled
                    </Text>
                    <Text
                      style={{
                        ...rowText,
                        fontSize: 13,
                        color: colors.NEUTRAL_LOW,
                      }}>
                      {moment(row?.interview_date).format('DD MMM')}
                    </Text>
                  </>
                );
              }
            } else {
              return null;
            }
          },
        },
        {
          header: 'Outcome date',
          field: 'outcome_date',
          type: 'date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
        },
        {
          header: 'Reason',
          field: '  outcome_rejection_reason',
          type: 'text',
        },
        // STATUS,

        // OUTCOME_DATE,
        // EXPERIENCE,
        // REQUIRMENT,
        // MENTOR,
        // MODE,
        // OUTCOME_DESCRIPTION,
        // REJECTION_REASON,
      ]}
      moreActions={() => [
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [{path: 'mentor', select: {name: 1}}],
              api: `/staffaugmentationopportunities/${row?._id}/activityLogs`,
              displayFields: {
                mentor: {
                  label: 'Mentor',
                  field: 'mentor.name',
                },
              },
            });
          },
        },
      ]}
    />
  );
};

export const InterviewResourcesTableForAnalytics = props => {
  const {navigation} = props;
  const params = props.route.params;
  const {product, row, addOnFilter: _addOnFilter, hideAddResource} = params;

  let addOnFilter = {
    _id: {$in: row},
  };
  if (_addOnFilter) {
    addOnFilter = {...addOnFilter, ..._addOnFilter};
  }
  const {
    scheduleResourceInterview,
    outcomeDateCorrection,
    updateResume,
    markInterviewResourceOutcome,
    // interviewCommunication,
  } = leadControllers(props);
  const {resourceUrl, user} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['StaffAugmentation'],
  });
  return (
    <Table
      api={`/interviewresources/all`}
      sort={{rfr_assigned_date: -1}}
      eventSourceId={'StaffAugmentation'}
      addOnFilter={{
        ...addOnFilter,
      }}
      renderHeader={() => <TableHeader title={'Resources'} />}
      columns={[
        RESOURCE_NAME,
        {
          header: 'Exp',
          field: 'resource.experience',
          type: 'text',
          width: 80,
        },
        {
          header: 'Skill',
          render: RenderSkills,
          width: 150,
        },
        RESUME(resourceUrl),
        {
          header: 'Mentor',
          field: 'mentor',
          type: 'userAvatar',
          width: 80,
        },
        {
          type: 'date',
          header: 'Assigned',
          field: 'rfr_assigned_date',
          formatOptions: {
            format: 'DD MMM, YY',
          },
          width: 180,
        },
        {
          header: 'Interview',
          field: 'interview_date',
          type: 'date',
          width: 180,
          formatOptions: {
            format: 'DD MMM, YY',
          },
        },
        {
          header: 'Outcome date',
          field: 'outcome_date',
          type: 'date',
          width: 180,
          formatOptions: {
            format: 'DD MMM, YY',
          },
        },
        STATUS,
        {
          header: 'Reason',
          field: 'outcome_rejection_reason',
          type: 'textArea',
          width: 350,
        },
      ]}
    />
  );
};
