import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';
import {getCurrentMonth, useFilter} from '@unthinkable/react-filters';
import {PeriodRangeMonthWiseBreakupFilter} from '../../../components/filter/Filters';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {getPreviousMonthWithDateParameters} from '../../../utils/util';

export const KeyPerformanceIndicatorTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'keyperformanceindicators',
  });

  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {params: filterParams} = filterValues;
  const {period} = filterParams;
  const previousMonthDate = getPreviousMonthWithDateParameters(
    period?.from,
    period?.to,
  );

  return (
    <Table
      eventSourceId={'keyperformanceindicators'}
      api={`/kpi/data`}
      filter={filterValues?.params}
      renderHeader={() => (
        <TableHeader
          title="Key Performance Indicator"
          actions={[
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              asParam
            />,
            <AddButton
              title="Add"
              view="add-key-perfermance-indicator"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      // fields={{
      //   kra_id: {name: 1},
      //   name: 1,
      //   description: 1,
      //   api: 1,
      //   entity: {label: 1},
      //   role: 1,
      //   department: {name: 1},
      // }}
      columns={[
        {
          field: 'kra_id.name',
          header: 'KRA',
          responsive: 'sm',
        },
        {
          field: 'name',
          header: 'KPI',
          responsive: 'sm',
        },
        {
          header: 'Current',
          align: 'right',
          render: CountCellRender,
          count_field: 'current_value',
          width: 150,
          onPress: ({row}) => {
            const route = row?.api?.view;
            navigation.navigate(route, {
              period: {...filterValues?.params?.period},
              forKpi: true,
            });
          },
        },
        {
          header: 'Previous',
          align: 'right',
          render: CountCellRender,
          count_field: 'previous_value',
          width: 150,
          // type: 'text',
          onPress: ({row}) => {
            const route = row?.api?.view;
            navigation.navigate(route, {
              period: {...previousMonthDate},
              forKpi: true,
            });
          },
        },
        {
          field: 'variance',
          header: 'Variance',
          type: 'text',
          width: 150,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-key-performance-indicator', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/keyperformanceindicators/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

export const AllKeyPerformanceIndicatorTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'keyperformanceindicators',
  });

  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    // params: {
    //   period: getCurrentMonth(),
    // },
  });
  // const {period = {}} = filterValues?.params;
  // const previousPeriod = {};
  // if (period.filterOf === 'Month') {
  //   (previousPeriod.from = moment(period.from)
  //     .subtract(1, 'M')
  //     .startOf('M')
  //     .toDate()),
  //     (previousPeriod.to = moment(period.from)
  //       .subtract(1, 'M')
  //       .endOf('M')
  //       .toDate());
  // }
  return (
    <Table
      eventSourceId={'keyperformanceindicators'}
      api={`/keyperformanceindicators`}
      filter={filterValues?.params}
      renderHeader={() => (
        <TableHeader
          title="Key Performance Indicator"
          actions={[
            <AddButton
              title="Add"
              view="add-key-perfermance-indicator"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      fields={{
        kra_id: {name: 1},
        name: 1,
        description: 1,
        api: 1,
        entity: {label: 1},
        role: 1,
        department: {name: 1},
      }}
      columns={[
        {
          field: 'kra_id.name',
          header: 'KRA',
          responsive: 'sm',
        },
        {
          field: 'name',
          header: 'KPI',
          responsive: 'sm',
        },
        {
          field: 'department.name',
          header: 'Department',
          responsive: 'sm',
        },
        {
          field: 'entity.label',
          header: 'Entity',
          width: 150,
        },
        {
          field: 'role',
          header: 'Role',
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-key-performance-indicator', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/keyperformanceindicators/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};

// export default KeyPerformanceIndicatorTable;

export const KeyPerformanceIndicatorAchievementTable = props => {
  const {navigation} = props;
  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'keyperformanceindicators',
  });

  const {applyFilter, filterValues, onChangeFilter} = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const {params: filterParams} = filterValues;
  const {period} = filterParams;
  const previousMonthDate = getPreviousMonthWithDateParameters(
    period?.from,
    period?.to,
  );

  return (
    <Table
      eventSourceId={'keyperformanceindicators'}
      api={`/kpi/achievement-data`}
      filter={filterValues?.params}
      renderHeader={() => (
        <TableHeader
          title="Key Performance Indicator"
          actions={[
            <PeriodRangeMonthWiseBreakupFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              asParam
            />,
            <AddButton
              title="Add"
              view="add-key-perfermance-indicator"
              params={props?.route?.params}
            />,
          ]}
        />
      )}
      // fields={{
      //   kra_id: {name: 1},
      //   name: 1,
      //   description: 1,
      //   api: 1,
      //   entity: {label: 1},
      //   role: 1,
      //   department: {name: 1},
      // }}
      columns={[
        {
          field: 'kra_id.name',
          header: 'KRA',
          responsive: 'sm',
        },
        {
          field: 'kpi_id.name',
          header: 'KPI',
          responsive: 'sm',
        },
        {
          header: 'Current',
          align: 'right',
          render: CountCellRender,
          count_field: 'current_value',
          width: 150,
          onPress: ({row}) => {
            const route = row?.api?.view;
            navigation.navigate(route, {
              period: {...filterValues?.params?.period},
              forKpi: true,
            });
          },
        },
        {
          header: 'Previous',
          align: 'right',
          render: CountCellRender,
          count_field: 'previous_value',
          width: 150,
          // type: 'text',
          onPress: ({row}) => {
            const route = row?.api?.view;
            navigation.navigate(route, {
              period: {...previousMonthDate},
              forKpi: true,
            });
          },
        },
        {
          field: 'variance',
          header: 'Variance',
          type: 'text',
          width: 150,
        },
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({row}) => {
            navigation.navigate('edit-key-performance-indicator', {row});
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({row}) => {
            deleteInvoke({
              uri: `/keyperformanceindicators/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
      {...props}
    />
  );
};
