import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {UserAvatarWithNameCodeJoiningDate} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Text} from '@unthinkable/react-core-components';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab';
import {useAppStateContext} from '../../../providers/AppState';
import {useInvoke} from '../../../controllers/useInvoke';
import {AdditionalEmailRequesterTable} from './AdditionalEmailsRequestsTables';
import {
  AdditionalEmailApproverTables,
  AdditionalEmailFinanceTables,
  AdditionalEmailRequesterTables,
} from './AdditionalRequestsTables';
import {AddButton} from '../../../components/button/AddButton';
import { getZeroTimeDate } from '@unthinkable/react-date-picker';

export const AdditionalEmailRequesterTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    Draft: {
      label: 'Draft',
      view: (
        <AdditionalEmailRequesterTables
          tab={'Draft'}
          filterValues={filterValues}
          addOnFilter={{
            requester: employee._id,
            status: 'Draft',
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        requester: employee._id,
        status: 'Draft',
        ...filterValues.filter,
      },
      limit: 500,
    },
    Requested: {
      label: 'Requested',
      view: (
        <AdditionalEmailRequesterTables
          tab={'Requested'}
          filterValues={filterValues}
          addOnFilter={{
            // status: 'Requested',
            $or: [
              {status: 'Requested'},
              {status: 'Approved', approver_status: 'IT'},
            ],
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        $or: [
          {status: 'Requested'},
          {status: 'Approved', approver_status: 'IT'},
        ],
        ...filterValues.filter,
      },
      limit: 500,
    },
    Issued: {
      label: 'Issued',
      view: (
        <AdditionalEmailRequesterTables
          tab={'Approved'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Issued',
            requester: employee._id,
            inactivation_request: {$exists: false},
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        requester: employee._id,
        status: 'Issued',
        inactivation_request: {$exists: false},
        ...filterValues.filter,
      },
    },
    inactive_requests: {
      label: 'Inactivation Requests',
      view: (
        <AdditionalEmailRequesterTables
          tab={'StopTxn'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Issued',
            requester: employee._id,
            inactivation_request: true,
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        requester: employee._id,
        status: 'Issued',
        inactivation_request: true,
        ...filterValues.filter,
      },
    },
    inactive_emails: {
      label: 'Inactive Emails',
      view: (
        <AdditionalEmailRequesterTables
          tab={'Inactive'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Inactive',
            requester: employee._id,
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        status: 'Inactive',
        requester: employee._id,
        ...filterValues.filter,
      },
    },
    rejected: {
      label: 'Rejected',
      view: (
        <AdditionalEmailRequesterTables
          tab={'Rejected'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Rejected',
            requester: employee._id,
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        requester: employee._id,
        status: 'Rejected',
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'project',
            },
            {
              type: 'date',
              label: 'Requested On',
              field: 'request_date',
            },
          ]}
        />,
        <AddButton
          title="Additional Email Req"
          view={'add-additional-email-request'}
        />,
      ]}
    />
  );
};

export const AdditionalEmailApproverTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    Requested: {
      label: 'Requested',
      view: (
        <AdditionalEmailApproverTables
          tab={'Requested'}
          filterValues={filterValues}
          addOnFilter={{
            // status: 'Requested',
            approver: employee._id,
            status: 'Requested',
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        approver: employee._id,
        // status: 'Requested',
        status: 'Requested',
        ...filterValues.filter,
      },
      limit: 500,
    },
    Closed: {
      label: 'Closed',
      view: (
        <AdditionalEmailApproverTables
          tab={'Closed'}
          filterValues={filterValues}
          addOnFilter={{
            status: {
              $in: ['Approved', 'Rejected', 'Active', 'Inactive', 'Issued'],
            },
            approver: employee._id,
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        status: {$in: ['Approved', 'Rejected', 'Active', 'Inactive', 'Issued']},
        approver: employee._id,
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'project',
            },
            {
              type: 'autoComplete',
              label: 'Requester',
              api: '/employeeSuggestions',
              filter: {
                status: 'Active',
              },
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'official_email_id',
              field: 'requester',
            },
            {
              type: 'date',
              label: 'Requested On',
              field: 'request_date',
            },
          ]}
        />,
      ]}
    />
  );
};

export const AdditionalEmailFinanceTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {
    user: {employee},
  } = useAppStateContext();
  const tabs = {
    Requested: {
      label: 'Requested',
      view: (
        <AdditionalEmailFinanceTables
          tab={'Requested'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Approved',
            approver_status: 'IT',
            approved_by_finance_date: {$exists: false},
            ...filterValues.filter,
          }}
          searchValue={searchValue}
          {...props}
        />
      ),
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      search: searchValue,
      searchFields: 'invoice_number',
      addOnFilter: {
        status: 'Approved',
        approver_status: 'IT',
        approved_by_finance_date: {$exists: false},
        ...filterValues.filter,
      },
      limit: 500,
    },
    Issued: {
      label: 'Issued',
      view: (
        <AdditionalEmailFinanceTables
          tab={'Approved'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Issued',
            inactivation_request: {$exists: false},
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        status: 'Issued',
        inactivation_request: {$exists: false},
        ...filterValues.filter,
      },
    },
    Overdue: {
      label: 'Overdue',
      view: (
        <AdditionalEmailFinanceTables
          tab={'Overdue'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Issued',
            $or : [
              {
                extended_date : {$lt : getZeroTimeDate(new Date())},
                is_extend : true
              },
              {
                to_date : {$lt : getZeroTimeDate(new Date())},
                is_extend : {$in : [null, false]}
              }
            ],
            inactivation_request: {$exists: false},
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        status: 'Issued',
        $or : [
          {
            extended_date : {$lt : getZeroTimeDate(new Date())},
            is_extend : true
          },
          {
            to_date : {$lt : getZeroTimeDate(new Date())},
            is_extend : {$in : [null, false]}
          }
        ],
        inactivation_request: {$exists: false},
        ...filterValues.filter,
      },
    },
    inactive_requests: {
      label: 'Inactivation Requests',
      view: (
        <AdditionalEmailFinanceTables
          tab={'StopTxn'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Issued',
            inactivation_request: true,
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        status: 'Issued',
        inactivation_request: true,
        ...filterValues.filter,
      },
    },
    inactive_emails: {
      label: 'Inactive Emails',
      view: (
        <AdditionalEmailFinanceTables
          tab={'Inactive'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Inactive',
            ...filterValues.filter,
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        status: 'Inactive',
        ...filterValues.filter,
      },
    },
    rejected: {
      label: 'Rejected',
      view: (
        <AdditionalEmailFinanceTables
          tab={'Rejected'}
          filterValues={filterValues}
          addOnFilter={{
            status: 'Rejected',
          }}
          {...props}
        />
      ),
      search: searchValue,
      searchFields: 'invoice_number',
      eventSourceId: 'budget',
      api: '/additionalemailrequests/details',
      addOnFilter: {
        status: 'Rejected',
        ...filterValues.filter,
      },
    },
  };
  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'team',
            },
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Project',
              api: '/projects',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'project',
            },
            {
              type: 'date',
              label: 'Requested On',
              field: 'request_date',
            },
            {
              type: 'date',
              label: 'To Date',
              field: 'to_date',
            },
          ]}
        />,
        <AddButton
          title="Additional Email Req"
          view={'add-additional-email-request-it'}
        />,
      ]}
    />
  );
};
