import {
  ActivityIndicator,
  Text,
  View,
} from '@unthinkable/react-core-components';
import moment from 'moment';
import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {AmountRender} from '../../order/views/components/AmountRender';
import {Durations} from '../../order/views/components/Durations';
import {useAppStateContext} from '../../../providers/AppState';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';

const RenderStatus = ({row, styles, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'subscriptionplanned',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flexDirection: 'row'}}>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/subscriptionplanneds/${row?._id}`,
                props: {
                  status: 'Approved',
                  approved_by_head_date: getZeroTimeDate(new Date()),
                  approver_status: 'Finance',
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Approve </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              navigation.navigate('reject-subscription-request', {
                row,
                rejected_by: 'Team Head',
              });
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </View>
      )}
    </>
  );
};

export const SubscriptionRequesterTable = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'subscriptionplanned',
  });
  return (
    <Table
      eventSourceId={['subscriptionplanned']}
      api={`/subscriptionplanned/details`}
      addOnFilter={addOnFilter}
      //   search={searchValue}
      //   searchFields="invoice_number"
      limit={500}
      onRowPress={({row}) => {
        if (tab === 'Approved' || tab === 'Rejected') {
          navigation.navigate('edit-subscription-request', {
            row,
            readOnly: true,
          });
        } else {
          navigation.navigate('edit-subscription-request', {row});
        }
      }}
      columns={[
        {
          header: 'Category',
          field: 'category.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 300,
        },
        {
          header: 'Limit',
          field: 'amount',
          type: 'currency',
          width: 200,
          render: ({row, styles}) => {
            return (
              <AmountRender row={row} styles={styles} amount={row?.amount} />
            );
          },
        },
        {
          header: 'Renewal Date',
          align: 'center',
          type: 'date',
          field: 'renewal_date',
          width: 250,
        },
        tab != 'StopTxn' &&
          tab != 'Inactive' && {
            header: 'Requested',
            field: 'request_date',
            type: 'date',
            width: 100,
          },
        tab === 'Requested' && {
          header: 'Pending by',
          field: 'approver_status',
          type: 'colorTag',
          minWidth: 200,
        },
        tab === 'StopTxn' && {
          header: 'Stop requested on',
          field: 'subscription_request_date',
          type: 'date',
          width: 150,
        },
        tab === 'Inactive' && {
          header: 'Stopped on',
          field: 'inactive_by_finance_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 100,
          align: 'center',
        },
        {
          header: 'Head Date',
          type: 'date',
          width: 120,
          visible: ({values}) => {
            if (tab === 'Active') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_by_head_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Finance Date',
          type: 'date',
          width: 120,
          visible: ({values}) => {
            if (tab === 'Active') return true;
          },
          render: ({row, styles}) => {
            return (
              <Text style={{...styles.rowText, color: '#34C759'}}>
                {moment(row.approved_by_finance_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Rejected',
          type: 'date',
          visible: ({values}) => {
            if (tab === 'Rejected') return true;
          },
          render: ({row, styles}) => {
            return (
              <View>
                <Text style={{...styles.rowText, color: '#FF3B30'}}>
                  {moment(row.rejected_date).format('DD MMM YY')}
                </Text>
                <Text style={{...styles.rowText, color: '#737373'}}>
                  {row.reject_reason}
                </Text>
              </View>
            );
          },
          width: 200,
        },
      ]}
      moreActions={[
        tab === 'Active' && {
          title: 'Close Subscription',
          confirm: {
            title: 'Stop',
            message: 'Are you sure you want to stop Subscription?',
            confirmText: 'Stop',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/subscriptionplanneds/${row._id}`,
              props: {
                status: 'Active',
                cancel_subscription_request: true,
                subscription_request_date: getZeroTimeDate(new Date()),
              },
            });
          },
        },
        tab === 'Active' && {
          title: 'Upgrade Subscription',
          variant: 'Error',
          onPress: ({row}) => {
            navigation.navigate('add-subscription-request', {
              row,
              upgrade : true
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'entity', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/subscriptionplanneds/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};

export const SubscriptionApproverTable = props => {
  const {navigation, tab, addOnFilter, orgView} = props;
  let {
    user: {employee},
  } = useAppStateContext();
  let filter = {approver: employee._id};
  if (orgView) {
    filter = {};
  }
  const UpdateInvoke = useInvoke({
    method: 'put',
    eventSourceId: ['subscriptionplanned'],
  });
  return (
    <Table
      eventSourceId={['subscriptionplanned']}
      api={`/subscriptionplanned/details`}
      addOnFilter={{...addOnFilter, ...filter}}
      onRowPress={({row}) => {
        navigation.navigate('edit-subscription-request', {
          row,
          readOnly: true,
        });
      }}
      columns={[
        {
          header: 'Requested On',
          field: 'request_date',
          type: 'date',
          width: 150,
        },
        {
          header: 'Category',
          field: 'category.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 300,
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'currency',
          width: 150,
          render: ({row, styles}) => {
            return (
              <AmountRender row={row} styles={styles} amount={row?.amount} />
            );
          },
        },
        {
          header: 'Renewal Date',
          align: 'center',
          type: 'date',
          field: 'renewal_date',
          width: 250,
        },
        {
          header: 'Requester',
          field: 'requested_by',
          type: 'userAvatar',
          width: 100,
        },
        {
          header: 'Action',
          width: 200,
          visible: ({}) => {
            if (tab === 'ApproverActive') return true;
          },
          align: 'center',

          render: ({row, styles}) => {
            return (
              <RenderStatus row={row} styles={styles} navigation={navigation} />
            );
          },
        },
        {
          header: 'Status',
          visible: ({}) => {
            if (tab === 'ApproverClosed') return true;
          },
          render: ({row, styles}) => {
            let color = '';
            let status = '';
            if (
              row?.status === 'Approved' ||
              row?.status == 'Active' ||
              row?.status == 'Inactive'
            ) {
              color = '#34C759';
              status = 'Approved';
            } else if (
              row?.status === 'Rejected' &&
              row?.rejected_by === 'Team Head'
            ) {
              color = '#FF5247';
              status = 'Rejected';
            } else if (
              row?.status === 'Rejected' &&
              row?.rejected_by === 'Finance'
            ) {
              color = '#34C759';
              status = 'Approved';
            }
            return (
              <Text style={{...styles.rowText, color: color}}>{status}</Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/subscriptionplanneds/${row?._id}/activityLogs`,
            });
          },
        },
        // {
        //   title: 'Approve',
        //   onPress: ({row}) => {
        //     UpdateInvoke({
        //       uri: `/subscriptionplanneds/${row._id}`,
        //       props: {
        //         status: 'Approve',
        //       },
        //     });
        //   },
        // },
        // {
        //   title: 'Reject',
        //   onPress: ({row}) => {
        //     UpdateInvoke({
        //       uri: `/subscriptionplanneds/${row._id}`,
        //       props: {
        //         status: 'Rejected',
        //       },
        //     });
        //   },
        // },
      ]}
    />
  );
};

export const SubscriptionFinanceApproverTable = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const {download,resourceUrl} = useAppStateContext();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'subscriptionplanned',
  });
  const postInvoke = useInvoke({
    method: 'post',
    eventSourceId: 'subscriptionplanned',
  });
  const fetchProps = {
    addOnFilter,
    api: `/subscriptionplanned/details`,
  };

  return (
    <Table
      eventSourceId={['subscriptionplanned']}
      api={`/subscriptionplanned/details`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="invoice_number"
      limit={500}
      onRowPress={({row}) => {
        navigation.navigate('edit-subscription-request', {
          row,
          readOnly: true,
        });
      }}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Team': 'team.name',
                      Vendor: 'vendor.name',
                      Amount: 'amount',
                      'Renewal Date': 'renewal_date',
                      'Request Date': 'request_date',
                      'Requested By': 'requested_by.name',
                      'Approved By': 'approver.name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Category',
          field: 'category.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'currency',
          width: 200,
        },
        {
          header: 'Renewal Date',
          align: 'center',
          type: 'date',
          field: 'renewal_date',
          width: 250,
        },
        tab === 'StopTxn' && {
          header: 'Renewal End Date',
          field: 'next_renewal_date',
          type: 'text',
          width: 100,
          align: 'center',
        },
        {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 100,
        },
        tab === 'InactiveTransactions' && {
          header: 'Stopped on',
          field: 'inactive_by_finance_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Requester',
          field: 'requested_by',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
        // (tab === 'ApproverClosed' || tab === 'InactiveTransactions') && {
        //   header: 'Card',
        //   field: 'fund_center.name',
        //   type: 'text',
        //   width: 180,
        // },
        // {
        //   header: 'Status',
        //   visible: ({}) => {
        //     if (tab === 'ApproverClosed') return true;
        //   },
        //   render: ({row, styles}) => {
        //     let color = '';
        //     let status = '';
        //     if (
        //       row?.status === 'Approved' ||
        //       row?.status == 'Active' ||
        //       row?.status == 'Inactive'
        //     ) {
        //       color = '#34C759';
        //       status = 'Approved';
        //     } else if (row?.status === 'Rejected') {
        //       color = '#FF5247';
        //       status = 'Rejected';
        //     }
        //     return (
        //       <Text style={{...styles.rowText, color: color}}>{status}</Text>
        //     );
        //   },
        // },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/subscriptionplanneds/${row?._id}/activityLogs`,
            });
          },
        },
        tab === 'FinanceRequested' && {
          title: 'Approve',
          onPress: ({row}) => {
            postInvoke({
              uri: `/approveSubscription`,
              props: {
                subscription : row
              },
            });
          },
        },
        tab === 'FinanceRequested' && {
          title: 'Reject Subscription',
          variant: 'Error',
          onPress: ({row}) => {
            navigation.navigate('reject-subscription-request', {
              row,
              rejected_by: 'Finance',
            });
          },
        },
        // tab === 'StopTxn' && {
        //   title: 'Approve Upgrade Request',
        //   variant: 'Error',
        //   onPress: ({row}) => {
        //     navigation.navigate('approve-upgrade-subscription', {
        //       row,
        //     });
        //   },
        //   visible: ({row}) => row?.subscription_request_type == 'Renew',
        // },
        tab === 'StopTxn' && {
          title: 'Approve Request',
          variant: 'Error',
          confirm: {
            title: 'Approve',
            message: 'Are you sure you want to close Subscription request?',
            confirmText: 'Yes',
          },
          visible: ({row}) => row?.cancel_subscription_request,
          onPress: ({row}) => {
            invoke({
              uri: `/subscriptionplanneds/${row._id}`,
              props: {
                status: 'Inactive',
                inactive_by_finance_on: getZeroTimeDate(new Date()),
              },
            });
          },
        },
      ]}
    />
  );
};

export const SubscriptionOrgApproverTable = props => {
  let {navigation, addOnFilter, tab, filterValues, searchValue} = props;
  if (filterValues) {
    addOnFilter = {...addOnFilter, ...filterValues.filter};
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'subscriptionplanned',
  });

  return (
    <Table
      eventSourceId={['subscriptionplanned']}
      api={`/subscriptionplanned/details`}
      addOnFilter={addOnFilter}
      search={searchValue}
      searchFields="invoice_number"
      limit={500}
      onRowPress={({row}) => {
        navigation.navigate('edit-subscription-request', {
          row,
          readOnly: true,
        });
      }}
      columns={[
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 200,
        },
        {
          header: 'Amount',
          field: 'amount',
          type: 'currency',
          width: 200,
        },
        {
          header: 'Renewal Date',
          align: 'center',
          type: 'date',
          field: 'renewal_date',
          width: 250,
        },
        {
          header: 'Requested',
          field: 'request_date',
          type: 'date',
          width: 100,
        },
        tab === 'InactiveTransactions' && {
          header: 'Stopped on',
          field: 'inactive_by_finance_on',
          type: 'date',
          width: 120,
        },
        {
          header: 'Requester',
          field: 'requested_by',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 150,
          align: 'center',
        },
        // (tab === 'ApproverClosed' || tab === 'InactiveTransactions') && {
        //   header: 'Card',
        //   field: 'fund_center.name',
        //   type: 'text',
        //   width: 180,
        // },
        // {
        //   header: 'Status',
        //   visible: ({}) => {
        //     if (tab === 'ApproverClosed') return true;
        //   },
        //   render: ({row, styles}) => {
        //     let color = '';
        //     let status = '';
        //     if (
        //       row?.status === 'Approved' ||
        //       row?.status == 'Active' ||
        //       row?.status == 'Inactive'
        //     ) {
        //       color = '#34C759';
        //       status = 'Approved';
        //     } else if (row?.status === 'Rejected') {
        //       color = '#FF5247';
        //       status = 'Rejected';
        //     }
        //     return (
        //       <Text style={{...styles.rowText, color: color}}>{status}</Text>
        //     );
        //   },
        // },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/subscriptionplanneds/${row?._id}/activityLogs`,
            });
          },
        },
        tab === 'FinanceRequested' && {
          title: 'Approve',
          onPress: ({row}) => {
            invoke({
              uri: `/subscriptionplanneds/${row._id}`,
              props: {
                status: 'Active',
                approved_by_finance_date: getZeroTimeDate(new Date()),
              },
            });
          },
        },
        tab === 'FinanceRequested' && {
          title: 'Reject Subscription',
          variant: 'Error',
          onPress: ({row}) => {
            navigation.navigate('reject-subscription-request', {
              row,
              rejected_by: 'Finance',
            });
          },
        },
        tab === 'StopTxn' && {
          title: 'Stop Subscription',
          variant: 'Error',
          confirm: {
            title: 'Stop',
            message: 'Are you sure you want to stop Subscription?',
            confirmText: 'Stop',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/subscriptionplanneds/${row._id}`,
              props: {
                status: 'Inactive',
                inactive_by_finance_on: getZeroTimeDate(new Date()),
              },
            });
          },
        },
      ]}
    />
  );
};


export const SubscriptionCategoryTable = () => {
  const navigation = useNavigation();
  return (
    <Table
      eventSourceId="SubscriptionCategory"
      api={`/subscriptioncategories`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`edit-subscription-category`, {
          row
        });
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Subscription Category"
            actions={[
              <AddButton
                title="Subscription Category"
                view="add-subscription-category"
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
        },
      ]}
    />
  );
};
