import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form} from '../../../components/form/Form';
import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter} from '../../../components/filter/Filters';
import { RowWithSeparator } from '@unthinkable/react-layout';
import { GroupContentItem } from '../../../components/card/GroupContentItem';

const unmarkDelivered = ({invoke, user}) => ({
  title: 'Unmark Delivered',
  confirm: {
    title: 'Unmark Delivered',
    message: 'Do you want unmark delivered for this data?',
  },
  visible: () => {
    if (
      user.email === 'sushil.nagvan@daffodilsw.com' ||
      user.email === 'amit.singh@daffodildb.com' ||
      user.email === 'vanshika.dixit@unthinkable.co' ||
      user.email === 'aswathy.krishnankutty@unthinkable.co'
    ) {
      return true;
    }
  },
  onPress: props => {
    const {row = {}} = props;
    invoke({
      uri: `/unmarkdelivered/${row?._id}`,
      props: {
        deliveryphasesData: row,
      },
    });
  },
});

export const DeliveryProgressTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {deliveryProgressFilter} = params;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'ActiveOrderOfOrdersAndDeliveries',
  });
  const {user} = useAppStateContext();
  return (
    <Table
      {...props}
      renderHeader={() => <TableHeader title="Delivery Progress" />}
      api={`/deliveryphases`}
      filter={{
        ...deliveryProgressFilter,
      }}
      eventSourceId={'ActiveOrderOfOrdersAndDeliveries'}
      fields={{
        delivered_date: 1,
        project_resource_working_data: {
          delivery_number: 1,
        },
        customer: {name: 1},
        billed_hours: 1,
        skill_requirement: 1,
      }}
      columns={[
        {
          type: 'date',
          field: 'delivered_date',
          header: 'Delivered Date',
        },
        {
          field: 'project_resource_working_data.delivery_number',
          header: 'Delivery No.',
        },
        {
          field: 'customer.name',
          header: 'Customer',
        },
        {
          field: 'billed_hours',
          header: 'Billed Hours',
          type: 'number',
        },
      ]}
      moreActions={[
        {
          title: 'Edit',

          onPress: ({row}) => {
            navigation.navigate('edit-delivery-progress', {row});
          },
          visible: () => {
            if (
              user.email === 'sushil.nagvan@daffodilsw.com' ||
              user.email === 'amit.singh@daffodildb.com' ||
              user.email === 'vanshika.dixit@unthinkable.co' ||
              user.email === 'aswathy.krishnankutty@unthinkable.co'
            ) {
              return true;
            }
          },
        },
        unmarkDelivered({invoke, user}),
      ]}
    />
  );
};

const DeliveryProgressForm = props => {
  const {route: {params} = {}} = props;
  const {row} = params;
  const {onSubmit} = useFormSubmit({
    uri: '/deliveryphases',
    eventSourceId: 'ActiveOrderOfOrdersAndDeliveries',
  });
  return (
    <Form
      api={`/deliveryphases/${row?._id}`}
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Delivery Date',
          field: 'delivered_date',
          type: 'date',
          required: true,
          size: 6,
        },

        {
          label: 'Billed Hours',
          field: 'billed_hours',
          type: 'number',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const EditDeliveryProgress = props => {
  return (
    <DeliveryProgressForm
      mode="edit"
      header={'Update Delivery Progress'}
      {...props}
    />
  );
};

export const KPIDeliveryPhasesTable = props => {
  const {
    navigation,
    route: {params},
  } = props;
  const {period = {}} = params;
  const {applyFilter, filterValues} = useFilter({});
  const {filter = {}} = filterValues || {};
  return (
    <Table
      {...props}
      addOnFilter={filter}
      renderHeader={() => {
        return (
          <TableHeader
            title="Delivery Phases"
            // actions={[
            //   <GroupFilter
            //     filterValues={filterValues}
            //     applyFilter={applyFilter}
            //     filters={[
            //       {
            //         type: 'multiAutoComplete',
            //         label: 'Delivery Number',
            //         api: '/projectresourceworkingdata',
            //         placeholder: 'Select',
            //         suggestionField: 'delivery_number',
            //         valueField: 'delivery_number',
            //         field: 'project_resource_working_data',
            //         searchFields: ['delivery_number'],
            //       },
            //     ]}
            //   />,
            // ]}
          />
        );
      }}
      limit = {50000}
      groupRow={{
        data: '_children',
        // defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.customer.name} />
              <GroupContentItem value={`${(Number((row?.billed_hours).toFixed(0)) || 0).toLocaleString('en-IN')} Amount`} />
            </RowWithSeparator>
          );
        },
      }}
      api={`/customerwiseDeliveryPhases`}
      filter={{
        status: {
          $in: ['Delivered', 'Fully Delivered'],
        },
        delivered_date: {$gte: period.from, $lte: period.to},
        is_internal: {$in: [null, false]},
      }}
      eventSourceId={'ActiveOrderOfOrdersAndDeliveries'}
      columns={[
        {
          type: 'date',
          field: 'delivered_date',
          header: 'Delivered Date',
        },
        {
          field: 'project_resource_working_data.delivery_number',
          header: 'Delivery No.',
        },
        {
          field: 'customer',
          header: 'Customer',
        },
        {
          field: 'billed_hours',
          header: 'Billed Hours',
          type: 'number',
        },
      ]}
    />
  );
};
