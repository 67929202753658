import {useFilter} from '@unthinkable/react-filters';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';
import {UserAvatarWithNameCodeJoiningDate} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Text} from '@unthinkable/react-core-components';
import {Table} from '../../../components/table/Table';
import {TabView} from '../../../components/tab';
import {useAppStateContext} from '../../../providers/AppState';
import {useInvoke} from '../../../controllers/useInvoke';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {getZeroTimeDate} from '@unthinkable/react-date-picker';
import moment from 'moment';

export const EmployeeForIT = props => {
  let {
    navigation,
    filter,
    params,
    search,
    api,
    tab,
    filterValues,
    addOnFilter: addFilter,
  } = props;
  const {organization, product, period, _id, ...rest} = params;
  const {filter: filterValueFlter = {}} = filterValues || {};
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {
    ...filterValueFlter,
    ...addFilter,
  };

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  const {user, download} = useAppStateContext();

  const fetchProps = {
    params,
    search,
    addOnFilter,
    searchFields: ['name', 'employee_code'],
    api: api ? api : `/employees/active`,
  };
  return (
    <Table
      {...props}
      {...fetchProps}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      selection={{
        actions: [
          {
            title: 'Download',
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee code': 'employee_code',
                      Name: 'name',
                      Gender: 'gender',
                      'Father name': 'father_name',
                      Experience: 'experience',
                      Designation: 'designation.name',
                      'Employee Level': 'employee_level.name',
                      'Mobile No': 'mobile_number',
                      'Official Email Id': 'official_email_id',
                      'Personal Email': 'email_id',
                      'Reporting To': 'reporting_to.name',
                      'Reporting To Code': 'reporting_to.employee_code',
                      'Functional Manager': 'functional_manager.name',
                      'Functional Manager Code':
                        'functional_manager.employee_code',
                      'Delivery Owner': 'delivery_owner.name',
                      'Delivery Owner Email':
                        'delivery_owner.official_email_id',
                      'Skill Leader': 'skill_leader.name',
                      'Skill Leader Email': 'skill_leader.official_email_id',
                      'Internship End Date': 'internship_end_date',
                      DOB: `dob`,
                      'Joining Date': 'joining_date',
                      'Releiving Date': 'relieving_date',
                      'Employee Type': 'employee_type.name',
                      'PAN No': 'pan_number',
                      'UAN No': 'uan_number',
                      'PF No': 'pf_number',
                      'Adhar No': 'adhar_number',
                      'LWF No': 'lwf_number',
                      Organization: 'organization.name',
                      Product: 'product.name',
                      'Sitting Location': 'sitting_location.name',
                      Department: 'department.name',
                      Location: 'accounting_location.name',
                      Practice: 'primary_practice.name',
                      'Practice head': 'primary_practice.owner.name',
                    },
                  },
                },
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Email',
          field: 'official_email_id',
        },
        // {
        //   header: 'Exp',
        //   field: 'experience',
        //   type: 'number',
        //   width: 80,
        //   render: ({row = {}, styles: {rowText = {}}}) => {
        //     return (
        //       <Text style={{...rowText}}>
        //         {row?.experience && `${row?.experience} yr`}
        //       </Text>
        //     );
        //   },
        // },
        // {
        //   header: 'Designation',
        //   field: 'designation.name',
        //   type: 'text',
        // },
        (tab == 'Inactive' ||
          tab == 'Legal' ||
          tab === 'Due' ||
          tab === 'Overdue') && {
          header: 'No Dues Status',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.email_status}
                items={[
                  // {
                  //   value:
                  //     row?.email_active_date &&
                  //     moment(row?.email_active_date).format('DD-MM-YYYY'),
                  // },
                  {
                    value: row?.active_reason,
                  },
                ]}
              />
            );
          },
        },
        (tab == 'Inactive' ||
          tab == 'Legal' ||
          tab === 'Due' ||
          tab === 'Overdue') && {
          header: 'Approved Upto',
          type: 'date',
          render: ({row}) => {
            if (row?.email_extended_date) {
              return (
                <PrimaryColumnCard primaryTitle={row?.email_extended_date} />
              );
            } else if (row?.email_active_date) {
              return (
                <PrimaryColumnCard primaryTitle={row?.email_active_date} />
              );
            }
          },
        },

        (tab == 'Inactive' ||
          tab == 'Legal' ||
          tab === 'Due' ||
          tab === 'Overdue') && {
          header: 'Approved By',
          type: 'userAvatar',
          field: 'approved_by',
        },
        (tab == 'Inactive' || tab == 'Legal' || tab == 'Closed') && {
          header: 'Current Status',
          field: 'email_activation_status',
          type: 'text',
        },
      ]}
      moreActions={[
        tab &&
          (tab === 'Inactive' || tab === 'Legal') && {
            title: 'Mark Email Inactive',
            confirm: {
              title: 'Mark Inactive',
              message: 'Are you sure you want to mark this email as inactive?',
              confirmText: 'Mark Inactive',
            },
            visible: ({row}) => {
              if (row?.email_activation_status === 'Active') return true;
            },
            onPress: ({row}) => {
              invoke({
                uri: `/employees/${row._id}`,
                props: {
                  email_activation_status: 'Inactive',
                  email_inactivation_date: new Date(),
                },
              });
            },
          },
        tab &&
          (tab === 'Inactive' || tab === 'Legal') && {
            title: 'Mark Email Active',
            // confirm: {
            //   title: 'Mark Active',
            //   message: 'Are you sure you want to mark this email as active?',
            //   confirmText: 'Mark Active',
            // },
            onPress: ({row}) => {
              navigation.navigate('mark-email-status-active', {
                row,
              });
            },
            // onPress: ({row}) => {
            //   invoke({
            //     uri: `/employees/${row._id}`,
            //     props: {
            //       email_activation_status: 'Active',
            //       email_inactivation_date: new Date(),
            //     },
            //   });
            // },
            visible: ({row}) => {
              if (row?.email_activation_status === 'Inactive') return true;
            },
          },
        tab &&
          (tab === 'Inactive' || tab === 'Legal') && {
            title: 'Extend',
            variant: 'Error',
            onPress: ({row}) => {
              navigation.navigate('extend-employee-email-request', {
                row,
              });
            },
          },
        tab &&
          (tab === 'Inactive' || tab === 'Legal') && {
            title: 'Update Remarks',
            variant: 'Error',
            onPress: ({row}) => {
              navigation.navigate('update-employee-email-remark', {
                row,
              });
            },
          },
      ]}
    />
  );
};

export const EmployeeForITTabs = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter} = useFilter({});
  const {filter, params: filterParams = {}} = filterValues;
  const {organization, product, period, ...rest} = params;
  const currentDate = getZeroTimeDate(new Date());
  const oneMonthAfterDate = new Date(currentDate);
  oneMonthAfterDate.setMonth(oneMonthAfterDate.getMonth() + 1);
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Active',
    api: '/employees/active',
    eventSourceId: 'EmployeeAssignment',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        filter={{
          ...filter,
          employee_status: 'Active',
          legal: {$in: [null, false]},
        }}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.inactive = {
    label: 'Inactive',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
        api="/employees/inactive-with-package-nongrp"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.due = {
    label: 'Due',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: {
      ...filter,
      $or: [
        {
          to_date: {$gte: getZeroTimeDate(new Date()), $lte: oneMonthAfterDate},
          is_email_extended: {$in: [null, false]},
        },
        {
          email_extended_date: {
            $gte: getZeroTimeDate(new Date()),
            $lte: oneMonthAfterDate,
          },
          is_email_extended: true,
        },
      ],
    },
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={{
          ...filter,
          $or: [
            {
              to_date: {
                $gte: getZeroTimeDate(new Date()),
                $lte: oneMonthAfterDate,
              },
              is_email_extended: {$in: [null, false]},
            },
            {
              email_extended_date: {
                $gte: getZeroTimeDate(new Date()),
                $lte: oneMonthAfterDate,
              },
              is_email_extended: true,
            },
          ],
        }}
        api="/employees/inactive-with-package-nongrp"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.overdue = {
    label: 'Overdue',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: {
      ...filter,
      $or: [
        {
          to_date: {$lt: getZeroTimeDate(new Date())},
          is_email_extended: {$in: [null, false]},
        },
        {
          email_extended_date: {$lt: getZeroTimeDate(new Date())},
          is_email_extended: true,
        },
      ],
    },
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={{
          ...filter,
          $or: [
            {
              to_date: {$lt: getZeroTimeDate(new Date())},
              is_email_extended: {$in: [null, false]},
            },
            {
              email_extended_date: {$lt: getZeroTimeDate(new Date())},
              is_email_extended: true,
            },
          ],
        }}
        api="/employees/inactive-with-package-nongrp"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.legal = {
    label: 'Legal...',
    api: '/employees/legal-with-package',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
        api="/employees/legal-with-package"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };
  return <TabView tabs={tabs} {...props} />;
};

export const EmployeeTabsForEmailActivation = props => {
  let {route: {params} = {}} = props;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({});
  const {filter = {}, params: filterParams = {}} = filterValues || {};
  const {organization, product, period, ...rest} = params;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };
  console.log('params>>>>>.', filterValues);
  const currentDate = getZeroTimeDate(new Date());
  const oneMonthAfterDate = new Date(currentDate);
  oneMonthAfterDate.setMonth(oneMonthAfterDate.getMonth() + 1);
  let tabs = {};
  tabs.active = {
    label: 'Active',
    api: '/employees/active',
    eventSourceId: 'EmployeeAssignment',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        params={{...params, ...filterParams}}
        search={searchValue}
        filterValues={filterValues}
        tab={'Active'}
        filter={{
          ...filter,
          employee_status: 'Active',
          legal: {$in: [null, false]},
        }}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.inactive = {
    label: 'Inactive',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        tab={'Inactive'}
        params={{...params, ...filterParams}}
        search={searchValue}
        filterValues={filterValues}
        addOnFilter={filter}
        api="/employees/inactive-with-package-nongrp"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Status',
            options: ['Active', 'Inactive'],
            placeholder: 'Select',
            field: 'email_activation_status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
        ]}
      />,
    ],
  };
  tabs.due = {
    label: 'Due',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: {
      ...filter,
      $or: [
        {
          email_active_date: {
            $gte: getZeroTimeDate(new Date()),
            $lte: oneMonthAfterDate,
          },
          is_email_extended: {$in: [null, false]},
        },
        {
          email_extended_date: {
            $gte: getZeroTimeDate(new Date()),
            $lte: oneMonthAfterDate,
          },
          is_email_extended: true,
        },
      ],
    },
    limit: 5000,
    view: (
      <EmployeeForIT
        tab={'Due'}
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={{
          ...filter,
          $or: [
            {
              email_active_date: {
                $gte: getZeroTimeDate(new Date()),
                $lte: oneMonthAfterDate,
              },
              is_email_extended: {$in: [null, false]},
            },
            {
              email_extended_date: {
                $gte: getZeroTimeDate(new Date()),
                $lte: oneMonthAfterDate,
              },
              is_email_extended: true,
            },
          ],
        }}
        api="/employees/inactive-with-package-nongrp"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };

  tabs.overdue = {
    label: 'Overdue',
    api: '/employees/inactive-with-package-nongrp',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: {
      ...filter,
      $or: [
        {
          email_active_date: {$lt: getZeroTimeDate(new Date())},
          is_email_extended: {$in: [null, false]},
        },
        {
          email_extended_date: {$lt: getZeroTimeDate(new Date())},
          is_email_extended: true,
        },
      ],
    },
    limit: 5000,
    view: (
      <EmployeeForIT
        tab={'Overdue'}
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={{
          ...filter,
          $or: [
            {
              email_active_date: {$lt: getZeroTimeDate(new Date())},
              is_email_extended: {$in: [null, false]},
            },
            {
              email_extended_date: {$lt: getZeroTimeDate(new Date())},
              is_email_extended: true,
            },
          ],
        }}
        api="/employees/inactive-with-package-nongrp"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
    ],
  };
  tabs.legal = {
    label: 'Legal',
    api: '/employees/legal-with-package',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeForIT
        tab={'Legal'}
        params={{...params, ...filterParams}}
        search={searchValue}
        filterValues={filterValues}
        addOnFilter={filter}
        api="/employees/legal-with-package"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Status',
            options: ['Active', 'Inactive'],
            placeholder: 'Select',
            field: 'email_activation_status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
        ]}
      />,
    ],
  };
  tabs.closed = {
    label: 'Closed',
    api: '/employees/closed-email-activation',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    eventSourceId: 'EmployeeAssignment',
    view: (
      <EmployeeForIT
        tab={'Closed'}
        params={{...params, ...filterParams}}
        search={searchValue}
        filterValues={filterValues}
        addOnFilter={filter}
        api="/employees/closed-email-activation"
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,

      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Status',
            options: ['Active', 'Inactive'],
            placeholder: 'Select',
            field: 'email_activation_status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
        ]}
      />,
    ],
  };
  // tabs.all = {
  //   label: 'All',
  //   api: '/employees/all-email-activation',
  //   params: {
  //     ...params,
  //   },
  //   search: searchValue,
  //   searchFields: ['name', 'employee_code'],
  //   addOnFilter: filter,
  //   limit: 5000,
  //   eventSourceId: 'EmployeeAssignment',
  //   view: (
  //     <EmployeeForIT
  //       tab={'Closed'}
  //       params={{...params, ...filterParams}}
  //       search={searchValue}
  //       addOnFilter={filter}
  //       api="/employees/all-email-activation"
  //     />
  //   ),
  //   actions: [
  //     <SearchFilter
  //       value={searchValue}
  //       onChangeFilter={onChangeFilter}
  //       placeholder="Search"
  //     />,
  //     <GroupFilter
  //       filterValues={filterValues}
  //       applyFilter={applyFilter}
  //       filters={[
  //         {
  //           type: 'autoComplete',
  //           label: 'Department',
  //           // api: '/activeDepartmentSuggestions',
  //           options: [
  //             {label: 'Active', value: 'Active'},
  //             {label: 'Inactive', value: 'Inactive'},
  //           ],
  //           placeholder: 'Select',
  //           field: 'email_activation_status',
  //         },
  //       ]}
  //     />,
  //   ],
  // };
  return <TabView tabs={tabs} {...props} />;
};
