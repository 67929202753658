import {
  ActivityIndicator,
  Row,
  Text,
  View,
} from '@unthinkable/react-core-components';
import {useFilter} from '@unthinkable/react-filters';
import {useTheme} from '@unthinkable/react-theme';
import moment from 'moment';
import {useState} from 'react';
import {
  UserAvatar,
  UserAvatarChip,
} from '../../../components/avatar/UserAvatar';
import {MultiColorTag} from '../../../components/chip/MultiColorChip';
import {GroupFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {renderType} from '../../department/views/TeamAttendanceRequestTable';
import {
  Timesheet,
  TimesheetRequest,
} from '../../department/views/TimesheetTable';
import {
  UserAvatarWithDateChip,
  UserAvatarWithNameAndEmail,
} from '../../reimbursement/components/ReimbursementColumnRenders';

const RenderStatusLeaveRequest = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'leaveManagement',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  if (row.status) {
    return;
  }
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/requests/${row._id}`,
                props: {
                  status: 'approve',
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/requests/${row._id}`,
                props: {
                  status: 'reject',
                },
              });
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </>
      )}
    </View>
  );
};

const RenderStatusAttendanceRequest = ({row = {}, styles = {}, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'teamAttendance',
    close: false,
    setLoading,
  });
  let {first_attendance_type_id, second_attendance_type_id} = row;
  let {rowText = {}} = styles;
  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flexDirection: 'row'}}>
          {first_attendance_type_id?.name === 'EWD' ||
          second_attendance_type_id.name === 'EWD' ? (
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                navigation.navigate(`ewd-form`, {
                  row,
                });
              }}>
              <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
            </View>
          ) : (
            <View
              style={{
                padding: 4,
                alignItem: 'center',
                textAlign: 'center',
              }}
              onPress={() => {
                invoke({
                  uri: `/dailyattendances/${row?._id}`,
                  props: {
                    status: 'approve',
                    is_approved: true,
                    __v: 0,
                  },
                });
              }}>
              <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
            </View>
          )}

          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              navigation.navigate('reject-team-request', {row});
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </View>
      )}
    </>
  );
};

const RenderStatusTimesheet = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'pendingTimesheetteamreq',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/timesheets/${row._id}`,
                props: {
                  status: 'Log Approved',
                  approved_by_manager: true,
                  approved_on: new Date(),
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/timesheets/${row._id}`,
                props: {
                  status: 'Log Rejected',
                  rejected_on: new Date(),
                  rejected_by_manager: true,
                },
              });
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </>
      )}
    </View>
  );
};

const RenderStatusWFHRequest = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'wfhRequest',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/wfhrequests/${row._id}`,
                props: {
                  status: 'approve',
                  is_approve: true,
                  is_hr: true,
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/wfhrequests/${row._id}`,
                props: {
                  status: 'reject',
                  is_approve: true,
                },
              });
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </>
      )}
    </View>
  );
};
const RenderStatusTourRequest = ({row = {}, styles = {}}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'tourRequest',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <View style={{flexDirection: 'row'}}>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/tourrequests/${row._id}`,
                props: {
                  status: 'approve',
                  is_approve: true,
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/tourrequests/${row._id}`,
                props: {
                  status: 'reject',
                  is_approve: true,
                },
              });
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </>
      )}
    </View>
  );
};

const RenderStatus = ({row, styles}) => {
  let {status} = row;
  let background = '';
  let updateColor = {color: '#34C759'};
  if (status === 'Log Approved' || status === 'approve') {
    background = '#F0FFF4';
    updateColor['color'] = '#34C759';
  } else if (status === 'Log Rejected' || status === 'reject') {
    background = '#FFF1F0';
    updateColor['color'] = '#FF5247';
  }
  return (
    <View
      style={{
        backgroundColor: background,
        padding: 6,
        alignItem: 'center',
        textAlign: 'center',
      }}>
      <Text style={{...styles.rowText, ...updateColor}}>{status}</Text>
    </View>
  );
};

export const EmployeeLeaveRequestTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
  } = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'leaveManagement',
  });

  return (
    <Table
      api={'/leaverequest/all'}
      params={params}
      eventSourceId={'leaveManagement'}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.requested_on).format('DD MMM YY')}
              </Text>
            );
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Duration',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <View style={{flexDirection: 'row'}}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {moment(row?.from_date).format('DD MMM YY')}
                </Text>
                <Text>-</Text>
                <Text style={{...rowText, marginLeft: 3, color: '#737373'}}>
                  {moment(row?.to_date).format('DD MMM YY')}
                </Text>
              </View>
            );
          },
          width: 180,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          width: 120,
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          width: 120,
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 140,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'center',
          render: ({row, styles}) => (
            <RenderStatusLeaveRequest row={row} styles={styles} />
          ),
          width: 160,
          visible: () => {
            if (tab === 'pending') return true;
          },
        },
        {
          header: 'Status',
          align: 'center',
          width: 160,
          field: 'status',
          render: ({row, styles}) => {
            return <RenderStatus row={row} styles={styles} />;
          },
          visible: () => {
            if (tab === 'processed') return true;
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Reject',
          confirm: {
            title: 'Mark Reject',
            message: 'Are you sure you want to reject this request ?',
            confirmText: 'Confirm',
          },
          onPress: ({row}) => {
            invoke({
              uri: `/requests/${row?._id}`,
              props: {
                status: 'reject',
              },
            });
          },
        },
      ]}
    />
  );
};

export const EmployeeWFHRequestTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
  } = props;

  return (
    <Table
      api={'/wfhrequest/all'}
      params={params}
      eventSourceId={'wfhRequest'}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Duration',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <View style={{flexDirection: 'row'}}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {moment(row?.from_date).format('DD MMM YY')}
                </Text>
                <Text>-</Text>
                <Text style={{...rowText, marginLeft: 3, color: '#737373'}}>
                  {moment(row?.to_date).format('DD MMM YY')}
                </Text>
              </View>
            );
          },
          width: 180,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
          width: 120,
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
          width: 120,
        },
        {
          header: 'Approver',
          width: 140,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'center',
          render: ({row, styles}) => (
            <RenderStatusWFHRequest row={row} styles={styles} />
          ),
          visible: () => {
            if (tab === 'pending') return true;
          },
          width: 200,
        },
        {
          header: 'Status',
          align: 'center',
          width: 200,
          field: 'status',
          render: ({row, styles}) => {
            return <RenderStatus row={row} styles={styles} />;
          },
          visible: () => {
            if (tab === 'processed') return true;
          },
        },
      ]}
    />
  );
};

export const EmployeeAttendanceRequestTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
  } = props;
  return (
    <Table
      api={'/attendancerequest/all'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
          width: 160,
        },
        {
          header: 'Employee',
          minWidth: 200,
          type: 'text',
          render: ({row, styles}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Date',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText}}>
                {moment(row?.attendance_date).format('DD MMM YY')}
              </Text>
            );
          },
          width: 120,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          width: 120,
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          width: 120,
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 140,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.reporting_to?.[0]?.name,
                  color: row?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'center',
          width: 180,
          render: ({row, styles}) => (
            <RenderStatusAttendanceRequest
              row={row}
              styles={styles}
              navigation={navigation}
            />
          ),
          visible: () => {
            if (tab === 'pending') return true;
          },
        },
        {
          header: 'Status',
          align: 'center',
          width: 200,
          field: 'status',
          render: ({row, styles}) => {
            return <RenderStatus row={row} styles={styles} />;
          },
          visible: () => {
            if (tab === 'processed') return true;
          },
        },
      ]}
    />
  );
};

export const EmployeeEWDRequestTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
  } = props;
  return (
    <Table
      api={'/attendancerequest/all'}
      params={params}
      eventSourceId={'teamAttendance'}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 120,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          minWidth: 200,
          type: 'text',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Date',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <Text style={{...rowText}}>
                {moment(row?.attendance_date).format('DD MMM YY')}
              </Text>
            );
          },
          width: 150,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          width: 120,
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          width: 120,
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
        },
        {
          header: 'Approver',
          width: 140,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.reporting_to?.[0]?.name,
                  color: row?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'center',
          width: 150,
          render: ({row, styles}) => (
            <RenderStatusAttendanceRequest
              row={row}
              styles={styles}
              navigation={navigation}
            />
          ),
          visible: () => {
            if (tab === 'pending') return true;
          },
        },
        {
          header: 'Status',
          align: 'center',
          width: 200,
          field: 'status',
          render: ({row, styles}) => {
            return <RenderStatus row={row} styles={styles} />;
          },
          visible: () => {
            if (tab === 'processed') return true;
          },
        },
      ]}
    />
  );
};

export const EmployeeTimesheetRequests = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
  } = props;
  return (
    <Table
      api={'/timesheets/all-requests'}
      params={params}
      eventSourceId={'pendingTimesheetteamreq'}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          width: 120,
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {row?.request_date &&
                  moment(row?.request_date).format('DD MMM YY')}
              </Text>
            );
          },
        },
        {
          header: 'Employee',
          minWidth: 200,
          type: 'text',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Duration',
          type: 'date',
          width: 200,
          render: ({row, styles}) => {
            const colors = useTheme('colors');
            let {rowText} = styles;
            return (
              <View
                style={{
                  flexDirection: 'row',
                  alignItem: 'center',
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {row?.date && moment(row?.date).format('DD MMM YY')}
                </Text>
              </View>
            );
          },
        },
        {
          header: 'Approver',
          width: 140,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        // {
        //   header: 'Status',
        //   field: 'status',
        //   align: 'center',
        //   render: ,
        //   width: 200,
        // },
        {
          header: 'Actions',
          align: 'right',
          width: 200,
          render: ({row, styles}) => (
            <RenderStatusTimesheet row={row} styles={styles} />
          ),
          visible: () => {
            if (tab === 'pending') {
              return true;
            }
          },
        },
        {
          header: 'Status',
          field: 'status',
          align: 'center',
          visible: () => {
            if (tab === 'processed') {
              return true;
            }
          },
          render: ({row, styles}) => {
            return <RenderStatus row={row} styles={styles} />;
          },
          width: 200,
        },
      ]}
    />
  );
};

export const EmployeeTimesheetTable = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
  } = props;
  return (
    <Table
      api={'/timesheets/all-requests'}
      addOnFilter={addOnFilter}
      eventSourceId={['nonProjectTimesheet', 'projectTimesheet']}
      columns={[
        {
          header: 'Employee',
          width: 400,
          type: 'text',
          render: ({row, styles}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
        },
        {
          header: 'Date',
          field: 'date',
          type: 'date',
          width: 200,
        },
        {
          header: 'Projects',
          width: 250,
          render: ({row}) => {
            return (
              <MultiColorTag
                value={row.project}
                labelField={'project'}
                colorField={'color'}
                titleField={'project'}
              />
            );
          },
        },
        {
          header: 'Timesheet',
          width: 400,
          render: props => {
            return (
              <Timesheet {...props} params={params} navigation={navigation} />
            );
          },
        },
      ]}
      // moreActions={[
      //   {
      //     title: 'Add More Progress',
      //     onPress: ({row}) => {
      //       navigation.navigate('project-timesheet-progress', {
      //         ...params,
      //         row,
      //       });
      //     },
      //   },
      // ]}
      params={params}
    />
  );
};

export const EmployeeTourRequestTable = props => {
  let {
    navigation,
    route: {params},
    addOnFilter,
    tab,
  } = props;

  return (
    <Table
      api={'/tourrequest/all'}
      params={params}
      eventSourceId={'tourRequest'}
      addOnFilter={addOnFilter}
      columns={[
        {
          header: 'Requested On',
          type: 'date',
          render: ({row, styles: {rowText}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  padding: 3,
                  marginLeft: 2,
                  color: '#A3A3A3',
                }}>
                {moment(row?.createdAt).format('DD MMM YY')}
              </Text>
            );
          },
          width: 120,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee?.name}
                color={row?.employee?.color}
                official_email_id={row?.employee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Duration',
          type: 'date',
          render: ({row, styles}) => {
            let {rowText} = styles;
            return (
              <View style={{flexDirection: 'row'}}>
                <Text style={{...rowText, marginRight: 3, color: '#737373'}}>
                  {moment(row?.from_date).format('DD MMM YY')}
                </Text>
                <Text>-</Text>
                <Text style={{...rowText, marginLeft: 3, color: '#737373'}}>
                  {moment(row?.to_date).format('DD MMM YY')}
                </Text>
              </View>
            );
          },
          width: 180,
        },
        {
          header: 'First Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.first_attendance_type_id, styles: styles}),
          width: 120,
        },
        {
          header: 'Second Half',
          type: 'text',
          align: 'center',
          render: ({row, styles}) =>
            renderType({value: row.second_attendance_type_id, styles: styles}),
          width: 120,
        },
        {
          header: 'Approver',
          width: 140,
          render: ({row}) => {
            return (
              <UserAvatarChip
                value={{
                  name: row?.employee?.reporting_to?.[0]?.name,
                  color: row?.employee?.reporting_to?.[0]?.color,
                }}
              />
            );
          },
        },
        {
          header: 'Actions',
          align: 'center',
          render: ({row, styles}) => (
            <RenderStatusTourRequest row={row} styles={styles} />
          ),
          visible: () => {
            if (tab === 'pending') return true;
          },
          width: 200,
        },
        {
          header: 'Status',
          align: 'center',
          width: 200,
          field: 'status',
          render: ({row, styles}) => {
            return <RenderStatus row={row} styles={styles} />;
          },
          visible: () => {
            if (tab === 'processed') return true;
          },
        },
      ]}
    />
  );
};
