import React from 'react';
import {TabView} from '../../../components/tab';
import {
  ActiveCandidate,
  MyCandidateClosedFeedbacks,
  MyCandidateFeedbacks,
} from './CandidateTable';
import {useAppStateContext} from '../../../providers/AppState';

export const MyCandidateFeedbackTabs = props => {
  const {user} = useAppStateContext();
  const candidateFeedbackTabs = {
    open: {
      label: 'Pending',
      eventSourceId: 'candidate',
      view: (
        <MyCandidateFeedbacks
          api="/candidates"
          addOnFilter={{
            $or: [
              {
                'first_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'first_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'second_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'second_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'third_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'third_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'fourth_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'fourth_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'hr_round_interview_feedback.status': {
                  $in: ['Active', 'Interview'],
                },
                'hr_round_interview_feedback.interviewer': user?.employee?._id,
              },
            ],
          }}
        />
      ),
      api: '/candidates',
      addOnFilter: {
        $or: [
          {
            'first_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'first_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'second_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'second_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'third_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'third_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'fourth_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'fourth_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'hr_round_interview_feedback.status': {
              $in: ['Active', 'Interview'],
            },
            'hr_round_interview_feedback.interviewer': user?.employee?._id,
          },
        ],
      },
    },
    closed: {
      label: 'Closed',
      eventSourceId: 'candidate',
      view: (
        <MyCandidateClosedFeedbacks
          api="/candidates"
          addOnFilter={{
            $or: [
              {
                'first_round_interview_feedback.status': 'Completed',
                'first_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'second_round_interview_feedback.status': 'Completed',
                'second_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'third_round_interview_feedback.status': 'Completed',
                'third_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'fourth_round_interview_feedback.status': 'Completed',
                'fourth_round_interview_feedback.interviewer':
                  user?.employee?._id,
              },
              {
                'hr_round_interview_feedback.status': 'Completed',
                'hr_round_interview_feedback.interviewer': user?.employee?._id,
              },
            ],
          }}
        />
      ),
      api: '/candidates',
      addOnFilter: {
        $or: [
          {
            'first_round_interview_feedback.status': 'Completed',
            'first_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'second_round_interview_feedback.status': 'Completed',
            'second_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'third_round_interview_feedback.status': 'Completed',
            'third_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'fourth_round_interview_feedback.status': 'Completed',
            'fourth_round_interview_feedback.interviewer': user?.employee?._id,
          },
          {
            'hr_round_interview_feedback.status': 'Completed',
            'hr_round_interview_feedback.interviewer': user?.employee?._id,
          },
        ],
      },
    },
  };
  return <TabView {...props} tabs={candidateFeedbackTabs} isLinkTab={false} />;
};
