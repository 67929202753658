import React from 'react';
import {Table} from '../../../components/table/Table';
import {TableHeader} from '../../../components/table/Headers';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import { Durations } from '../../order/views/components/Durations';

const PROJECTANDCUSTOMER = {
  header: 'Project',
  //   align: 'center',
  //   width: 150,
  // width: 300,
  render: ({row}) => {
    return (
      <PrimaryColumnCard
        primaryTitle={row?.project?.project}
        items={[
          {
            value: row?.customer?.name,
          },
        ]}
      />
    );
  },
};

export const ProjectAuditTable = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {row = {}} = params;
  return (
    <Table
      {...props}
      params={params}
      api={`/projectaudits`}
      filter={{project: row?._id}}
      eventSourceId={'performance-report'}
      renderHeader={() => {
        return (
          <TableHeader title="Project Audits" secondaryTitle={row?.project} />
        );
      }}
      fields={{
        auditer: {
          name: 1,
          color: 1,
        },
        audit_comments: 1,
        audit_date: 1,
        next_audit_date: 1,
        total_score: 1,
      }}
      sort={{plan_project_audit: -1}}
      onRowPress={({row}) => {
        navigation.navigate('update-project-audit-plan', {
          row,
          fromHistory: true,
        });
      }}
      columns={[
        {
          field: 'auditer',
          type: 'userAvatarChip',
          header: 'Auditer',
        },
        {
          field: 'audit_date',
          type: 'date',
          header: 'Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'next_audit_date',
          type: 'date',
          header: 'Next Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'total_score',
          type: 'number',
          header: 'Score',
        },
        {
          field: 'audit_comments',
          type: 'text',
          header: 'Comments',
        },
      ]}
    />
  );
};

export const ProjectAuditTableForKpi = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {period = {}} = params;
  const addOnFilter = {
    audit_date: {
      $gte: period.from,
      $lte: period.to,
    },
  };
  return (
    <Table
      {...props}
      params={{...params}}
      api={`/projectaudits`}
      eventSourceId={'performance-report'}
      renderHeader={() => {
        return <TableHeader title="Project Audits" />;
      }}
      fields={{
        auditer: {
          name: 1,
          color: 1,
        },
        audit_comments: 1,
        audit_date: 1,
        next_audit_date: 1,
        total_score: 1,
        project: {project: 1},
      }}
      addOnFilter={addOnFilter}
      sort={{plan_project_audit: -1}}
      // onRowPress={({row}) => {
      //   navigation.navigate('update-project-audit-plan', {
      //     row,
      //     fromHistory: true,
      //   });
      // }}
      columns={[
        {
          field: 'project.project',
          type: 'colortag',
          header: 'Project',
        },
        {
          field: 'auditer',
          type: 'userAvatarChip',
          header: 'Auditer',
        },
        {
          field: 'audit_date',
          type: 'date',
          header: 'Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'next_audit_date',
          type: 'date',
          header: 'Next Audit',
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          field: 'total_score',
          type: 'number',
          header: 'Score',
        },
        {
          field: 'audit_comments',
          type: 'text',
          header: 'Comments',
        },
      ]}
    />
  );
};

export const ProjectCSATTableForKpi = props => {
  const {
    route: {params = {}},
    navigation,
  } = props;
  const {period = {}} = params;
  const addOnFilter = {
    client_feedback_from_date: {$gte: period.from},
    client_feedback_to_date: {$lte: period.to},
  };
  return (
    <Table
      {...props}
      params={{...params}}
      api={`/clientfeedbacks`}
      eventSourceId={'performance-report'}
      renderHeader={() => {
        return <TableHeader title="Project CSAT" />;
      }}
      fields={{
        project: {project: 1},
        customer: {name: 1},
        client_score: 1,
        client_feedback_from_date: 1,
        client_feedback_to_date: 1,
        rating: 1,
        message: 1,
        description: 1,
      }}
      addOnFilter={addOnFilter}
      sort={{client_feedback_from_date: -1}}
      // onRowPress={({row}) => {
      //   navigation.navigate('update-project-audit-plan', {
      //     row,
      //     fromHistory: true,
      //   });
      // }}
      columns={[
        PROJECTANDCUSTOMER,
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.client_feedback_to_date,
              fromDate: row?.client_feedback_from_date,
            });
          },
          width: 250,
        },
        {
          field: 'client_score',
          type: 'number',
          header: 'Score',
        },
        // {
        //   field: 'rating',
        //   type: 'text',
        //   header: 'Rating',
        // },
        {
          field: 'description',
          type: 'text',
          header: 'Messagge',
        },
      ]}
    />
  );
};
