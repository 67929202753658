import React from 'react';
import { Table } from '../../../components/table/Table';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';

export const DomainTable = () => {
  const navigation = useNavigation();

  return (
    <Table
      eventSourceId={["domain"]}
      api={`/domains`}
      fields={{
        domain: 1,
        is_domain: 1,
        parent: {
          domain: 1,
        },
        index: 1,
      }}
      sort={{index: 1}}
      renderHeader={() => (
        <TableHeader
          title="Domain"
          actions={[
            <AddButton
              title="Add Domain"
              view="add-domain"
            />,
          ]}
        />
      )}
      onRowPress={({ row }) => {
        navigation.navigate('edit-domain', { row });
      }}
      columns={[
        {
          type: 'text',
          field: 'domain',
          header: 'Domain',
        },
        {
          type: 'text',
          field: 'parent.domain',
          header: 'Parent',
        },
        {
          type: 'boolean',
          field: 'is_domain',
          header: 'Domain Module',
          width: 200,
        },
        {
          type: 'number',
          field: 'index',
          header: 'Index'
        }
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate('edit-domain', { row });
          },
        },
      ]}
    />
  );
};
