import React from 'react';
import {
  AllITAssetTab,
  DeletedITAssetTab,
  NewITAssetTable,
} from './view/NewITAssetTable';
import {RegisterITAssetsTab} from './view/RegisterITAssetTable';
import {ActiveITAssetTab, RepairHistoryTab} from './view/ActiveITAsset';
import {InActiveITAssetTab} from './view/InActiveITAsset';
import {MyITAssetTab} from './view/MyITAsset';
import { SubscriptionCategoryTable } from './view/SubscriptionPlannedTable';

export const ScreenMapping = [
  {
    id: 'itassets',
    view: <RegisterITAssetsTab />,
  },
  {
    id: 'allasset',
    view: props => {
      return <AllITAssetTab {...props} />;
    },
  },
  {
    id: 'assetinventory',
    view: props => {
      return <DeletedITAssetTab {...props} />;
    },
  },
  {
    id: 'new',
    view: props => {
      return <NewITAssetTable {...props} />;
    },
  },
  {
    id: 'active',
    view: props => {
      return <ActiveITAssetTab {...props} />;
    },
  },
  {
    id: 'inactive',
    view: props => {
      return <InActiveITAssetTab {...props} />;
    },
  },
  {
    id: 'myasset',
    view: props => {
      return <MyITAssetTab {...props} />;
    },
  },
  {
    id: 'repairhistory',
    view: props => {
      return <RepairHistoryTab {...props} />;
    },
  },
  {
    id: 'subscriptioncategory',
    view: props => {
      return <SubscriptionCategoryTable {...props} />;
    },
  },
];

// export const Screens = [
//   {
//     menuItemId: 'registeritassets',
//     views: [
//       {
//         id: 'itassets',
//         label: 'Register IT Assets',
//         view: <RegisterITAssetsTab />,
//       },
//     ],
//   },
//   {
//     menuItemId: 'allassets',
//     views: [
//       {
//         id: 'allasset',
//         label: 'All Asset',
//         view: props => {
//           return <AllITAssetTab {...props} />;
//         },
//       },
//       {
//         id: 'assetinventory',
//         label: 'Deleted',
//         view: props => {
//           return <DeletedITAssetTab {...props} />;
//         },
//       },
//     ],
//   },
//   {
//     menuItemId: 'itassets',
//     views: [
//       {
//         id: 'new',
//         label: 'New',
//         view: props => {
//           return <NewITAssetTable {...props} />;
//         },
//       },
//       {
//         id: 'active',
//         label: 'Active',
//         view: props => {
//           return <ActiveITAssetTab {...props} />;
//         },
//       },
//       {
//         id: 'inactive',
//         label: 'Inactive',
//         view: props => {
//           return <InActiveITAssetTab {...props} />;
//         },
//       },
//     ],
//   },
//   // {
//   //   menuItemId: 'AssetRecovery',
//   //   views: [],
//   // },
//   {
//     menuItemId: 'myassets',
//     views: [
//       {
//         id: 'myasset',
//         label: 'My Assets',
//         view: props => {
//           return <MyITAssetTab {...props} />;
//         },
//       },
//     ],
//   },
// ];

// export const MenuItems = [
//   {
//     id: 'registeritassets',
//     label: 'Register IT Assets',
//   },
//   {
//     id: 'allassets',
//     label: 'All Asset',
//   },
//   {
//     id: 'itassets',
//     label: 'IT Asset',
//   },
//   // {
//   //   id: 'AssetRecovery',
//   //   label: 'Asset Recovery',
//   // },
//   {
//     id: 'myassets',
//     label: 'My Assets',
//   },
// ];
