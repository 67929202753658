import React from 'react';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {Form, InlineForm} from '../../../components/form/Form';
import {
  CASCADER_NAVIGATE_TO_ADD_VIEW,
  PROJECT_TASK_TYPE_ID,
} from '../../common/constants/SourceConstants';
import NestedTable from '../../../components/form/NestedTable';
import {MarkCompleted} from './MarkTaskCompleteForm';
import {TabView} from '../../../components/tab';
import {FormCommentTable} from '../../pmt/views/CommentTable';
import {ActivityLogTable} from '../../pmt/views/ActivityLogTable';
import {
  getLastWorkingDayOfMonth,
  getLastWorkingDayOfWeek,
} from '@unthinkable/react-date-picker';
import {AllReviews} from './TaskReview';
import {SubmitButton} from '@unthinkable/react-form';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {OutlineButtonStyle} from '../../../components/button/theme';
import {View} from '@unthinkable/react-core-components';
import {useAppStateContext} from '../../../providers/AppState';

const AcceptanceCriteriaRender = props => {
  const {
    value,
    form: {setFieldValue},
    readOnly,
  } = props;
  return (
    <NestedTable
      {...props}
      editFormHeader={'Checklist Item Detail'}
      inlineAddForm
      modalProps={{
        size: 'small',
        width: 600,
        minHeight: '30%',
      }}
      fields={[
        {
          type: 'textArea',
          field: 'name',
          placeholder: 'Checklist Item',
        },
      ]}
      columns={[
        {
          render: props => {
            return (
              <MarkCompleted
                {...props}
                disabled={readOnly}
                onPress={({index}) => {
                  if (value[index]?._id) {
                    value[index] = {
                      ...value[index],
                      is_completed: !value[index]?.is_completed,
                    };
                    setFieldValue('acceptance_criteria', value);
                  }
                }}
              />
            );
          },
          width: 36,
        },
        {
          type: 'text',
          field: 'name',
          header: 'Checklist Item',
          numberOfLines: 3,
        },
      ]}
    />
  );
};

const outputField = ({params, navigation}) => ({
  label: 'Output',
  field: 'source',
  type: 'cascader',
  changeOnSelect: true,
  cascades: [
    {
      label: 'Output',
      api: '/types',
      field: 'source',
      fields: {name: 1, label: 1, list_view: 1, add_view: 1},
      suggestionField: 'label',
      valueField: 'label',
    },
    {
      label: 'Source Value',
      field: 'source_id',
      api: '/sourceSuggestions',
      suggestionField: 'name',
      valueField: 'name',
      params: ({values}) => {
        const {source} = values || {};
        return {
          source,
        };
      },
      onCreate: CASCADER_NAVIGATE_TO_ADD_VIEW({
        navigation,
        params,
      }),
    },
  ],
});

const assignedToField = ({params}) => {
  return {
    label: 'Assignee',
    field: 'assigned_to',
    type: 'autoComplete',
    api: `/usersuggestions`,
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    colorField: 'color',
    chip: true,
    valueField: 'name',
    params: {project_id: params?.project_id || params?.project?._id},
    validate: (value, {values} = {}) => {
      let {reviewer} = values;
      if (reviewer && !Array.isArray(reviewer)) {
        reviewer = [reviewer];
      }
      if (value?._id && reviewer?.length) {
        let result = reviewer?.find(item => {
          return item?._id === value?._id;
        });
        if (result) {
          return 'Assignee and reviewer must be different';
        }
      }
    },
  };
};

const teamField = ({params} = {}) => {
  const {employee_team} = params || {};
  const employeeTeamId = employee_team?._id || employee_team;
  return {
    label: 'Assignees',
    field: 'team',
    type: 'multiAutoComplete',
    api: employeeTeamId
      ? `/employeeteams/${employeeTeamId}/userSuggestions`
      : `/usersuggestions`,
    filter: props => {
      const {values = {}} = props || {};
      const {employee_team} = values;
      if (employee_team) {
        return {employee_team: employee_team?._id};
      }
    },
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    colorField: 'color',
    avatar: true,
    valueField: 'name',
    required: true,
    validate: (value, {values} = {}) => {
      const {reviewer} = values;
      if (value?.length && reviewer?.length) {
        const result = value?.find(item => {
          if (item._id) {
            return reviewer.find(reviewerItem => reviewerItem._id === item._id);
          }
        });
        if (result) {
          return 'Assignee and reviewer must be different';
        }
      }
    },
  };
};

const reviewerField = ({params}) => {
  return {
    label: 'Reviewers',
    field: 'reviewer',
    type: 'multiAutoComplete',
    api: `/usersuggestions`,
    suggestionField: 'name',
    secondarySuggestionField: 'email',
    colorField: 'color',
    avatar: true,
    valueField: 'name',
    params: {project_id: params?.project_id || params?.project?._id},
    validate: (value, {values} = {}) => {
      const {assigned_to, team} = values;
      if (value?.length) {
        const result = value?.find(item => {
          if (item?._id) {
            if (assigned_to?._id) {
              return item?._id === assigned_to._id;
            } else if (team?.length) {
              return team.find(teamItem => teamItem?._id === item?._id);
            }
          }
        });
        if (result) {
          return 'Assignee and reviewer must be different';
        }
      }
    },
  };
};

export const TaskForm = ({
  defaultValues,
  navigation,
  route: {params},
  reviewerMandatory,
  ...props
}) => {
  let {onSubmit} = useFormSubmit({
    uri: '/projectTasks',
    eventSourceId: 'Task',
    ...props,
  });
  const {
    row,
    product,
    department,
    source,
    source_id,
    parentSourceView,
    employee_team,
  } = params;
  return (
    <Form
      header="Add Task"
      onSubmit={onSubmit}
      api={`/projecttasks/${row?._id}`}
      defaultValues={{
        type: 'Task',
        status: 'backlog',
        source,
        source_id,
        product: product,
        department: department,
        employee_team,
        ...defaultValues,
      }}
      fields={{
        name: 1,
        description: 1,
        est_hours: 1,
        assigned_to: {
          name: 1,
          color: 1,
        },
        due_date: 1,
        source: {name: 1, label: 1},
        source_id: {name: 1},
        skill_metrics: {name: 1},
        completed_on: 1,
        reviewer: {name: 1, color: 1},
        acceptance_criteria: 1,
        status: 1,
        product: {
          name: 1,
        },
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Task',
          field: 'name',
          type: 'textArea',
          required: true,
        },
        {
          ...assignedToField({params}),
        },
        {
          label: 'Estimate Hours',
          field: 'est_hours',
          type: 'duration',
          readOnly: parentSourceView === 'OpenTask',
        },
        {
          ...reviewerField({params}),
          required: !!reviewerMandatory,
        },

        {
          groups: [
            {
              label: 'Output',
              fields: [
                {
                  ...outputField({params, navigation}),
                  visible: !source_id,
                },
              ],
            },
            {
              label: 'Due On',
              fields: [
                {
                  placeholder: 'Due On',
                  field: 'due_date',
                  type: 'date',
                  width: '50%',
                },
              ],
            },
            {
              label: 'Completed On',
              visible: row?.status === 'completed',
              fields: [
                {
                  placeholder: 'Completed On',
                  field: 'completed_on',
                  type: 'date',
                  width: '50%',
                },
              ],
            },
            {
              label: 'Skill',
              fields: [
                {
                  placeholder: 'Select Skill',
                  type: 'autoComplete',
                  field: 'skill_metrics',
                  api: '/skillMetrics',
                  searchField: 'name',
                  suggestionField: 'name',
                  width: '50%',
                },
              ],
            },
            {
              visible: !product,
              label: 'Product',
              fields: [
                {
                  placeholder: 'Select Product',
                  field: 'product',
                  type: 'autoComplete',
                  api: `/products`,
                  suggestionField: 'name',
                  valueField: 'name',
                  width: '50%',
                },
              ],
            },
            {
              label: 'Description',
              fields: [
                {
                  placeholder: 'Write description...',
                  field: 'description',
                  type: 'textArea',
                  inputProps: {
                    minRows: 2,
                    maxRows: 5,
                  },
                },
              ],
            },
            {
              label: 'Checklist',
              fields: [
                {
                  field: 'acceptance_criteria',
                  nested: true,
                  render: AcceptanceCriteriaRender,
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditTaskForm = props => {
  return <TaskForm header={'Edit Task'} mode="edit" {...props} />;
};

export const AddAdHocTeamTaskForm = props => {
  const {
    route: {params},
  } = props;
  let {onSubmit} = useFormSubmit({
    uri: '/createAdHocTask',
    eventSourceId: ['Task'],
    ...props,
  });
  const {product, department, employee_team, parent_task, status} = params;

  return (
    <Form
      header={{title: 'Add Task', secondaryTitle: parent_task?.name}}
      onSubmit={onSubmit}
      defaultValues={{
        type: 'Task',
        is_ad_hoc: true,
        status: status || 'backlog',
        product,
        department,
        employee_team,
        parent_task: parent_task?._id,
      }}
      submitAction={'Save'}
      layoutFields={[
        {
          label: 'Task',
          field: 'name',
          type: 'textArea',
          required: true,
        },
        teamField({params}),
        {
          label: 'Est Hrs',
          field: 'est_hours',
          type: 'duration',
        },
        {
          ...reviewerField({params}),
          required: true,
        },
        {
          groups: [
            {
              label: 'Due Date',
              fields: [
                {
                  placeholder: 'Due Date',
                  field: 'due_date',
                  type: 'date',
                  width: '50%',
                },
              ],
            },
            {
              label: 'Description',
              fields: [
                {
                  placeholder: 'Write description...',
                  field: 'description',
                  type: 'textArea',
                  inputProps: {
                    minRows: 2,
                    maxRows: 5,
                  },
                },
              ],
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const OrganizationTaskInlineForm = ({
  navigation,
  params,
  defaultValues,
  onSubmit,
  ...props
}) => {
  const {
    department,
    product,
    organization,
    source,
    source_id,
    parent_task,
    thread = {},
    delegatedTask,
    userTeam,
  } = params || {};

  const {user} = useAppStateContext();

  let {onSubmit: onDefaultSubmit} = useFormSubmit({
    uri: '/projectTasks',
    eventSourceId: 'Task',
  });

  return (
    <InlineForm
      {...props}
      onSubmit={onSubmit || onDefaultSubmit}
      defaultValues={{
        department: department?._id,
        product: product?._id,
        organization: organization?._id,
        type: 'Task',
        source,
        source_id,
        parent_task: parent_task?._id,
        ...defaultValues,
      }}
      layoutFields={[
        {
          label: 'Task',
          field: 'name',
          type: 'textArea',
          required: true,
        },
        !params?.thread?._id && {
          label: 'Thread',
          field: 'thread',
          type: 'autoComplete',
          width: 240,
          api: '/threads',
          suggestionField: 'name',
          valueField: 'name',
          filter: {
            status: {$in: ['Active', 'Plan']},
          },
        },
        userTeam
          ? {
              label: 'Employee Team',
              field: 'employee_team',
              type: 'autoComplete',
              width: 250,
              api: '/employeeteams',
              filter: {
                project: {$exists: false},
                team_type: 'Department',
                status: 'Active',
                $or: [
                  {performance_manager: user?.employee?._id},
                  {observers: user?._id},
                ],
              },
              suggestionField: 'name',
              secondarySuggestionField: 'name',
              valueField: 'name',
              required: true,
            }
          : void 0,
        {
          ...teamField({params}),
          required: false,
          width: 200,
        },
        {
          label: 'Est Hrs',
          field: 'est_hours',
          type: 'number',
          width: 100,
        },
        !thread?._id && !delegatedTask && !userTeam
          ? {
              ...reviewerField({params}),
              width: 200,
              required: false,
            }
          : void 0,
      ]}
    />
  );
};

export const AdhocTaskInlineForm = ({defaultValues, ...props}) => {
  let {onSubmit} = useFormSubmit({
    uri: '/createAdHocTask',
    eventSourceId: ['Task'],
  });

  return (
    <OrganizationTaskInlineForm
      {...props}
      onSubmit={onSubmit}
      defaultValues={{
        is_ad_hoc: true,
        ...defaultValues,
      }}
    />
  );
};
export const StrategicTaskInlineForm = ({defaultValues, ...props}) => {
  let {onSubmit} = useFormSubmit({
    uri: '/createAdHocTask',
    eventSourceId: ['Task'],
  });

  return (
    <OrganizationTaskInlineForm
      {...props}
      onSubmit={onSubmit}
      defaultValues={{
        is_strategic: true,
        ...defaultValues,
      }}
    />
  );
};

export const EditAdHocTaskForm = props => {
  return (
    <EditTaskForm
      {...props}
      header={'Edit Task'}
      mode="edit"
      isDualMode
      reviewerMandatory
    />
  );
};

export const EditStrategicTaskForm = props => {
  return (
    <EditTaskForm
      {...props}
      header={'Edit Task'}
      mode="edit"
      isDualMode
      reviewerMandatory
    />
  );
};

const OutputForm = ({row}) => {
  return (
    <Form
      initialValues={row}
      readOnly
      skipHeader
      layoutFields={[
        {
          field: 'output_link',
          type: 'link',
          label: 'Link',
          numberOfLines: 2,
        },
        {
          field: 'output_remark',
          type: 'textArea',
          label: 'Remark',
        },
        {
          field: 'output_attachment',
          type: 'file',
          label: 'Attachment',
          multiple: true,
        },
      ]}
    />
  );
};

const AddReviewForm = props => {
  const {params} = props;

  const {row = {}} = params;
  const style = useStyles(OutlineButtonStyle);
  const colors = useTheme('colors');
  const {onSubmit} = useFormSubmit({
    uri: `/taskReviews`,
    eventSourceId: 'TaskReview',
  });
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.OUTLINE,
        borderRadius: 12,
        marginBottom: 12,
        overflow: 'hidden',
      }}>
      <Form
        initialValues={{
          task_id: row?._id,
        }}
        skipHeader={true}
        onSubmit={onSubmit}
        beforeSubmit={props => {
          const {
            data,
            submitState: {variant},
          } = props;
          if (variant === 'success' && !data?.review_estimated_time) {
            throw new Error('Please provide estimated time');
          } else if (variant === 'success' && !data?.review_quality_standards) {
            throw new Error('Please provide quality standards');
          }

          return {
            data: {
              ...data,
              status: variant === 'success' ? 'accepted' : 'rejected',
            },
          };
        }}
        footer={{
          actions: [
            <SubmitButton styles={style} label="Reject" variant="error" />,
            <SubmitButton styles={style} label="Accept" variant="success" />,
          ],
        }}
        skipDefaultSave
        layoutFields={[
          [
            {
              field: 'review_estimated_time',
              type: 'rating',
              label: 'Estimated time',
              size: 6,
              starSize: 24,
            },
            {
              field: 'review_quality_standards',
              type: 'rating',
              label: 'Quality standards',
              size: 6,
              starSize: 24,
            },
          ],
          {
            size: 12,
            field: 'comment',
            type: 'textArea',
            label: 'Remarks',
            required: true,
            inputProps: {
              minRows: 2,
              maxRows: 5,
            },
          },
        ]}
      />
    </View>
  );
};

const TaskFormTabs = props => {
  const {navigation, params} = props;

  const {row = {}, project_id, addReviewEnabled} = params;
  if (!row._id) {
    return null;
  }

  let tabs = {};
  let selectedTab =
    row?.recent_review || addReviewEnabled ? 'reviews' : 'comments';
  if (row?.recent_review || row.status === 'completed' || addReviewEnabled) {
    tabs.reviews = {
      label: 'Reviews',
      view: (
        <>
          {addReviewEnabled ? <AddReviewForm {...props} /> : void 0}
          <AllReviews row={row} navigation={navigation} />
        </>
      ),
    };
  }
  tabs.comments = {
    label: 'Comments',
    view: (
      <FormCommentTable
        project={project_id}
        comment_source={{_id: PROJECT_TASK_TYPE_ID, label: 'Task'}}
        comment_source_id={{_id: row._id, name: row.name}}
      />
    ),
  };
  tabs.activityLogs = {
    label: 'Activity Logs',
    view: (
      <ActivityLogTable
        variant="none"
        api={`/tasks/${row?._id}/activities`}
        params={{
          excludeFields: ['parent_objects'],
          _id: row._id,
        }}
      />
    ),
  };
  return <TabView {...props} tabs={tabs} selectedTab={selectedTab} />;
};

export const ProjectTaskForm = props => {
  let {onSubmit} = useFormSubmit({
    uri: '/projecttasks',
    eventSourceId: 'Task',
    ...props,
  });
  const {
    route: {params},
    defaultValues: oldDefaultValues,
  } = props;

  const {row = {}, project, readOnly, showThread} = params;

  const defaultValues = {status: 'active', project, ...oldDefaultValues};

  return (
    <Form
      onSubmit={onSubmit}
      readOnly={readOnly}
      api={`/projecttasks/${row?._id}`}
      fields={{
        name: 1,
        project_id: {name: 1},
        assigned_to: {name: 1, color: 1},
        desc: 1,
        description: 1,
        due_date: 1,
        status: 1,
        est_hours: 1,
        acceptance_criteria: 1,
        reviewer: {name: 1, color: 1},
        completed_on: 1,
        master_task_assignment: {
          task: 1,
        },
        output_link: 1,
        output_attachment: 1,
        output_remark: 1,
        recent_review: {
          review_avg: 1,
          reviewed_by: {name: 1},
        },
        recent_review_status: 1,
        is_rework: 1,
        thread: {name: 1},
      }}
      defaultValues={defaultValues}
      layoutFields={[
        {
          label: 'Task',
          field: 'name',
          type: 'textArea',
          required: true,
        },
        showThread && {
          label: 'Thread',
          field: 'thread',
          type: 'autoComplete',
          api: '/threads',
          suggestionField: 'name',
          valueField: 'name',
          filter: {status: {$in: ['Active', 'Plan']}},
        },
        {
          label: 'Description',
          field: 'description',
          type: 'textArea',
        },
        [
          {
            ...assignedToField({params}),
            size: 3,
            required: row?.status === 'completed',
          },
          {
            label: 'Estimate Hours',
            field: 'est_hours',
            type: 'duration',
            size: 3,
            readOnly: true,
          },
          {
            label: 'Rework',
            field: 'is_rework',
            type: 'checkbox',
            size: 3,
          },
          {
            label: 'Completed On',
            field: 'completed_on',
            type: 'date',
            visible: row?.status === 'completed',
            size: 3,
          },
        ],
        {
          ...reviewerField({params}),
          size: 'medium',
          label: 'Reviewers',
        },
        {
          label: 'Source',
          field: 'source',
          type: 'cascader',
          cascades: [
            {
              label: 'Source',
              api: '/types',
              fields: {name: 1, display_field: 1},
              type: 'autoComplete',
              field: 'source',
              suggestionField: 'name',
              valueField: 'name',
              keyField: '_id',
            },
            {
              label: 'Source Value',
              field: 'source_id',
              api: '/sourceSuggestions',
              suggestionField: 'name',
              valueField: 'name',
              params: ({values}) => {
                const {source} = values || {};
                return {
                  source: source,
                };
              },
            },
          ],
          visible: ({values}) => !values.project_id,
        },
        {
          groups: [
            {
              label: 'Acceptance Checklist',
              field: 'acceptance_criteria',
              nested: true,
              render: AcceptanceCriteriaRender,
            },
            {
              label: 'Output',
              visible: ({values}) =>
                values.output_link ||
                values.output_attachment?.length ||
                values.output_remark,
              fields: [
                {
                  field: 'output_attachment',
                  type: 'file',
                  label: 'Attachment',
                  multiple: true,
                },
                {
                  field: 'output_link',
                  type: 'link',
                  label: 'Link',
                  numberOfLines: 2,
                },

                {
                  field: 'output_remark',
                  type: 'textArea',
                  label: 'Remark',
                },
              ],
            },
          ],
        },
        {
          render: (_, {values}) => {
            return (
              <TaskFormTabs
                {...props}
                params={{
                  project_id: values?.project_id || project?._id || project,
                  ...params,
                  row: values,
                }}
              />
            );
          },
        },
      ]}
      {...props}
    />
  );
};

export const EditProjectTaskForm = props => {
  return (
    <ProjectTaskForm
      isDualMode
      mode="edit"
      header={{title: 'Task Details'}}
      {...props}
    />
  );
};

export const MarkTaskActiveForm = props => {
  const {
    route: {params},
  } = props;

  const {row, fromMyView} = params || {};

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'Task',
    uri: '/projecttasks',
    ...props,
  });

  return (
    <Form
      mode={'edit'}
      header={{title: 'Mark Active', secondaryTitle: row?.name}}
      onSubmit={onSubmit}
      api={`/projecttasks/${row?._id}`}
      fields={{
        est_hours: 1,
        assigned_to: {
          name: 1,
          color: 1,
        },
        due_date: 1,
        due_plan: 1,
        plan_type: 1,
      }}
      submitAction={'Save'}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'active',
            plan_date:
              data?.plan_type === 'Date'
                ? data?.plan_date
                : data?.plan_type === 'This Week'
                ? getLastWorkingDayOfWeek()
                : data?.plan_type === 'This Month'
                ? getLastWorkingDayOfMonth()
                : void 0,
          },
        };
      }}
      layoutFields={[
        {
          ...assignedToField({params}),
          visible: !fromMyView,
        },

        {
          label: 'Est Hrs',
          field: 'est_hours',
          type: 'duration',
          visible: !fromMyView,
        },
        {
          label: 'Due On',
          field: 'due_date',
          type: 'date',
          visible: !fromMyView,
        },
        {
          label: 'Plan Type',
          field: 'plan_type',
          type: 'radioGroup',
          options: ['Date', 'This Week', 'This Month', 'Later'],
          onChangeValue: async (value, _, {setFieldValue}) => {
            if (value === 'Date') {
              setFieldValue('plan_date', void 0);
            }
          },
        },
        {
          field: 'plan_date',
          type: 'date',
          required: true,
          label: 'Plan Date',
          visible: ({values}) => {
            return values?.plan_type === 'Date';
          },
        },
      ]}
      {...props}
    />
  );
};
