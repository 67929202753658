const domainTypes = [
    {
        label: 'Domain Intro',
        id: 'intro',
    },
    {
        label: "Domain Module Quote",
        id: "domainQuote"
    },
    {
        label: "Domain Module Roles",
        id: "domainRole"
    },
    {
        label: "Domain Module SuperCharge",
        id: "domainSuperCharge"
    },
    {
        label: "Domain Watchout",
        id: "domainWatchout"
    },
    {
        label: "Domain Development Approach",
        id: "domainDevApproach"
    },
    {
        label: "Domain Success Stories",
        id: "domainSuccessStories"
    },
    {
        label: "Blogs",
        id: "blog"
    },
    {
        label: "Domain FAQ's ",
        id: "domainFaqs",
    },
    {
        label: "Domain Contact Section",
        id: "domainContact"
    },
    {
        label: "Footer Section",
        id: "footer"
    },
];

export const sectionTypes = {
    "/domain": domainTypes,
    "/domain/module": [
        {
            label: "Domain Module Intro",
            id: "domainModuleIntro"
        },
        {
            label: "Domain Module Quote",
            id: "domainQuote"
        },
        {
            label: "Domain Module Roles",
            id: "domainRole"
        },
        {
            label: "Domain Module SuperCharge",
            id: "domainSuperCharge"
        },
        {
            label: "Domain Watchout",
            id: "domainWatchout"
        },
        {
            label: "Domain Difference",
            id: 'domainDifference',
        },
        {
            label: "Domain Quality Standards",
            id: 'domainQualityStandards',
        },
        {
            label: "Domain Success Stories",
            id: 'domainSuccessStories',
        },
        {
            label: "Blogs",
            id: "blog"
        },
        {
            label: "Domain FAQ's ",
            id: "domainFaqs",
        },
        {
            label: "Domain Contact Section",
            id: "domainContact"
        },
        {
            label: "Footer Section",
            id: "footer"
        },
    ],
    "/about-us": [
        {
            label: "About Us Introduction",
            id: "aboutUsIntro",
        },
        {
            label: "Our Values",
            id: "values",
        },
        {
            label: "The Unthinkable Journey",
            id: "journey",
        },
        {
            label: "Our Partner Ecosystem",
            id: "partners",
        },
        {
            label: "Visionaries",
            id: "visionary",
        },
        {
            label: "Positive Impact",
            id: "positiveImpact",
        },
        {
            label: "Our Group's Decorated History",
            id: "history",
        },
        {
            label: "Locations",
            id: "locations",
        },
        {
            label: "Contact Us",
            id: "contact",
        },
        {
            label: "Footer",
            id: "footer",
        },
    ],
    "/career": [
        {
            label: "Career Page Introduction",
            id: "careerIntro",
        },
        {
            label: "Values we Stand By",
            id: "values",
        },
        {
            label: "Our Commitment",
            id: "commitment",
        },
        {
            label: "Opportunities",
            id: "jobs",
        },
        {
            label: "Candidate Resume",
            id: 'candidateForm',
        },
        {
            label: "Break The Break",
            id: "breakTheBreak",
        },
        {
            label: "Add the Child",
            id: "child",
        },
        {
            label: "Footer",
            id: "footer",
        },
    ],
    "/partnership": [
        {
            label: "Partnership Page Introduction",
            id: "partnerShipIntro",
        },
        {
            label: "Our Partners",
            id: "ourPartners",
        },
        {
            label: "Contact",
            id: "partnerShipContact",
        },
        {
            label: "Footer",
            id: "footer",
        },
    ],
    "/podcast": [
        {
            label: 'Podcast Page Introduction',
            id: 'podcastIntro',
        },
        {
            label: 'Podcasts',
            id: 'podcasts',
        },
        {
            label: "Contact",
            id: "contact",
        },
        {
            label: 'Footer',
            id: 'footer',
        },
    ],
    '/blogs': [
        {
            label: 'Blogs Carousel',
            id: 'blogsCarousel',
        },
        {
            label: 'Blogs',
            id: 'blogsHome',
        },
        {
            label: "Contact",
            id: "contact",
        },
        {
            label: 'Footer',
            id: 'footer',
        },
    ],
    '/career-detail': [
        {
            label: 'Career Detail Introduction Page',
            id: 'careerDetailIntro',
        },
        {
            label: 'Job Description',
            id: 'jobDesc'
        },
        {
            label: 'Jobs',
            id: 'jobs',
        },
        {
            label: 'Footer',
            id: 'footer',
        }
    ],
    '/case-study': [
        {
            label: 'Case Study Introduction',
            id: 'caseStudyIntro',
        },
        {
            label: 'Case Study',
            id: 'caseStudyHome',
        },
        {
            label: "Contact",
            id: "contact",
        },
        {
            label: 'Footer',
            id: 'footer',
        },
    ],
    '/breakthebreak': [
        {
            label: 'Break The Break Candidate Form',
            id: 'candidateForm'
        },
        {
            label: 'Footer',
            id: 'footer',
        }
    ],
    '/blogs-detail': [
        {
            label: 'Blogs Detail Introduction',
            id: 'blogDetailIntro',
        },
        {
            label: 'Quick Summary',
            id: 'summary',
        },
        {
            label: 'Text Editor',
            id: 'textEditor',
        },
        {
            label: 'Image Editor',
            id: 'imageEditor',
        },
        {
            label: 'Quote',
            id: 'quote',
        },
        {
            label: 'Quick Poll',
            id: 'poll',
        },
        {
            label: 'Cards',
            id: 'cards',
        },
        {
            label: 'Conclusion',
            id: 'conclusion',
        },
        {
            label: 'Contact',
            id: 'contact',
        },
        {
            label: 'Other Blogs',
            id: 'blog'
        },
        {
            label: 'Footer',
            id: 'footer',
        }
    ],
    '/case-study-detail': [
        {
            label: 'Case Study Detail Introduction',
            id: 'caseStudyDetailIntro',
        },
        {
            label: 'Quick Summary',
            id: 'summary',
        },
        {
            label: 'Text Editor',
            id: 'textEditor',
        },
        {
            label: 'Image Editor',
            id: 'imageEditor',
        },
        {
            label: 'Quote',
            id: 'quote',
        },
        {
            label: 'Quick Poll',
            id: 'poll',
        },
        {
            label: 'Cards',
            id: 'cards',
        },
        {
            label: 'Conclusion',
            id: 'conclusion',
        },
        {
            label: 'Contact',
            id: 'contact',
        },
        {
            label: 'Other Case Studies',
            id: 'domainSuccessStories',
        },
        {
            label: 'Footer',
            id: 'footer',
        }
    ],
    '/privacy-policy': [
        {
            label: 'Privacy Policy',
            id: 'privacyPolicy',
        },
        {
            label: 'Footer',
            id: 'footer',
        }
    ],
    '/home': [
        {
            label: 'Building Better Platform',
            id: "building-better-platform",
        },
        {
            label: 'Key Pillar',
            id: "key-pillar",
        },
        {
            label: 'Key Pillar Features',
            id: "sticky-header",
        },
        {
            label: 'Frontend Architecture',
            id: "frontend-architecture",
        },
        {
            label: 'Backend Architecture',
            id: "backend-architecture",
        },
        {
            label: 'Modular Development',
            id: "modular-development",
        },
        {
            label: 'Testimonials',
            id: "testimonials",
        },
        {
            label: 'Home Introduction Page',
            id: 'homeIntro',
        },
        {
            label: 'Architectural Excellence',
            id: 'homeArchitectural',
        },
        {
            label: 'Code Features',
            id: 'homeCodeFeatures',
        },
        {
            label: 'Architectural Structure',
            id: 'homeStructure',
        },
        {
            label: 'Deep Domain Expertise',
            id: 'homeDomainExpertise',
        },
        {
            label: 'Our Expertise, across domains',
            id: 'homeDomainCaseStudy',
        },
        {
            label: "Our Award Winning Journey",
            id: "history",
        },
        {
            label: 'Blogs',
            id: 'blog',
        },
        {
            label: 'Daffodil Annual Function',
            id: "daffodil-annual",
        },
        {
            label: 'Contact',
            id: "contact",
        },
        {
            label: 'Footer',
            id: "footer",
        },
    ]
}